import { IconButton, Skeleton } from '@mui/material';
import {
  PayerType,
  ProductBillingType,
  SchoolYear,
  useGetStudentProductsQuery,
  useUpdateStudentProductsMutation,
  YearlyProductAssignments,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { CrossIcon, Loading, ModalHeader, ModalSmall } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import AccessDenied from '../../../../../components/common/AccessDenied';
import { useProfile } from '../../../../../context/profile/useProfile';
import { useRouter } from '../../../../../context/router/useRouter';
import useSchoolYears from '../../../../../hooks/useSchoolYears';
import { convertFormDataToAssignedProductSave } from './helpers';
import {
  StudentProductsModalContent,
  UpdateStudentProductsForm,
} from './StudentProductsModalContent';

export const STUDENT_PRODUCTS_COMPANY_ID_PARAM = 'company_id';

export const StudentProductsModal: FC = () => {
  const [searchParams] = useSearchParams();
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { schoolMembership, isFetching, user } = useProfile();
  const { permissions } = useAuth();
  const canEdit = permissions.includes('payer_and_product_assignment_manager');
  const { goBack } = useRouter();
  const { showError, showNotification } = useNotifications();
  const updateStudentProducts = useUpdateStudentProductsMutation();
  const { isLoading: isSaving } = updateStudentProducts;
  const invalidateCompanyQueries = useInvalidateListQueriesFor('company');
  const invalidateProductQueries = useInvalidateListQueriesFor('product');
  const { getConfirmation } = useConfirmationDialog();

  const relationId = schoolMembership?.relation_id || '';

  const { isLoading: isYearsFetching, defaultValidity, schoolYears } = useSchoolYears();
  const { data: productAssignmentsData, isLoading } = useGetStudentProductsQuery(
    { relationId, type: ProductBillingType.Recurring },
    { enabled: !!schoolMembership, refetchOnMount: 'always' },
  );

  const productAssignmentsByYear = useMemo(
    () =>
      productAssignmentsData?.yearly_product_assignments.reduce<
        Record<string, YearlyProductAssignments>
      >((acc, data) => {
        acc[data.year_id] = data;
        return acc;
      }, {}) ?? {},
    [productAssignmentsData?.yearly_product_assignments],
  );

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) =>
      Boolean(year && !isDateInPast(year.end) && productAssignmentsByYear?.[year.id]),
    [productAssignmentsByYear],
  );

  const yearsForSelect = useMemo(
    () => schoolYears.filter(canShowYearInSelect),
    [canShowYearInSelect, schoolYears],
  );
  const defaultYear = canShowYearInSelect(defaultValidity) ? defaultValidity : undefined;
  const initialYear = defaultYear ?? yearsForSelect[0];

  const paramsCompanyId = searchParams.get(STUDENT_PRODUCTS_COMPANY_ID_PARAM);

  const handleSubmit = useCallback(
    async (data: UpdateStudentProductsForm) => {
      const products = data.products.map(convertFormDataToAssignedProductSave);

      const companyPayerSelected = data.products.some((p) => p.payer_type === PayerType.Company);
      const initCompanyId =
        paramsCompanyId ??
        productAssignmentsData?.yearly_product_assignments.find(
          ({ year_id }) => year_id === data.yearId,
        )?.company_payer?.id;

      if (data.companyPayerId && !companyPayerSelected) {
        const isConfirmed = await getConfirmation({
          message: $t(
            {
              id: initCompanyId
                ? 'profile-HaveDeselectedCompanyAsPayerConfirmation'
                : 'profile-HaveNotSelectedCompanyAsPayerConfirmation',
            },
            { studentName: user && getUserFullName(user) },
          ),
        });
        if (!isConfirmed) return;
      }

      await updateStudentProducts.mutateAsync(
        {
          ...data,
          companyPayerId: companyPayerSelected ? data.companyPayerId : undefined,
          studentId: relationId,
          products,
        },
        {
          onError: showError,
          onSuccess: () => {
            showNotification({
              textId: paramsCompanyId
                ? 'companies-AssignmentsHaveBeenSuccessfullyUpdated'
                : 'products-ProductsAndPayersSaved',
              type: 'success',
            });
            invalidateProductQueries();
            invalidateCompanyQueries();
          },
        },
      );
      return true;
    },
    [
      paramsCompanyId,
      productAssignmentsData?.yearly_product_assignments,
      updateStudentProducts,
      relationId,
      showError,
      getConfirmation,
      $t,
      user,
      showNotification,
      invalidateProductQueries,
      invalidateCompanyQueries,
    ],
  );

  if (!canEdit) {
    return (
      <ModalSmall open onClose={goBack}>
        <AccessDenied />
      </ModalSmall>
    );
  }

  const showLoading =
    isFetching || isLoading || isYearsFetching || !productAssignmentsData || !initialYear;

  return (
    <ModalSmall open onClose={goBack}>
      {showLoading ? (
        <Loading />
      ) : (
        <StudentProductsModalContent
          schoolId={schoolId}
          relationId={relationId}
          student={user}
          onSubmit={handleSubmit}
          onClose={goBack}
          isSaving={isSaving}
          schoolYear={initialYear}
          yearsForSelect={yearsForSelect}
          productAssignmentsByYear={productAssignmentsByYear}
          companyId={paramsCompanyId ?? undefined}
        >
          <ModalHeader title={user ? getUserFullName(user) : <Skeleton width="50%" />} active>
            <IconButton onClick={goBack}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
        </StudentProductsModalContent>
      )}
    </ModalSmall>
  );
};
