import { SystemStyleObject } from '@mui/system';
import { DropdownSelect, DropdownSelectProps } from '@schooly/components/filters';
import { forwardRef } from 'react';

interface CellDropdownSelectProps extends DropdownSelectProps {}

export const CellDropdownSelect = forwardRef<DropdownSelect, CellDropdownSelectProps>(
  ({ sx, opened, ...dropdownProps }, ref) => {
    return (
      <DropdownSelect
        ref={ref}
        sx={(theme) => ({
          height: 46,
          width: opened ? 200 : undefined,
          borderRadius: 0,
          margin: theme.spacing('-1px', 0),
          backgroundColor: opened ? undefined : 'transparent',
          borderColor: opened ? undefined : 'transparent',
          outlineColor: 'transparent !important',
          ' .right-icon': {
            right: theme.spacing(1),
          },
          ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        })}
        {...dropdownProps}
      />
    );
  },
);
