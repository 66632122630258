import {
  PaymentFrequency,
  PaymentFrequencyType,
  Product,
  ProductBillingType,
  ProductFormType,
  ProductSaveType,
  ProductSaveVariant,
  ProductType,
  ProductVariantPrice,
  SchoolYear,
} from '@schooly/api';
import {
  Currencies,
  CURRENCY_SYMBOLS,
  PRODUCTS_COPY_FROM_YEAR_ID_PARAM,
  PRODUCTS_YEAR_ID_PARAM,
} from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { areIntervalsOverlapping, isAfter } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import useSchoolYears from '../../../hooks/useSchoolYears';
import {
  IntersectionIds,
  INTERSECTS_ALL,
} from './SchoolProductCreateModal/SchoolProductCreateModalContent';

export const FREQUENCY_SETTINGS_PATH = '/settings/frequencies';

export const DEFAULT_FREQUENCIES = [
  PaymentFrequencyType.Weekly,
  PaymentFrequencyType.Monthly,
  PaymentFrequencyType.Termly,
  PaymentFrequencyType.Biannually,
  PaymentFrequencyType.Annually,
  PaymentFrequencyType.OneOff,
];

export const useSchoolYearsInProduct = (
  canShowYearInSelect: (year?: SchoolYear) => boolean,
  shouldIgnoreSchoolYears = false,
) => {
  const { defaultValidity, schoolYears } = useSchoolYears();
  const [params] = useSearchParams();

  const yearsForSelect = useMemo(
    () => schoolYears.filter(canShowYearInSelect),
    [canShowYearInSelect, schoolYears],
  );
  const defaultYear = canShowYearInSelect(defaultValidity) ? defaultValidity : undefined;
  const defaultYearInSelect = defaultYear ?? yearsForSelect[0];

  const paramsYearId = params.get(PRODUCTS_YEAR_ID_PARAM);
  const paramsCopyFromYearId = params.get(PRODUCTS_COPY_FROM_YEAR_ID_PARAM);

  const { paramsSchoolYear, paramsCopyFromYear } = useMemo(() => {
    const paramsSchoolYear = yearsForSelect.find((y) => y.id === paramsYearId);
    const paramsCopyFromYear = yearsForSelect.find((y) => y.id === paramsCopyFromYearId);
    return { paramsSchoolYear, paramsCopyFromYear };
  }, [paramsCopyFromYearId, paramsYearId, yearsForSelect]);

  const initSelectedYear = shouldIgnoreSchoolYears
    ? defaultYearInSelect
    : paramsSchoolYear ?? defaultYearInSelect;

  const [selectedYear, setSelectedYear] = useState<SchoolYear | undefined>(initSelectedYear);

  const selectedYearIndex = yearsForSelect.findIndex((y) => y.id === selectedYear?.id);
  const previousYear = yearsForSelect[selectedYearIndex - 1];

  const initCopyFromYear =
    shouldIgnoreSchoolYears || !paramsCopyFromYearId ? undefined : paramsCopyFromYear;
  const [copyFromYear, setCopyFromYear] = useState<SchoolYear | undefined>(initCopyFromYear);

  const isValidYearId = useCallback(
    (yearId: string) => {
      return yearsForSelect.some((y) => y.id === yearId);
    },
    [yearsForSelect],
  );

  useEffect(() => {
    if (!paramsYearId && !paramsCopyFromYearId) return;

    const searchParams = new URLSearchParams(window.location.search);
    let shouldUpdate = false;

    //remove incorrect params
    if (shouldIgnoreSchoolYears && (paramsYearId || paramsCopyFromYearId)) {
      searchParams.delete(PRODUCTS_YEAR_ID_PARAM);
      searchParams.delete(PRODUCTS_COPY_FROM_YEAR_ID_PARAM);
      shouldUpdate = true;
    } else {
      //incorrect year id param
      if (paramsYearId && selectedYear?.id && paramsYearId !== selectedYear.id) {
        searchParams.set(PRODUCTS_YEAR_ID_PARAM, selectedYear.id);
        shouldUpdate = true;
      }

      //incorrect copy from year id param
      if (paramsCopyFromYearId && !isValidYearId(paramsCopyFromYearId)) {
        searchParams.delete(PRODUCTS_COPY_FROM_YEAR_ID_PARAM);
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      const params = searchParams.toString() ? `?${searchParams.toString()}` : '';
      const newPath = `${window.location.pathname}${params}`;
      window.history.replaceState(null, '', newPath);
    }
  }, [
    isValidYearId,
    paramsCopyFromYearId,
    paramsYearId,
    selectedYear?.id,
    shouldIgnoreSchoolYears,
  ]);

  return {
    selectedYear,
    setSelectedYear,
    defaultYear: defaultYearInSelect,
    yearsForSelect,
    copyFromYear,
    setCopyFromYear,
    isValidYearId,
    previousYear,
  };
};

export const getVariantPrice = (v: Product['types'][0]['variants'][0], freqId?: string) => {
  if (!freqId) return null;

  const p = v.prices.find((v) => v.frequency_id === freqId);
  if (!p) return null;

  return p.price;
};

export function getSortedFrequencies(frequencies: PaymentFrequency[]) {
  return [...frequencies].sort(
    (a, b) => DEFAULT_FREQUENCIES.indexOf(a.type) - DEFAULT_FREQUENCIES.indexOf(b.type),
  );
}

type UseProductFrequencyProps = {
  product: Product;
  frequencies: PaymentFrequency[];
};

export type ProductTypeNotActiveMap = Record<ProductType['id'], boolean>;

export const useProductFrequency = ({ product, frequencies }: UseProductFrequencyProps) => {
  return useMemo(() => {
    const productTypeNotActiveMap = product.types.reduce<ProductTypeNotActiveMap>(
      (acc, { id, variants }) => ({
        ...acc,
        [id]: variants.every((v) =>
          v.prices.every((p) => !frequencies.find((f) => f.id === p.frequency_id)?.in_use),
        ),
      }),
      {},
    );

    return {
      productTypeNotActiveMap,
      allTypesNotActive: Object.values(productTypeNotActiveMap).every(Boolean),
    };
  }, [frequencies, product.types]);
};

type HasIntersectionErrorProps = {
  variant: ProductSaveVariant;
  intersectionIds?: IntersectionIds;
};

export function getIntersections({
  variant: { age_groups, half_day, subjects },
  intersectionIds,
}: HasIntersectionErrorProps) {
  const intersectionsForDay = intersectionIds?.[half_day ? 'halfDayIds' : 'fullDayIds'];

  const ageGroupsIntersections =
    !age_groups.length &&
    intersectionsForDay?.some(({ ageGroupId }) => ageGroupId !== INTERSECTS_ALL)
      ? INTERSECTS_ALL
      : age_groups.filter((agId) =>
          intersectionsForDay?.map(({ ageGroupId }) => ageGroupId).includes(agId),
        );
  const subjectIntersections = subjects.filter((agId) =>
    intersectionsForDay?.map(({ subjectId }) => subjectId).includes(agId),
  );

  const hasIntersectionError = !!(subjectIntersections.length || ageGroupsIntersections.length);

  return { subjectIntersections, hasIntersectionError, ageGroupsIntersections };
}

export const getCurrencySymbol = (currency?: Currencies) => {
  //list of currency symbols is used cause the task was to use symbols from a certain source
  return currency ? CURRENCY_SYMBOLS[currency] : '';
};

export const copyProductTypes = ({
  types,
  yearId,
  copyFromFrequencies,
  copyToFrequencies,
  filterAgeGroups,
  copyPrices = true,
}: {
  types: ProductFormType[];
  yearId?: string;
  copyFromFrequencies: PaymentFrequency[];
  copyToFrequencies: PaymentFrequency[];
  filterAgeGroups: (ageGroup: string) => boolean;
  copyPrices?: boolean;
}): ProductFormType[] => {
  const transformPrices = (prices: ProductVariantPrice[]) => {
    return prices.reduce<ProductVariantPrice[]>((acc, p) => {
      const frequencyType = copyFromFrequencies.find((f) => f.id === p.frequency_id)?.type;
      const newFrequency = copyToFrequencies.find((f) => frequencyType === f.type);

      if (newFrequency?.in_use) {
        acc.push({ ...p, frequency_id: newFrequency.id, price: copyPrices ? p.price : 0 });
      }

      return acc;
    }, []);
  };

  const transformVariant = ({ id, prices, age_groups, ...variant }: ProductSaveVariant) => {
    const newPrices = transformPrices(prices);
    const newAgeGroups = age_groups.filter(filterAgeGroups);
    return { ...variant, age_groups: newAgeGroups, prices: newPrices };
  };

  return types.map(({ id, variants, ...type }) => ({
    ...type,
    year_id: yearId,
    variants: variants.map(transformVariant),
  }));
};

export const convertProductTypeToForm = (type: ProductType, yearId?: string) => {
  const { billing_connection, active_from, active_to, created_at, ...rest } = type;
  return {
    ...rest,
    billing_connection: {
      account_id: billing_connection.legal_entity_account.id,
      legal_entity_id: billing_connection.legal_entity_id,
      legal_entity_currency: billing_connection.legal_entity_currency,
    },
    year_id: yearId,
  };
};

export const getProductTypeYearId = <T extends Pick<ProductType, 'active_to' | 'active_from'>>(
  type: T,
  years: SchoolYear[],
) => {
  return years.find(({ start, end }) =>
    areIntervalsOverlapping(
      {
        start: newDateTimezoneOffset(type.active_from),
        end: newDateTimezoneOffset(type.active_to),
      },
      { start: newDateTimezoneOffset(start), end: newDateTimezoneOffset(end) },
      { inclusive: true },
    ),
  )?.id;
};

export const getLatestProductTypes = <T extends Pick<ProductType, 'active_to' | 'created_at'>>(
  types: T[],
  endDate?: string,
) => {
  const date = endDate ? newDateTimezoneOffset(endDate) : new Date();
  let latestTypes = types.filter(({ active_to }) =>
    isAfter(newDateTimezoneOffset(active_to), date),
  );

  if (!latestTypes.length) {
    //if no types with future active_to, fallback to latest created types
    //agreed with BE that types of one version will have the same created_at
    const typesByCreatedAt = types.reduce<Record<string, T[]>>((acc, t) => {
      acc[t.created_at] = [...(acc[t.created_at] || []), t];
      return acc;
    }, {});

    const latestCreatedAt = Object.keys(typesByCreatedAt)
      .map(Number)
      .sort((a, b) => b - a)[0];

    latestTypes = typesByCreatedAt[latestCreatedAt];
  }

  return latestTypes;
};

type ProductTypeBase = Partial<Pick<ProductType, 'active_from' | 'active_to' | 'created_at'>> &
  Pick<ProductSaveType, 'year_id'>;

export const getProductTypesByYear = <T extends ProductTypeBase>({
  types,
  schoolYears,
  yearId,
}: {
  types: T[];
  schoolYears: SchoolYear[];
  yearId: string;
}) => {
  return types.filter(({ year_id, active_from, active_to }) => {
    if (year_id) return year_id === yearId;

    if (active_from && active_to) {
      const typeYearId = getProductTypeYearId({ active_from, active_to }, schoolYears);
      return typeYearId === yearId;
    }

    return false;
  });
};

export const getCurrentProductTypes = <T extends ProductTypeBase>({
  types,
  productType,
  schoolYears,
  yearId,
}: {
  types: T[];
  productType: ProductBillingType;
  schoolYears: SchoolYear[];
  yearId: string;
}) => {
  if (!types.length) return [];

  switch (productType) {
    case ProductBillingType.Recurring:
      return getProductTypesByYear({ types, schoolYears, yearId });
    case ProductBillingType.OneOff:
      const typesToFilter = types.filter(
        (t): t is T & Pick<ProductType, 'active_to' | 'created_at'> =>
          !!t.active_to && !!t.created_at,
      );
      return types.length === typesToFilter.length ? getLatestProductTypes(typesToFilter) : types;
  }
};
