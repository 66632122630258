import {
  Box,
  Button,
  Icon,
  IconButton,
  Stack,
  StackProps,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DefaultSchoolYear,
  PaymentFrequency,
  PaymentFrequencyType,
  ProductBillingType,
  ProductForm,
  ProductFormType,
  ProductVariantPrice,
  SchoolYear,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { toggleMultipleValueArrayProperty } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  CheckIcon,
  DeleteIcon,
  DropdownYears,
  EditIcon,
  Loading,
  LockIcon,
  PlusIcon,
  SimpleButton,
} from '@schooly/style';
import isEqual from 'lodash.isequal';
import {
  Dispatch,
  FC,
  FocusEventHandler,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldError,
  useFieldArray,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getInputErrorText } from '../../../../components/ui/Input/utils';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { EmptyTypes } from '../EmptyTypes';
import { getCurrencySymbol, getCurrentProductTypes, getIntersections } from '../helpers';
import {
  HalfDayIconComponent,
  ProductBlockedEditingLabel,
  ProductTypeLayout,
} from '../SchoolProductCommon';
import { BillingConnectionForm } from './AccountSelect';
import { CreateVariantsTable, PriceValue } from './CreateVariantsTable';
import { ProductApplicableSelectMultiple } from './ProductApplicableSelectMultiple';
import { ProductPriceInput } from './ProductPriceInput';
import {
  getDefaultType,
  getDefaultVariant,
  IntersectionIds,
} from './SchoolProductCreateModalContent';

export enum VariantValidationError {
  MissingApplicable = 'MissingApplicable',
  NameMustBeUnique = 'NameMustBeUnique',
  NoPricesProvided = 'NoPricesProvided',
  PriceRemoved = 'PriceRemoved',
}
type SchoolProductCreateModalVariantsProps = {
  frequencies: PaymentFrequency[];
  form: UseFormReturn<ProductForm>;
  schoolId: string;
  findIntersections: () => void;
  typesIntersectionIds: IntersectionIds[];
  selectedYear?: SchoolYear;
  setSelectedYear: Dispatch<SetStateAction<SchoolYear | undefined>>;
  yearsForSelect: SchoolYear[];
  defaultYear?: DefaultSchoolYear;
  canViewFrequency: boolean;
  canEdit: boolean;
  isLoading: boolean;
  sortedFrequencies: PaymentFrequency[];
  isEdit: boolean;
};
export const SchoolProductCreateModalVariants: FC<
  PropsWithChildren<SchoolProductCreateModalVariantsProps>
> = ({
  form,
  frequencies,
  schoolId,
  typesIntersectionIds,
  findIntersections,
  selectedYear,
  setSelectedYear,
  yearsForSelect,
  defaultYear,
  canViewFrequency,
  children,
  canEdit,
  isLoading,
  sortedFrequencies,
  isEdit,
}) => {
  const { $t } = useIntl();
  const { schoolYears } = useSchoolYears();

  const {
    fields: allTypes,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: `types`,
  });

  const name = form.watch('name');
  const isRecurring = form.watch('type') === ProductBillingType.Recurring;

  const selectedYearId = selectedYear?.id ?? '';

  const types = useMemo(
    () =>
      isRecurring
        ? getCurrentProductTypes({
            types: allTypes,
            schoolYears,
            yearId: selectedYearId,
            productType: form.watch('type'),
          })
        : allTypes,
    [allTypes, form, isRecurring, schoolYears, selectedYearId],
  );

  const handleAddType = useCallback(() => {
    append(getDefaultType(selectedYearId));
  }, [append, selectedYearId]);

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    const hasFrequencies = !!frequencies.length;
    const hasActiveFrequencies = isRecurring
      ? frequencies.some((f) => f.type !== PaymentFrequencyType.OneOff)
      : frequencies.some((f) => f.type === PaymentFrequencyType.OneOff);

    const getMessage = () => {
      if (!hasFrequencies) {
        return 'products-NoFrequenciesForThisYear';
      }
      if (!hasActiveFrequencies) {
        return 'products-NoActiveFrequenciesForThisYear';
      }
      return 'products-NoPricesForThisProduct';
    };

    if ((!isEdit && !hasActiveFrequencies) || !types.length) {
      return (
        <EmptyTypes
          message={$t({ id: getMessage() })}
          actions={
            //currently user cannot add frequencies, so we only show button for editing existing ones
            hasFrequencies &&
            !hasActiveFrequencies &&
            canViewFrequency && (
              <Link to={`/settings/frequencies/year/${selectedYearId}`} target="_blank">
                <Button startIcon={<PlusIcon />}>{$t({ id: 'frequencies-SetFrequencies' })}</Button>
              </Link>
            )
          }
        />
      );
    }

    return (
      <Stack gap={3}>
        {types.map((type, index) => {
          const indexInAllTypes = allTypes.findIndex((t) => t.id === type.id);

          return (
            <ProductType
              schoolId={schoolId}
              index={indexInAllTypes}
              key={type.id}
              frequencies={sortedFrequencies}
              form={form}
              onRemove={remove}
              intersectionIds={typesIntersectionIds[index] ?? []}
              onVariantUpdated={findIntersections}
              canViewFrequency={canViewFrequency}
              canEdit={canEdit}
              types={types}
              selectedYearId={selectedYearId}
            />
          );
        })}

        {canEdit && (
          <Button
            variant="outlined"
            startIcon={<PlusIcon />}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleAddType}
          >
            {$t({ id: 'products-AddType-WithName' }, { name })}
          </Button>
        )}
      </Stack>
    );
  };

  return (
    <>
      <Stack justifyContent="space-between" flexDirection="row" alignItems="center" mb={2.25}>
        {children}

        {!!selectedYear && isRecurring && (
          <Stack gap={2.5} direction="row">
            <DropdownYears
              years={yearsForSelect}
              defaultYear={defaultYear}
              currentYear={selectedYear}
              onYearChange={setSelectedYear}
              disabled={isEdit}
            />
          </Stack>
        )}
      </Stack>

      {renderContent()}
    </>
  );
};

type ProductTypeVariantsProps = {
  schoolId: string;
  frequencies: PaymentFrequency[];
  form: UseFormReturn<ProductForm>;
  index: number;
  onRemove: (i: number) => void;
  onVariantUpdated: () => void;
  intersectionIds: IntersectionIds;
  canViewFrequency: boolean;
  canEdit: boolean;
  types: ProductFormType[];
  selectedYearId: string;
};

const ProductType: FC<ProductTypeVariantsProps> = ({
  form,
  index,
  frequencies,
  schoolId,
  intersectionIds,
  onRemove,
  onVariantUpdated,
  canViewFrequency,
  canEdit,
  types,
  selectedYearId,
}) => {
  const { formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();

  const currentType = form.watch(`types.${index}`);

  const isSingleType = types.length === 1;

  const productType = form.watch('type');
  const isRecurring = productType === ProductBillingType.Recurring;

  const {
    fields: variants,
    append,
    remove,
    update,
  } = useFieldArray({
    control: form.control,
    name: `types.${index}.variants`,
  });

  const currencySymbol = getCurrencySymbol(currentType.billing_connection.legal_entity_currency);

  const [frequencyTypes, setFrequencyTypes] = useState<Set<PaymentFrequencyType>>(() => {
    const usedFrequencyIds = variants.flatMap((v) => v.prices.map((p) => p.frequency_id));
    const usedFrequencyTypes = frequencies
      .filter((f) => usedFrequencyIds.includes(f.id))
      .map((f) => f.type);

    if (usedFrequencyTypes.length) {
      return new Set(usedFrequencyTypes);
    }

    return new Set(
      isRecurring
        ? [PaymentFrequencyType.Monthly, PaymentFrequencyType.Termly, PaymentFrequencyType.Annually]
        : [PaymentFrequencyType.OneOff],
    );
  });

  const handleAddVariant = useCallback(() => append(getDefaultVariant()), [append]);

  const handleRemoveVariant = useCallback(
    (i: number) => {
      if (variants.length <= 1) {
        onRemove(index);
        return;
      }
      remove(i);
    },
    [index, onRemove, remove, variants.length],
  );

  const handleRemoveType = useCallback(async () => {
    const emptyVariant = getDefaultVariant();
    const needsConfirmation = currentType.variants.some((v) => !isEqual(v, emptyVariant));

    if (!needsConfirmation) {
      onRemove(index);
      return;
    }

    const isConfirmed = await getConfirmation({
      textId: 'products-DeleteTypeConfirmation',
      textValues: {
        typeName: `"${currentType.name}"`,
        hasTypeName: !!currentType.name,
      },
      sx: {
        '.MuiDialog-paperFullWidth': {
          width: 600,
        },
      },
    });

    if (isConfirmed) onRemove(index);
  }, [currentType.name, currentType.variants, getConfirmation, index, onRemove]);

  const { addedFrequencies, availableFrequencies } = useMemo(() => {
    return frequencies.reduce<{
      addedFrequencies: PaymentFrequency[];
      availableFrequencies: PaymentFrequency[];
    }>(
      (acc, frequency) =>
        frequencyTypes.has(frequency.type)
          ? { ...acc, addedFrequencies: [...acc.addedFrequencies, frequency] }
          : {
              ...acc,
              availableFrequencies:
                frequency.in_use && frequency.type !== PaymentFrequencyType.OneOff && isRecurring
                  ? [...acc.availableFrequencies, frequency]
                  : acc.availableFrequencies,
            },
      { addedFrequencies: [], availableFrequencies: [] },
    );
  }, [frequencies, frequencyTypes, isRecurring]);

  useEffect(() => {
    const variantUpdateSubscription = form.watch((_, { name }) => {
      const [, idx] = name?.split('.') ?? [];
      const typeIdx = +idx;
      if (Number.isInteger(typeIdx) && index === typeIdx) {
        onVariantUpdated();
      }
    });

    return variantUpdateSubscription.unsubscribe;
  }, [form, index, onVariantUpdated]);

  const toggleFrequency = (fr: PaymentFrequency) =>
    setFrequencyTypes((v) => {
      const newTypes = new Set(v);

      const variants = form.getValues(`types.${index}.variants`);

      if (newTypes.has(fr.type)) {
        newTypes.delete(fr.type);

        variants.map((variant, index) => {
          return update(index, {
            ...variant,
            prices: variant.prices.filter((price) => price.frequency_id !== fr.id),
          });
        });

        return newTypes;
      }

      newTypes.add(fr.type);
      return newTypes;
    });

  const renderRemoveButton = () => {
    if (types.length < 2) return null;

    if (canEdit) {
      return (
        <IconButton inverse onClick={handleRemoveType}>
          <DeleteIcon />
        </IconButton>
      );
    }

    return (
      <Tooltip
        componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
        title={<ProductBlockedEditingLabel labelId="products-CannotAddRemoveType" />}
      >
        <IconButton inverse>
          <LockIcon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Stack>
      <ProductTypeLayout>
        {!isSingleType && (
          <Box width={456}>
            <Controller
              control={form.control}
              name={`types.${index}.name`}
              rules={{
                required: true,
                validate: (value, product) => {
                  const nameIsUsed = product.types.some((t, i) => {
                    const isSameVersion = !t.year_id || t.year_id === selectedYearId;
                    return t.name === value && i !== index && isSameVersion;
                  });

                  if (nameIsUsed) {
                    return VariantValidationError.NameMustBeUnique;
                  }
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <NameInput
                    error={fieldState.error}
                    placeholder={formatMessage({ id: 'products-TypeName' })}
                    maxWidth={500}
                    {...field}
                  />
                );
              }}
            />
          </Box>
        )}
        <Stack flex={1} pl={isSingleType ? 0 : 2} py={0.5} sx={{ overflowX: 'auto' }}>
          <BillingConnectionForm
            schoolId={schoolId}
            typeIndex={index}
            opened={isSingleType && !currentType.billing_connection.legal_entity_id}
            minHeight={22}
            maxLabelWidth={isSingleType ? 400 : 200}
          />
        </Stack>

        {renderRemoveButton()}
      </ProductTypeLayout>

      <CreateVariantsTable
        schoolId={schoolId}
        intersectionIds={intersectionIds ?? []}
        addedFrequencies={addedFrequencies}
        typeIdx={index}
        currencySymbol={currencySymbol}
        canViewFrequency={canViewFrequency}
        onToggleFrequency={toggleFrequency}
        availableFrequencies={availableFrequencies}
        onRemoveVariant={handleRemoveVariant}
        canEdit={canEdit}
        variants={variants}
        sx={
          canEdit
            ? undefined
            : {
                '& .MuiTableBody-root .MuiTableRow-root:last-of-type .MuiTableCell-root': {
                  ':last-of-type': {
                    borderBottomRightRadius: (theme) => theme.spacing(1),
                  },
                  ':first-of-type': {
                    borderBottomLeftRadius: (theme) => theme.spacing(1),
                  },
                },
              }
        }
      />

      {canEdit && (
        <Stack
          px={1}
          py={1.25}
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(0, 0, 1, 1),
            borderTop: theme.mixins.borderControlValue(),
            borderColor: theme.palette.divider,
          })}
        >
          <SimpleButton
            startIcon={<PlusIcon />}
            sx={{ alignSelf: 'flex-start' }}
            onClick={handleAddVariant}
          >
            {formatMessage({ id: 'products-AddVariant' })}
          </SimpleButton>
        </Stack>
      )}
    </Stack>
  );
};

type NameInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: FieldError;
  showEditIcon?: boolean;
  maxWidth?: number;
};
export const NameInput: FC<NameInputProps> = ({
  error,
  onFocus: onFocusProps,
  onBlur: onBlurProps,
  showEditIcon = true,
  maxWidth,
  ...rest
}) => {
  const { $t } = useIntl();
  const [isFocused, focus, blur] = useFlag(false);

  const onFocus: FocusEventHandler<HTMLInputElement> = (...props) => {
    onFocusProps?.(...props);
    focus();
  };
  const onBlur: FocusEventHandler<HTMLInputElement> = (...props) => {
    onBlurProps?.(...props);
    blur();
  };

  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={(theme) => ({
        maxWidth: maxWidth ?? '100%',
        width: '100%',
        '.edit-icon': {
          marginTop: theme.spacing(0.5),
          pointerEvents: 'none',
          transition: 'all .2s',
          opacity: isFocused ? 0 : undefined,
        },
        ':not(:hover) .edit-icon': {
          opacity: 0,
        },
      })}
    >
      <Stack
        sx={(theme) => ({
          width: '100%',
          gap: theme.spacing(0.5),
          transition: 'all .2s',
        })}
      >
        <NameInputStyled
          error={!!error}
          onFocus={onFocus}
          onBlur={onBlur}
          autoFocus={!rest.value}
          placeholder={$t({ id: 'products-Name' })}
          className="name-input"
          {...rest}
        />
        {error && (
          <>
            <Typography variant="caption" color="error.main">
              {error.message === VariantValidationError.NameMustBeUnique
                ? $t({ id: 'products-TheNameMustBeUnique' })
                : $t(getInputErrorText(error))}
            </Typography>
          </>
        )}
      </Stack>
      {showEditIcon && (
        <Icon className="edit-icon">
          <EditIcon />
        </Icon>
      )}
    </Stack>
  );
};

export const NameInputStyled = styled('input')<{ error: boolean }>(({ theme, error }) => ({
  background: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderTop: 'none',
  borderBottom: error
    ? `2px solid ${theme.palette.error.main} !important`
    : `2px solid transparent`,
  width: '100%',
  ...theme.typography.h2,
  padding: 0,
  transition: 'all .2s',
  color: error ? theme.palette.error.main : undefined,
  '&:hover, &:focus': {
    borderBottomColor: theme.palette.text.primary,
  },
  '&::placeholder': {
    color: error ? theme.palette.error.main : theme.palette.common.grey,
  },
}));

type ApplicableToFormProps = {
  typeIdx: number;
  variantIdx: number;
  intersectionIds: IntersectionIds;
  schoolId: string;
  renderError?: () => ReactNode;
  disabled: boolean;
} & StackProps;

export const ApplicableToForm: FC<ApplicableToFormProps> = ({
  variantIdx,
  typeIdx,
  intersectionIds,
  schoolId,
  renderError,
  disabled,
  ...props
}) => {
  const { control, watch } = useFormContext<ProductForm>();

  const variantPath = `types.${typeIdx}.variants.${variantIdx}` as const;
  const variant = watch(variantPath);

  return (
    <Controller
      control={control}
      name={variantPath}
      rules={{
        validate: (value) => {
          if (!value.age_groups.length && !value.subjects.length)
            return VariantValidationError.MissingApplicable;
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const hasEmptyError =
          error?.type === 'validate' && error.message === VariantValidationError.MissingApplicable;

        const { hasIntersectionError, ageGroupsIntersections, subjectIntersections } =
          getIntersections({
            intersectionIds,
            variant: field.value,
          });

        return (
          <Stack
            flex={1}
            sx={(theme) => ({
              borderLeft: `1px solid ${theme.palette.divider}`,
              justifyContent: 'center',
              position: 'relative',
            })}
            {...props}
          >
            <ProductApplicableSelectMultiple
              disabled={disabled}
              schoolId={schoolId}
              onSelectAgeGroupIds={(ids) => {
                field.onChange({
                  ...variant,
                  age_groups: toggleMultipleValueArrayProperty(field.value.age_groups, ids),
                });
              }}
              intersectionAgeGroupIds={ageGroupsIntersections}
              selectedAgeGroupIds={field.value.age_groups}
              intersectionSubjectIds={subjectIntersections}
              selectedSubjectIds={field.value.subjects}
              onSelectSubjectId={(id) => {
                field.onChange({
                  ...variant,
                  subjects: field.value.subjects.includes(id)
                    ? field.value.subjects.filter((sid) => sid !== id)
                    : [...field.value.subjects, id],
                });
              }}
            />
            {(hasEmptyError || hasIntersectionError) && (
              <>
                {renderError ? (
                  renderError()
                ) : (
                  <Box
                    sx={(theme) => ({
                      pointerEvents: 'none',
                      position: 'absolute',
                      left: hasIntersectionError ? -51 : 0,
                      top: -1,
                      right: 0,
                      bottom: -1,
                      border: `1px solid ${theme.palette.error.main}`,
                    })}
                  />
                )}
              </>
            )}
          </Stack>
        );
      }}
    />
  );
};

type HalfDayFormProps = {
  typeIdx: number;
  variantIdx: number;
  canEdit: boolean;
};

export const HalfDayForm: FC<HalfDayFormProps> = ({ typeIdx, variantIdx, canEdit }) => {
  const { watch, setValue } = useFormContext<ProductForm>();
  const variantPath = `types.${typeIdx}.variants.${variantIdx}` as const;
  const variant = watch(variantPath);

  const { $t } = useIntl();

  const handleChange = () => {
    setValue(variantPath, { ...variant, half_day: !variant.half_day });
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      onClick={canEdit ? handleChange : undefined}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          '.MuiTypography-root': {
            color: 'primary.main',
          },
        },
      }}
    >
      <HalfDayIconComponent
        isHalfDay={variant.half_day}
        tooltipTitle={
          canEdit ? (
            $t({ id: 'products-HalfDayTooltip' })
          ) : (
            <ProductBlockedEditingLabel labelId="products-CannotEditHalfDay" />
          )
        }
        onClick={canEdit ? handleChange : undefined}
        sx={{
          ' .lockIcon': { display: 'none' },
          ...(canEdit
            ? {}
            : {
                '&:hover': {
                  ' .lockIcon': { display: 'block' },
                  ' .checkIcon': { display: 'none' },
                },
              }),
        }}
        halfDayIcon={
          <>
            <CheckIcon className="checkIcon" />
            <LockIcon className="lockIcon" />
          </>
        }
      />
    </Stack>
  );
};

type FrequenciesPriceFormProps = {
  typeIndex: number;
  variantIdx: number;
  currencySymbol: string;
  frequencies: PaymentFrequency[];
  children?: (
    field: ControllerRenderProps<ProductForm, `types.${number}.variants.${number}.prices`>,
    errorMessage: VariantValidationError | undefined,
  ) => ReactNode;
  canRemove: boolean;
  onChange: (prevPrice: PriceValue, newPrice: PriceValue) => void;
};

export const FrequenciesPriceForm: FC<FrequenciesPriceFormProps> = ({
  variantIdx,
  typeIndex,
  frequencies,
  currencySymbol,
  children,
  canRemove,
  onChange,
}) => {
  const { control, formState } = useFormContext<ProductForm>();
  const pricesPath = `types.${typeIndex}.variants.${variantIdx}.prices` as const;
  const initPrices =
    formState.defaultValues?.types?.[typeIndex]?.variants?.[variantIdx]?.prices ?? [];

  const validateForRemovedPrices = (value: ProductVariantPrice[]) =>
    initPrices?.length > value.length ? VariantValidationError.PriceRemoved : undefined;

  const validateForEmptyPrices = (value: ProductVariantPrice[]) => {
    if (value.some((v) => !!v.price)) return;
    return VariantValidationError.NoPricesProvided;
  };

  return (
    <Controller
      control={control}
      name={pricesPath}
      rules={{ validate: canRemove ? validateForEmptyPrices : validateForRemovedPrices }}
      render={({ field, fieldState: { error } }) => {
        const getErrorMessage = () => {
          switch (error?.type) {
            case 'validate':
              if (error.message === VariantValidationError.NoPricesProvided) {
                return VariantValidationError.NoPricesProvided;
              }
              if (error.message === VariantValidationError.PriceRemoved) {
                return VariantValidationError.PriceRemoved;
              }
              break;
          }
        };

        return (
          <>
            {children
              ? children(field, getErrorMessage())
              : frequencies.map((fr) => {
                  const relatedPrice = field.value.find((p) => p.frequency_id === fr.id);

                  return (
                    <ProductPriceInput
                      key={fr.id}
                      currencySymbol={currencySymbol}
                      value={relatedPrice?.price}
                      onChange={(price) => {
                        field.onChange([
                          ...field.value.filter((p) => p.frequency_id !== fr.id),
                          ...(price ? [{ frequency_id: fr.id, price }] : []),
                        ]);
                        onChange(relatedPrice?.price, price);
                      }}
                    />
                  );
                })}
          </>
        );
      }}
    />
  );
};
