import { Box, Stack, Typography } from '@mui/material';
import {
  GetEnrollmentsResponse,
  StudentForCompany,
  useGetEnrollments,
  useGetSchoolPaymentFrequencies,
  useGetStudentsForCompanyListQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Counter, ModalSearch, PlusIcon, SimpleButton } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useCompany } from '../../../context/companies/WithCompany';
import { ProfileModalMode } from '../../../context/profile/helpers';
import useSchoolYears from '../../../hooks/useSchoolYears';
import {
  StudentWithProducts,
  StudentWithProductsSkeleton,
} from '../../ProfileModal/tabs/ProfileModalAssignedProductsList/StudentWithProducts';
import {} from '../../ProfileModal/tabs/ProfileModalDependants';
import { CompanyPreviewModalEmptyStub } from './CompanyPreviewModalEmptyStub';

export const CompanyPreviewModalStudents = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId = '', permissions } = useAuth();
  const { isLoading: isYearsFetching } = useSchoolYears();
  const { id: companyId, canEditCompanyStudents } = useCompany();

  const [checkEnrollmentStudentId, setCheckEnrollmentStudentId] = useState<string>('');

  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const navigateToAddStudent = useCallback(
    () => navigate(`/companies/${companyId}/add-student`),
    [companyId, navigate],
  );

  const navigateToProductAssignment = useCallback(
    (id: string) => navigate(`/students/${id}/products`),
    [navigate],
  );

  const handleEditProductAssignment = useCallback(
    (studentId: string, checkEnrollment: boolean) => {
      if (checkEnrollment) {
        setCheckEnrollmentStudentId(studentId);
        return;
      }
      navigateToProductAssignment(studentId);
    },
    [navigateToProductAssignment],
  );

  const handleCheckEnrollment = async (data: GetEnrollmentsResponse) => {
    setCheckEnrollmentStudentId('');
    const hasActiveEnrollment = data.enrollments.some((e) => !isDateInPast(e.school_year.end));

    if (!hasActiveEnrollment) {
      navigateToProductAssignment(checkEnrollmentStudentId);
      return;
    }

    const student = students?.find((s) => checkEnrollmentStudentId === s.relation.id)?.relation;
    const name = student
      ? getUserFullName(student)
      : formatMessage({ id: 'schoolProperty-Student' });

    const isConfirmed = await getConfirmation({
      message: formatMessage({ id: 'profile-NoRegistrations' }, { name }),
      cancelOnClose: false,
      cancelTextId: 'profile-GoToRegistrations',
      confirmTextId: 'action-Okay',
    });

    if (isConfirmed === false) {
      navigate(`/students/${checkEnrollmentStudentId}#${ProfileModalMode.Registrations}`);
    }
  };

  const { isFetching: isEnrollmentsFetching } = useGetEnrollments(
    { schoolId, studentId: checkEnrollmentStudentId ?? '' },
    {
      enabled: !!checkEnrollmentStudentId,
      onSuccess: handleCheckEnrollment,
    },
  );

  const {
    data,
    isLoading,
    isFetching,
    setParams,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetStudentsForCompanyListQuery(
    {
      query: '',
      companyId,
    },
    { enabled: !!companyId, refetchOnMount: 'always' },
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const students = useMemo(
    () =>
      data?.pages.reduce<StudentForCompany[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );
  const totalStudents = data?.pages[0].count ?? 0;

  const isEmpty = !isFetching && !isLoading && !totalStudents;

  const { data: frequenciesData } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId },
    { enabled: !!schoolId },
  );

  const renderContent = () => {
    if (isEmpty) {
      return (
        <CompanyPreviewModalEmptyStub
          emptySearchResults={!!params.query}
          onButtonClick={canEditCompanyStudents ? navigateToAddStudent : undefined}
          messageTextId={
            params.query ? 'companies-NoResultsMatching-Student' : 'companies-NoStudents'
          }
          buttonTextId="companies-AddStudent"
        />
      );
    }

    return (
      <Stack>
        {isLoading || isYearsFetching ? (
          <StudentWithProductsSkeleton />
        ) : (
          <Stack gap={4}>
            {students.map(({ relation, products }) => {
              const isCheckingEdit =
                isEnrollmentsFetching && checkEnrollmentStudentId === relation.id;

              return (
                <StudentWithProducts
                  key={relation.id}
                  student={relation}
                  products={products}
                  frequencies={frequenciesData?.frequencies}
                  onEdit={canEdit ? handleEditProductAssignment : undefined}
                  schoolId={schoolId}
                  isCheckingEdit={isCheckingEdit}
                />
              );
            })}
          </Stack>
        )}
        {hasNextPage && (
          <Box py={4}>
            <div ref={loaderRef} />
            <StudentWithProductsSkeleton />
          </Box>
        )}
      </Stack>
    );
  };

  return (
    <Stack height="100%" gap={1.75} marginTop={-0.75}>
      <Stack direction="row" justifyContent="space-between" minHeight={40}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            {formatMessage({ id: 'companies-StudentsAndProducts' })}
          </Typography>
          {!!totalStudents && <Counter>{totalStudents}</Counter>}
        </Stack>

        {!!(totalStudents || params.query) && (
          <Stack direction="row" gap={2.75}>
            <ModalSearch
              value={params.query}
              onChange_MemoizedCallbackOnly={handleSetFiltersQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
              withDebounce
            />
            {canEditCompanyStudents && (
              <SimpleButton inverse startIcon={<PlusIcon />} onClick={navigateToAddStudent}>
                {formatMessage({ id: 'companies-AddStudent' })}
              </SimpleButton>
            )}
          </Stack>
        )}
      </Stack>

      {renderContent()}
    </Stack>
  );
};
