import { ChipProps, Typography } from '@mui/material';
import { Spin, TagSelect, TypographyWithOverflowHint } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import useSchoolYears from 'apps/web/src/hooks/useSchoolYears';
import { FC, PropsWithChildren, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectRow } from '../PayerDropdown/PayerDropdown';
import { SelectContentSkeleton } from '../SelectContentSkeleton';

type SchoolYearExpandedSelectProps = PropsWithChildren<{
  selectedId: string;
  onSelect: (v: string) => void;
  onClose: () => void;
}>;
export const SchoolYearExpandedSelect: FC<SchoolYearExpandedSelectProps> = ({
  onClose,
  selectedId,
  onSelect,
}) => {
  const { schoolYears, isLoading } = useSchoolYears();

  const selectedYear = schoolYears.find((y) => y.id === selectedId);

  const renderLabel = useCallback((name: string, isSelected: boolean) => {
    return (
      <TypographyWithOverflowHint
        color={!isSelected ? 'common.grey2' : undefined}
        variant="h3"
        noWrap
      >
        {name}
      </TypographyWithOverflowHint>
    );
  }, []);

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    if (!schoolYears.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {schoolYears.map((y) => {
          const isSelected = selectedId === y.id;
          return (
            <SelectRow
              key={y.id}
              onSelect={() => {
                onSelect(y.id);
                onClose();
              }}
              isSelected={isSelected}
            >
              {renderLabel(y.name, isSelected)}
            </SelectRow>
          );
        })}
      </>
    );
  }, [isLoading, onClose, onSelect, renderLabel, schoolYears, selectedId]);

  return (
    <ExpandedSelect
      hasSelectedValue={!!selectedId}
      renderContent={renderContent}
      onClose={onClose}
      width={500}
    >
      {renderLabel(selectedYear?.name ?? '', true)}
    </ExpandedSelect>
  );
};

export type SchoolYearTagSelectProps = { id: string } & Omit<ChipProps, 'label'>;

export const SchoolYearTagSelect: FC<SchoolYearTagSelectProps> = ({ id, ...props }) => {
  const { schoolYears } = useSchoolYears();

  const schoolYear = schoolYears.find((y) => y.id === id);

  return <TagSelect label={schoolYear?.name ?? <Spin />} {...props} />;
};
