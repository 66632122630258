import { Skeleton, Stack } from '@mui/material';
import { PageHeader } from '@schooly/components/filters';
import { GridBody, GridHead, MainPageGrid, RowSkeleton, SkeletonRows } from '@schooly/style';
import { FC } from 'react';

import { PAGE_SIZE, SKELETON_COLUMNS } from './SchoolProductsContent';

export const SchoolProductsSkeleton: FC<{ withPageHeader?: boolean }> = ({
  withPageHeader = true,
}) => {
  return (
    <Stack gap={2}>
      {withPageHeader ? (
        <Stack gap={1}>
          <PageHeader pageTitleTextId="products-Title">
            <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
          </PageHeader>
          <Stack direction="row" alignItems="center" gap={2}>
            <Skeleton
              variant="rectangular"
              width={100}
              height={26}
              sx={{ '&&': { borderRadius: 13 } }}
            />
            <Skeleton
              variant="rectangular"
              width={100}
              height={26}
              sx={{ '&&': { borderRadius: 13 } }}
            />
            <Stack height={30} justifyContent="center">
              <Skeleton variant="rectangular" width={60} height={26} />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <div />
      )}
      <MainPageGrid>
        <GridHead borderBottom>
          <RowSkeleton columnsCount={SKELETON_COLUMNS} />
        </GridHead>
        <GridBody>
          <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />
        </GridBody>
      </MainPageGrid>
    </Stack>
  );
};
