import { Stack, StackProps, SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import React, { forwardRef, ReactNode } from 'react';

export type InformationBlockProps = {
  children?: ReactNode;
  sx?: SxProps<Theme>;
} & Omit<StackProps, 'sx'>;

export const InformationBlock = forwardRef<HTMLDivElement, InformationBlockProps>(
  ({ children, sx, ...stackProps }, ref) => {
    return (
      <Stack
        ref={ref}
        justifyContent="center"
        alignItems="center"
        direction="row"
        gap={1}
        p={3.25}
        {...stackProps}
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          bgcolor: 'background.default',
          ...((typeof sx === 'function' ? sx(theme) : sx) as SystemStyleObject),
        })}
      >
        {children}
      </Stack>
    );
  },
);
