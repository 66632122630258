import { Stack, Typography } from '@mui/material';
import {
  AssignedProduct,
  GetEnrollmentsResponse,
  useGetDependantStudentsForParentQuery,
  useGetEnrollments,
  useGetProductsForParentQuery,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { Counter, EyeIcon, ModalSearch } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../components/common/AccessDenied';
import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';
import useSchoolYears from '../../../hooks/useSchoolYears';
import getIsAccessDeniedError from '../../../utils/getIsAccessDeniedError';
import {
  StudentWithProducts,
  StudentWithProductsSkeleton,
} from './ProfileModalAssignedProductsList/StudentWithProducts';

export const ProfileModalDependants: FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { getConfirmation } = useConfirmationDialog();
  const { schoolId = '', permissions } = useAuth();

  const { actions, schoolMembership } = useProfile();
  const { isLoading: isYearsFetching } = useSchoolYears();

  const canView = permissions.includes('payer_and_product_assignment_viewer');
  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const [checkEnrollmentStudentId, setCheckEnrollmentStudentId] = useState<string>('');

  const [searchQuery, setSearchQuery] = useState('');

  const { data: frequenciesData } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId },
    { enabled: !!schoolId },
  );

  const {
    data: students,
    isLoading: isLoadingStudents,
    error,
  } = useGetDependantStudentsForParentQuery(
    { id: schoolMembership?.relation_id ?? '' },
    {
      enabled: !!schoolMembership?.relation_id,
      refetchOnMount: 'always',
    },
  );

  const { data, isLoading: isProductsLoading } = useGetProductsForParentQuery(
    { relationId: schoolMembership?.relation_id ?? '' },
    {
      enabled: !!schoolMembership?.relation_id,
      refetchOnMount: 'always',
    },
  );

  const navigateToProductAssignment = useCallback(
    (studentId: string) => {
      navigate(`/students/${studentId}/products`);
    },
    [navigate],
  );

  const handleEditProductAssignment = useCallback(
    (studentId: string, checkEnrollment: boolean) => {
      if (checkEnrollment) {
        setCheckEnrollmentStudentId(studentId);
        return;
      }
      navigateToProductAssignment(studentId);
    },
    [navigateToProductAssignment],
  );

  const handleCheckEnrollment = async (data: GetEnrollmentsResponse) => {
    setCheckEnrollmentStudentId('');
    const hasActiveEnrollment = data.enrollments.some((e) => !isDateInPast(e.school_year.end));

    if (hasActiveEnrollment) {
      navigateToProductAssignment(checkEnrollmentStudentId);
      return;
    }

    const student = students?.find((s) => checkEnrollmentStudentId === s.id);
    const name = student
      ? getUserFullName(student)
      : formatMessage({ id: 'schoolProperty-Student' });

    const isConfirmed = await getConfirmation({
      message: formatMessage({ id: 'profile-NoRegistrations' }, { name }),
      cancelOnClose: false,
      cancelTextId: 'profile-GoToRegistrations',
      confirmTextId: 'action-Okay',
    });

    if (isConfirmed === false) {
      navigate(`/students/${checkEnrollmentStudentId}#${ProfileModalMode.Registrations}`);
    }
  };

  const { isFetching: isEnrollmentsFetching } = useGetEnrollments(
    { schoolId, studentId: checkEnrollmentStudentId ?? '' },
    {
      enabled: canView && !!checkEnrollmentStudentId,
      onSuccess: handleCheckEnrollment,
    },
  );

  const { productCount, productsMap } = useMemo(() => {
    let products = data?.map((d) => ({ ...d.product, studentId: d.student_id })) ?? [];

    if (searchQuery) {
      products = products.filter(
        ({ name, variant }) =>
          name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          variant.type_name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    const productsMap =
      products.reduce((prev: Record<string, AssignedProduct[]>, { studentId, ...product }) => {
        if (!prev[studentId]) {
          prev[studentId] = [product];
        } else {
          prev[studentId].push(product);
        }

        return prev;
      }, {}) ?? {};

    return { productCount: products.length, productsMap };
  }, [data, searchQuery]);

  const renderContent = () => {
    if (isProductsLoading || isYearsFetching || isLoadingStudents)
      return <StudentWithProductsSkeleton />;

    if (searchQuery && !Object.keys(productsMap).length)
      return (
        <EmptyStub
          mode={ProfileModalMode.DependantsAndProducts}
          messageTextId="profile-NoResultsMatching"
          textWidth={300}
        />
      );

    return (
      <Stack gap={4}>
        {students?.map((student) => {
          const isCheckingEdit = isEnrollmentsFetching && checkEnrollmentStudentId === student.id;

          return (
            <StudentWithProducts
              key={student.id}
              products={productsMap[student.id] ?? []}
              student={student}
              frequencies={frequenciesData?.frequencies}
              schoolId={schoolId}
              onEdit={canEdit ? handleEditProductAssignment : undefined}
              isCheckingEdit={isCheckingEdit}
            />
          );
        })}
      </Stack>
    );
  };

  if (getIsAccessDeniedError(error) || !canView) {
    return <AccessDenied />;
  }

  const isEmpty = !isLoadingStudents && !students?.length;

  if (isEmpty) {
    return (
      <EmptyStub
        tabNameTextId="profile-DependantsAndProducts"
        mode={ProfileModalMode.DependantsAndProducts}
        messageTextId="profile-NoStudentAssigned"
        buttonTextId="profile-GoToFamilyPage"
        onButtonClick={() => actions.setMode(ProfileModalMode.Family)}
        buttonIcon={<EyeIcon />}
      />
    );
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={1.75}
        mt={-0.75}
      >
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-DependantsAndProducts" />
          </Typography>
          {!!productCount && <Counter>{productCount}</Counter>}
        </Stack>
        <ModalSearch
          value={searchQuery}
          onChange_MemoizedCallbackOnly={setSearchQuery}
          placeholder={formatMessage({ id: 'people-Search' })}
        />
      </Stack>
      {renderContent()}
    </>
  );
};
