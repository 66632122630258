import { IconButton, Stack, Typography } from '@mui/material';
import { FilterKeys, GetProductsQueryFilters, PRODUCTS_QUERY_FILTER_KEYS } from '@schooly/api';
import {
  FilterDropdown,
  FiltersContainer,
  MoreButton,
  MoreButtonOption,
  SchoolYearExpandedSelect,
  SchoolYearTagSelect,
} from '@schooly/components/filters';
import { CloseSmallIcon } from '@schooly/style';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type ProductFiltersProps = {
  defaultShowAllFrequencies: boolean;
  filters: GetProductsQueryFilters;
  defaultFilters: GetProductsQueryFilters;
  onSetShowAllFrequencies: Dispatch<SetStateAction<boolean>>;
  showAllFrequencies: boolean;
  showFrequenciesToggle: boolean;
  onSetFilters: (v: GetProductsQueryFilters) => void;
};

export const ProductFilters: FC<ProductFiltersProps> = ({
  defaultShowAllFrequencies,
  filters: actualFilters,
  defaultFilters,
  onSetShowAllFrequencies,
  showAllFrequencies,
  showFrequenciesToggle,
  onSetFilters,
}) => {
  const { $t } = useIntl();
  const moreButton = useRef<MoreButton | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetProductsQueryFilters>(actualFilters);
  const [draftShowAllFrequencies, setDraftShowAllFrequencies] = useState(defaultShowAllFrequencies);

  const handleApply = useMemo(() => {
    if (
      !PRODUCTS_QUERY_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      }) &&
      showAllFrequencies === draftShowAllFrequencies
    )
      return undefined;

    return () => {
      onSetFilters(draftFilters);
      onSetShowAllFrequencies(draftShowAllFrequencies);
    };
  }, [
    showAllFrequencies,
    draftShowAllFrequencies,
    draftFilters,
    actualFilters,
    onSetFilters,
    onSetShowAllFrequencies,
  ]);

  const handleResetToDefault = useCallback(() => {
    setDraftFilters(defaultFilters);
    setDraftShowAllFrequencies(defaultShowAllFrequencies);
  }, [defaultFilters, defaultShowAllFrequencies]);

  const handleClearFilters = useCallback(() => {
    setDraftFilters(defaultFilters);
    setDraftShowAllFrequencies(false);
  }, [defaultFilters]);

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    setDraftFilters((filters) => ({ ...filters, [v]: filters[v] !== undefined ? undefined : [] }));
  }, []);

  const { onSelectSchoolYear } = useMemo(() => {
    return {
      onSelectSchoolYear: (id: string) =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.YearId]: [id],
        })),
    };
  }, []);

  const filtersSchoolYear = draftFilters[FilterKeys.YearId];

  const schoolYearLabel = $t({ id: 'schoolYear' });

  const filterOptions: MoreButtonOption<keyof GetProductsQueryFilters>[] = [
    { value: FilterKeys.YearId, label: schoolYearLabel, required: true },
  ];

  return (
    <FiltersContainer onApply={handleApply}>
      {draftShowAllFrequencies && showFrequenciesToggle && (
        <Stack direction="row" gap={1}>
          <Typography variant="h3">
            <FormattedMessage id="frequencies-ShowDisabledFrequencies" />
          </Typography>
          <IconButton
            sx={{ fontSize: (theme) => theme.spacing(2) }}
            onClick={() => setDraftShowAllFrequencies(false)}
          >
            <CloseSmallIcon />
          </IconButton>
        </Stack>
      )}

      {filtersSchoolYear && (
        <FilterDropdown
          width={500}
          label={schoolYearLabel}
          tags={(open) =>
            filtersSchoolYear.map((id) => (
              <SchoolYearTagSelect key={id} sx={{ maxWidth: 200 }} id={id} onClick={open} />
            ))
          }
        >
          {(onClose) => (
            <SchoolYearExpandedSelect
              selectedId={filtersSchoolYear[0]}
              onSelect={onSelectSchoolYear}
              onClose={onClose}
            />
          )}
        </FilterDropdown>
      )}

      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterOptions}
        selectedOptions={PRODUCTS_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        onToggleOption={toggleFiltersVisible}
        toggleOptions={
          showFrequenciesToggle
            ? [
                {
                  key: 'frequencies-ShowDisabledFrequencies',
                  onToggle: () => setDraftShowAllFrequencies((s) => !s),
                  selected: draftShowAllFrequencies,
                  label: $t({ id: 'frequencies-ShowDisabledFrequencies' }),
                },
              ]
            : []
        }
      />
    </FiltersContainer>
  );
};
