import { Box, Button, Stack, Typography } from '@mui/material';
import {
  AssignedProductWithAvailableVariants,
  FilterKeys,
  PayerType,
  Product,
  ProductBillingType,
  ProductType,
  SchoolYear,
  SORT_DIRECTION,
  SyncUser,
  useGetProductsListQuery,
  YearlyProductAssignments,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { CompanySelect } from '@schooly/components/filters';
import {
  Counter,
  DoneIcon,
  DropdownYears,
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
  ModalPanel,
  Spin,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import withScrolling from 'react-dnd-scrolling';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ModalRightSidebar } from '../../../../../components/uikit-components/Modal/ModalRightSidebar';
import { convertProductAssignmentsToForm } from './helpers';
import { SetBulkFrequencyButton } from './SetBulkFrequencyButton';
import { StudentDefaultPayerSelect } from './StudentDefaultPayerSelect';
import { StudentProductsModalList } from './StudentProductsModalList';
import { PAGE_SIZE, StudentProductsModalSidebarFees } from './StudentProductsModalSidebarFees';

export type WithIndex = {
  index: number;
};

export type AssignedProductUpdate = Omit<
  AssignedProductWithAvailableVariants,
  'payer' | 'year_id'
> & {
  isExistingAssignment: boolean;
  payer_type: PayerType;
};

export type UpdateStudentProductsForm = {
  yearId: string;
  defaultPayerId: string;
  companyPayerId?: string;
  products: AssignedProductUpdate[];
};

type StudentProductsModalContentProps = {
  relationId: string;
  schoolId: string;
  onSubmit: (data: UpdateStudentProductsForm) => Promise<boolean | undefined>;
  isSaving: boolean;
  student?: SyncUser;
  schoolYear: SchoolYear;
  yearsForSelect: SchoolYear[];
  companyId?: string;
  productAssignmentsByYear: Record<string, YearlyProductAssignments>;
  onClose: () => void;
} & PropsWithChildren;

export type handleUpdateProductProps = {
  index: number;
  productUpdate: Partial<
    Omit<AssignedProductUpdate, 'variant'> & {
      variant: Partial<AssignedProductWithAvailableVariants['variant']>;
    }
  >;
};

const ScrollingComponent = withScrolling('div');

const calculateScrollingContentHeight = (
  modalContentCurrent: HTMLDivElement | null,
  modalContentHeaderCurrent: HTMLDivElement | null,
) => {
  if (!modalContentCurrent || !modalContentHeaderCurrent) {
    return 'auto';
  }

  return modalContentCurrent.clientHeight - modalContentHeaderCurrent.clientHeight - 10;
};

export const StudentProductsModalContent: FC<StudentProductsModalContentProps> = ({
  schoolId,
  relationId,
  onSubmit,
  isSaving,
  children,
  student,
  schoolYear,
  yearsForSelect,
  companyId: propsCompanyId,
  productAssignmentsByYear,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const { getConfirmation } = useConfirmationDialog();
  const panelRef = useRef<HTMLDivElement>(null);
  const [hasRef, setRef] = useState<boolean>(false);

  const [selectedYear, setSelectedYear] = useState<SchoolYear>(schoolYear);
  const [pendingYear, setPendingYear] = useState<SchoolYear | undefined>();

  const filters = useMemo(() => ({ [FilterKeys.YearId]: [selectedYear.id] }), [selectedYear.id]);
  const productsQueryState = useGetProductsListQuery(
    {
      schoolId,
      query: '',
      pageSize: PAGE_SIZE,
      relationId,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      type: ProductBillingType.Recurring,
      filters,
    },
    {
      refetchOnMount: 'always',
      enabled: !!schoolId && hasRef,
    },
  );
  const { data, params, isLoading, setParams } = productsQueryState;
  const hasNoProductsToAdd = !data?.pages[0].count && !params.query && !isLoading;

  const addedProducts = productAssignmentsByYear[selectedYear.id].products;

  const availableFrequencyIds = useMemo(
    () =>
      Array.from(
        addedProducts.reduce<Set<string>>((acc, p) => {
          p.available_variants.map((v) =>
            v.prices.map((p) => {
              acc.add(p.frequency_id);
              return null;
            }),
          );
          return acc;
        }, new Set()),
      ),
    [addedProducts],
  );

  const [[missingFrequencyId, missingFrequencyForVariantId], setMissingFrequencyForProductId] =
    useState<[string, Set<string>]>(['', new Set<string>()]);

  const form = useForm<UpdateStudentProductsForm>({
    defaultValues: convertProductAssignmentsToForm(
      productAssignmentsByYear[selectedYear.id],
      propsCompanyId,
    ),
  });

  const { append, update } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const getExistingAssignment = useCallback(
    (variantId: string) => addedProducts.find((p) => p.variant.id === variantId),
    [addedProducts],
  );

  const handleSelectProductWithType = useCallback(
    ({ product, type }: { product: Product; type: ProductType }) => {
      const variant = type.variants[0];

      const existingAssignment = getExistingAssignment(variant.id);

      const frequency_id =
        existingAssignment?.variant.frequency_id ?? variant?.prices[0].frequency_id;

      if (!variant || !frequency_id) return;

      const studentProduct: AssignedProductUpdate = {
        id: product.id,
        payer_type: PayerType.Default,
        obligatory: product.obligatory,
        product_type: product.type,
        name: product.name,
        isExistingAssignment: !!existingAssignment,
        variant: {
          id: variant.id,
          price: variant.prices[0].price,
          type_name: type.name,
          currency: type.billing_connection.legal_entity_currency,
          frequency_id,
        },
        available_variants: product.types
          .map((type) =>
            type.variants.map((variant) => ({
              type_id: type.id,
              type_name: type.name,
              id: variant.id,
              half_day: variant.half_day,
              prices: variant.prices,
              currency: type.billing_connection.legal_entity_currency,
            })),
          )
          .flat(),
      };

      append(studentProduct);
    },
    [append, getExistingAssignment],
  );

  const companyPayerId = form.watch('companyPayerId');
  const studentProducts = form.watch('products');

  const handleUpdateProduct = useCallback(
    ({ index, productUpdate }: handleUpdateProductProps) => {
      const product = form.getValues('products')[index];

      if (!product) return;

      const shouldEvaluateMissingFrequency =
        productUpdate.variant?.frequency_id &&
        product.variant.frequency_id !== productUpdate.variant?.frequency_id;

      if (shouldEvaluateMissingFrequency) {
        setMissingFrequencyForProductId(([id, set]) => {
          const updatedSet = new Set(set);
          updatedSet.delete(productUpdate.variant?.id || product.variant.id);
          return [id, updatedSet];
        });
      }

      const variant = { ...product.variant, ...productUpdate.variant };
      update(index, { ...product, ...productUpdate, variant: { ...variant } });
    },
    [update, form],
  );

  const handleChangeProductsFrequencyId = useCallback(
    (id: string) => {
      const missingFrequencyForVariantId = new Set<string>();
      form.getValues('products').map((product, i) => {
        //Based on TR-6701 frequency selection is temporary blocked for existing assignments
        if (product.isExistingAssignment) return null;

        const productVariant = product.available_variants.find((v) => v.id === product.variant.id);

        if (!productVariant?.prices.some((p) => p.frequency_id === id)) {
          missingFrequencyForVariantId.add(product.variant.id);
          return null;
        }

        handleUpdateProduct({ index: i, productUpdate: { variant: { frequency_id: id } } });
        return null;
      });

      setMissingFrequencyForProductId([id, missingFrequencyForVariantId]);
    },
    [handleUpdateProduct, form],
  );

  const showConfirm = !!pendingYear;

  const hideConfirm = useCallback(() => {
    if (showConfirm) {
      setPendingYear(undefined);
    }
  }, [showConfirm]);

  const handleChangeYear = useCallback(
    (year: SchoolYear) => {
      form.reset(
        convertProductAssignmentsToForm(productAssignmentsByYear[year.id], propsCompanyId),
      );
      setSelectedYear(year);
      hideConfirm();
    },
    [form, hideConfirm, productAssignmentsByYear, propsCompanyId],
  );

  const handleChangeYearWithSave = useCallback(
    async (year: SchoolYear) => {
      await onSubmit(form.getValues());
      handleChangeYear(year);
    },
    [onSubmit, form, handleChangeYear],
  );

  const getConfirmationContent = useCallback(
    (textId: string) => {
      return (
        <Typography variant="h1">
          {formatMessage(
            { id: textId },
            {
              yearName: selectedYear.name,
              yearType: (
                <Typography component="span" variant="inherit" sx={{ textDecoration: 'underline' }}>
                  {formatMessage({
                    id: isDateInPast(selectedYear.start) ? 'current' : 'future',
                  }).toLowerCase()}
                </Typography>
              ),
            },
          )}
        </Typography>
      );
    },
    [formatMessage, selectedYear.name, selectedYear.start],
  );

  const handleSubmit = useCallback(
    async (d: UpdateStudentProductsForm) => {
      const confirmed = await getConfirmation({
        content: getConfirmationContent('profile-SaveFeeAssignmentsConfirmation'),
      });

      if (!confirmed) return;

      await onSubmit(d);
      onClose();
    },
    [getConfirmation, getConfirmationContent, onClose, onSubmit],
  );

  const [payerProducts, companyProducts] = useMemo(() => {
    return studentProducts.reduce<Array<(AssignedProductUpdate & WithIndex)[]>>(
      (acc, p, index) => {
        p.payer_type === PayerType.Company
          ? acc[1].push({ ...p, index })
          : acc[0].push({ ...p, index });
        return acc;
      },
      [[], []],
    );
  }, [studentProducts]);

  const modalContentRef = useRef<HTMLDivElement | null>(null);
  const modalContentHeaderRef = useRef<HTMLDivElement | null>(null);

  const [scrollingContentHeight, setScrollingContentHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    const height = calculateScrollingContentHeight(
      modalContentRef.current,
      modalContentHeaderRef.current,
    );
    setScrollingContentHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalContentRef.current, modalContentHeaderRef.current]);

  useEffect(() => {
    const handleResize = () => {
      const height = calculateScrollingContentHeight(
        modalContentRef.current,
        modalContentHeaderRef.current,
      );
      setScrollingContentHeight(height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setParams((p) => ({ ...p, filters }));
  }, [filters, setParams]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          {children}
          <ModalMain sx={{ flexDirection: 'column' }}>
            <ModalPanel ref={panelRef} active sx={{ pb: 1 }}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <Typography variant="h2">
                    {formatMessage({ id: 'profile-RecurringProducts' })}
                  </Typography>
                  {!!studentProducts.length && <Counter>{studentProducts.length}</Counter>}
                </Stack>

                <Stack direction="row" gap={3}>
                  {availableFrequencyIds.length > 1 && (
                    <SetBulkFrequencyButton
                      schoolId={schoolId}
                      yearId={selectedYear.id}
                      optionIds={availableFrequencyIds}
                      onSetFrequency={handleChangeProductsFrequencyId}
                    />
                  )}

                  <DropdownYears
                    years={yearsForSelect}
                    defaultYear={schoolYear}
                    currentYear={selectedYear}
                    onYearChange={(y) => {
                      if (y.id !== selectedYear.id) {
                        const { defaultPayerId } = form.formState.dirtyFields;
                        const isDirty = form.formState.isDirty || !!defaultPayerId;
                        if (isDirty) {
                          setPendingYear(y);
                        } else {
                          handleChangeYear(y);
                        }
                      }
                    }}
                  />
                </Stack>
              </Stack>

              <OnLayout onLayout={setRef} />
            </ModalPanel>

            {hasRef && (
              <ModalRightSidebar open anchorEl={panelRef.current}>
                <StudentProductsModalSidebarFees
                  productsQueryState={productsQueryState}
                  onSelectProduct={handleSelectProductWithType}
                  selectedProducts={studentProducts}
                />
              </ModalRightSidebar>
            )}

            <ModalContent ref={modalContentRef} active sx={{ pt: 0, overflowY: 'hidden' }}>
              <Box mb={1} ref={modalContentHeaderRef}>
                <Controller
                  control={form.control}
                  name="defaultPayerId"
                  render={({ field }) => (
                    <StudentDefaultPayerSelect
                      schoolId={schoolId}
                      relationId={relationId}
                      selectedId={field.value}
                      onSelectId={field.onChange}
                    />
                  )}
                />
              </Box>

              <ScrollingComponent
                style={{
                  height: scrollingContentHeight,
                  overflow: 'scroll',
                  paddingRight: '10px',
                }}
              >
                <StudentProductsModalList
                  relationId={relationId}
                  schoolId={schoolId}
                  payerType={PayerType.Default}
                  form={form}
                  yearId={selectedYear.id}
                  onUpdateProduct={handleUpdateProduct}
                  missingFrequencyId={missingFrequencyId}
                  missingFrequencyForVariantId={missingFrequencyForVariantId}
                  products={payerProducts}
                  canDrag={!!companyPayerId}
                  getExistingAssignment={getExistingAssignment}
                  canAdd={!hasNoProductsToAdd}
                />

                <Stack mt={5} gap={1} mb={2.5}>
                  <Controller
                    control={form.control}
                    name="companyPayerId"
                    render={({ field }) => (
                      <CompanySelect
                        selectedId={field.value}
                        schoolId={schoolId}
                        disabled={hasNoProductsToAdd || (!addedProducts.length && isLoading)}
                        onSelectCompanyId={field.onChange}
                        placeholder={formatMessage({ id: 'profile-PayingCompany' })}
                        onClear={async (companyName) => {
                          if (companyProducts.length) {
                            const confirmed = await getConfirmation({
                              textId: 'profile-AreYouSureYouWantToRemoveTheCompany',
                              textValues: {
                                companyName: companyName ? `"${companyName}"` : '',
                                studentName: student
                                  ? getUserFullName(student)
                                  : formatMessage({ id: 'userType-student' }).toLowerCase(),
                              },
                            });

                            if (!confirmed) return;

                            field.onChange('');
                            companyProducts.forEach(({ index }) => {
                              handleUpdateProduct({
                                index,
                                productUpdate: { payer_type: PayerType.Default },
                              });
                            });
                          } else {
                            field.onChange('');
                          }
                        }}
                      />
                    )}
                  />

                  {(companyPayerId || hasNoProductsToAdd) && (
                    <StudentProductsModalList
                      relationId={relationId}
                      schoolId={schoolId}
                      payerType={PayerType.Company}
                      form={form}
                      yearId={selectedYear.id}
                      onUpdateProduct={handleUpdateProduct}
                      missingFrequencyId={missingFrequencyId}
                      missingFrequencyForVariantId={missingFrequencyForVariantId}
                      products={companyProducts}
                      canDrag
                      getExistingAssignment={getExistingAssignment}
                      canAdd={!hasNoProductsToAdd}
                    />
                  )}
                </Stack>
              </ScrollingComponent>
            </ModalContent>
          </ModalMain>
          <ModalFooter active>
            <Button
              disabled={isSaving}
              startIcon={isSaving && !showConfirm ? <Spin /> : <DoneIcon />}
              type="submit"
            >
              {formatMessage({ id: 'action-Save' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>

      {showConfirm && (
        <StudentProductsConfirmModal
          onClose={isSaving ? undefined : hideConfirm}
          onCancel={() => handleChangeYear(pendingYear)}
          onConfirm={() => handleChangeYearWithSave(pendingYear)}
          title={getConfirmationContent('profile-SaveChangesForYear')}
          saving={isSaving}
        />
      )}
    </>
  );
};

const OnLayout: FC<{ onLayout: (v: boolean) => void }> = ({ onLayout }) => {
  useEffect(() => {
    onLayout(true);

    return () => onLayout(false);
  }, [onLayout]);

  return null;
};

type StudentProductsConfirmModalProps = {
  onClose?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  title: ReactNode;
  saving: boolean;
};

const StudentProductsConfirmModal: FC<StudentProductsConfirmModalProps> = ({
  onClose,
  onCancel,
  onConfirm,
  title,
  saving,
}) => {
  return (
    <ModalConfirm open onClose={onClose} fullWidth>
      <ModalHeader multiline title={title} withBorderBottom={false} active />
      <ModalFooter withBorderTop={false} active>
        <Stack gap={2} direction="row" flexGrow={1}>
          <Button fullWidth variant="outlined" onClick={onCancel} disabled={saving}>
            <FormattedMessage id="no" />
          </Button>
          <Button
            onClick={onConfirm}
            fullWidth
            color="primary"
            disabled={saving}
            endIcon={saving ? <Spin /> : undefined}
          >
            <FormattedMessage id="yes" />
          </Button>
        </Stack>
      </ModalFooter>
    </ModalConfirm>
  );
};
