import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import {
  AssignedProduct,
  PaymentFrequency,
  ProductBillingType,
  WithAvatar,
  WithName,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { EditIcon, PlusIcon, Spin, TypographyWithOverflowHint } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, ReactNode, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ProfileModalMode } from '../../../../context/profile/helpers';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import { AssignedProductsListSkeleton } from './AssignedProductsList';
import { AssignedProductsTable } from './AssignedProductsTable';

type StudentWithProductsProps<S extends { id: string } & WithName & WithAvatar> = {
  student: S;
  products: AssignedProduct[];
  frequencies?: PaymentFrequency[];
  schoolId: string;
  onEdit?: (studentId: string, checkEnrollment: boolean) => void;
  isCheckingEdit: boolean;
};

export const StudentWithProducts = <S extends { id: string } & WithName & WithAvatar>({
  student,
  products,
  frequencies,
  schoolId,
  onEdit,
  isCheckingEdit,
}: StudentWithProductsProps<S>) => {
  const { formatMessage } = useIntl();
  const { getSchoolYearById, schoolYears } = useSchoolYears();

  const { productsMap, recurringProductsYearIds, hasProducts, hasRecurringProducts } =
    useMemo(() => {
      const productsMap = products.reduce<Record<string, AssignedProduct[]>>((acc, product) => {
        const yearId = product.year_id;
        const isRecurring = product.product_type === ProductBillingType.Recurring;
        //recurring products should be grouped by year, one-off products should be on one group
        const key = isRecurring ? yearId : ProductBillingType.OneOff;
        acc[key] = [...(acc[key] ?? []), product];
        return acc;
      }, {});

      //should only show recurring products for not past years
      const recurringProductsYearIds = schoolYears
        .filter(({ id, end }) => !isDateInPast(end) && productsMap[id])
        .map(({ id }) => id);

      const hasRecurringProducts = Boolean(recurringProductsYearIds.length);
      const hasProducts = Boolean(hasRecurringProducts || productsMap[ProductBillingType.OneOff]);

      return { productsMap, recurringProductsYearIds, hasProducts, hasRecurringProducts };
    }, [products, schoolYears]);

  return (
    <Box>
      <Stack direction="row" mb={1} p={1} justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5} sx={{ overflowX: 'hidden' }}>
          <AvatarAuth user={student} withAvatarPreview />
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            component={Link}
            to={{
              pathname: `/students/${student.id}`,
              hash: `#${ProfileModalMode.PayersAndProducts}`,
            }}
            sx={{ ':hover': { textDecoration: 'underline', color: 'primary.main' } }}
          >
            {getUserFullName(student)}
          </TypographyWithOverflowHint>
        </Stack>
        {onEdit && hasProducts && (
          <IconButton
            sx={{ color: 'common.grey2' }}
            onClick={() => onEdit(student.id, !hasRecurringProducts)}
          >
            {isCheckingEdit ? <Spin /> : <EditIcon />}
          </IconButton>
        )}
      </Stack>

      {hasProducts ? (
        <Stack gap={2}>
          {recurringProductsYearIds.map((id) => (
            <AssignedProductsTable
              key={id}
              products={productsMap[id]}
              frequencies={frequencies}
              headerTitle={getSchoolYearById(id)?.name ?? ''}
              schoolId={schoolId}
            />
          ))}

          {productsMap[ProductBillingType.OneOff] && (
            <AssignedProductsTable
              key={ProductBillingType.OneOff}
              products={productsMap[ProductBillingType.OneOff]}
              frequencies={frequencies}
              headerTitle={formatMessage({ id: 'profile-Issued' })}
              schoolId={schoolId}
              isActive={false}
            />
          )}
        </Stack>
      ) : (
        <StudentWithProductsListEmpty
          textId="profile-StudentHasNoProductAssignments"
          button={
            onEdit ? (
              <Button
                startIcon={isCheckingEdit ? <Spin /> : <PlusIcon />}
                onClick={() => onEdit(student.id, true)}
                size="small"
              >
                {formatMessage({ id: 'profile-AddProduct' })}
              </Button>
            ) : undefined
          }
        />
      )}
    </Box>
  );
};

export const StudentWithProductsSkeleton = () => {
  return (
    <>
      <Stack direction="row" mb={1} p={1.5} pl={1}>
        <Typography variant="h3">
          <Skeleton width={150} />
        </Typography>
      </Stack>
      <AssignedProductsListSkeleton />
    </>
  );
};

type StudentWithProductsListEmptyProps = {
  button: ReactNode;
  textId: string;
};

export const StudentWithProductsListEmpty: FC<StudentWithProductsListEmptyProps> = ({
  textId,
  button,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
      p={button ? 1.25 : 1.75}
      bgcolor="background.paper"
      sx={(theme) => ({ borderRadius: theme.spacing(1) })}
    >
      <Typography variant="h3" color={button ? 'primary.main' : 'text.secondary'}>
        {formatMessage({ id: textId })}
      </Typography>

      {button}
    </Stack>
  );
};
