import { Stack, Typography } from '@mui/material';
import { EmptyFolderSvg } from '@schooly/style';
import { FC, ReactNode } from 'react';

type EmptyTypesProps = {
  message: ReactNode;
  actions?: ReactNode;
};
export const EmptyTypes: FC<EmptyTypesProps> = ({ message, actions }) => {
  return (
    <Stack flex={1} justifyContent="center" alignItems="center">
      <EmptyFolderSvg />

      <Typography
        variant="h3"
        color="text.primary"
        mt={5}
        mb={3}
        whiteSpace="pre-wrap"
        textAlign="center"
      >
        {message}
      </Typography>
      {actions}
    </Stack>
  );
};
