import {
  AgeGroup,
  AvailableCriteriaCount,
  BaseSearchResult,
  EXPIRED_RE_ENROLLMENT,
  FilterElementType,
  FilterKeys,
  GET_ANNUAL_PLAN_QUERY,
  GET_AREAS_OF_LEARNING_QUERY,
  GET_ASSESSMENT_GROUPS_QUERY,
  GET_ASSESSMENTS_CHART_QUERY,
  GET_ASSESSMENTS_FOR_RELATION_QUERY,
  GET_ASSESSMENTS_QUERY,
  GET_ATTENDANCE_CODES_QUERY,
  GET_ATTENDANCE_ENTRIES_FOR_PERIOD_QUERY,
  GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY,
  GET_ATTENDANCE_REGISTERS_QUERY,
  GET_ATTENDANCE_STATS_QUERY,
  GET_AVAILABLE_RELATION_GROUPS_QUERY,
  GET_BILLING_COUNTS_QUERY,
  GET_COMPANIES_QUERY,
  GET_CONDUCT_ENTRIES_QUERY,
  GET_CONDUCT_STATS_QUERY,
  GET_EVENTS_CHART_QUERY,
  GET_EVENTS_QUERY,
  GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY,
  GET_GROUP_CHART_QUERY,
  GET_GROUP_DETAILS_QUERY,
  GET_GROUPS_AGGREGATED_DATA_QUERY,
  GET_GROUPS_FOR_RELATION_QUERY,
  GET_GROUPS_QUERY,
  GET_LEGAL_ENTITIES_QUERY,
  GET_MEMBERSHIP_QUERY,
  GET_MESSAGES_QUERY,
  GET_PARENTS_QUERY,
  GET_PAYABLE_FEES,
  GET_PAYABLE_FEES_STATISTICS,
  GET_PAYMENT_FREQUENCIES_QUERY,
  GET_PRODUCTS_FOR_PARENT_QUERY,
  GET_PRODUCTS_QUERY,
  GET_REPORT_RECIPIENTS_QUERY,
  GET_REPORTS_FOR_RELATION_QUERY,
  GET_REPORTS_QUERY,
  GET_SIGN_UPS_LIST_QUERY,
  GET_STAFF_CHART_QUERY,
  GET_STAFF_QUERY,
  GET_STUDENT_CHART_QUERY,
  GET_STUDENT_PRODUCTS_QUERY,
  GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY,
  GET_STUDENTS_FOR_COMPANY_QUERY,
  GET_STUDENTS_INCONSISTENCIES_QUERY,
  GET_STUDENTS_QUERY,
  GET_STUDENTS_WITH_PRODUCTS_QUERY,
  LeavingReason,
  ParentSearchResult,
  PENDING_PARENT_RESPONSE,
  SchoolProperty,
} from '@schooly/api';
import { CHARTS_SEARCH_PARAM, ZEROES_SEARCH_PARAM } from '@schooly/components/charts';
import { Genders, Nationalities } from '@schooly/constants';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { MoreButtonOption } from './MoreButton';

export const ARRANGE_BY_SEARCH_PARAM = 'arrange_by';
const GROUP_BY_SEARCH_PARAM = 'group_by';
const SHOW_BY_PRESENT_ABSENT_SEARCH_PARAM = 'show_by_present_absent';

type SyncPathName =
  | '/planner'
  | '/students'
  | '/staff'
  | '/parents'
  | '/groups'
  | '/messages'
  | '/assessments'
  | '/reports'
  | '/attendance'
  | '/conduct'
  | '/events'
  | '/signups'
  | '/payablefees'
  | '/settings/products';

export const useSyncFiltersStateWithSearchParams = ({
  pathname,
  filters,
  arrangeBy,
  groupBy,
  showByPresentAbsent,
  charts,
  zeroes,
}: {
  pathname: SyncPathName;
  filters: Partial<Record<FilterKeys, any>>;
  arrangeBy?: FilterKeys | null;
  groupBy?: FilterKeys | null;
  showByPresentAbsent?: boolean;
  charts?: boolean;
  zeroes?: boolean;
}) => {
  const { pathname: currentPathname } = useLocation();

  useEffect(() => {
    if (currentPathname !== pathname) return;

    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${(Object.keys(filters) as FilterKeys[])
        .map((key) =>
          filters[key] ? `${key}=${encodeURIComponent(JSON.stringify(filters[key]))}` : undefined,
        )
        .concat(arrangeBy !== undefined ? `${ARRANGE_BY_SEARCH_PARAM}=${arrangeBy}` : undefined)
        .concat(groupBy !== undefined ? `${GROUP_BY_SEARCH_PARAM}=${groupBy}` : undefined)
        .concat(
          showByPresentAbsent !== undefined
            ? `${SHOW_BY_PRESENT_ABSENT_SEARCH_PARAM}=${showByPresentAbsent}`
            : undefined,
        )
        .concat(charts !== undefined ? `${CHARTS_SEARCH_PARAM}=${charts}` : undefined)
        .concat(zeroes !== undefined ? `${ZEROES_SEARCH_PARAM}=${zeroes}` : undefined)
        .filter(Boolean)
        .join('&')}`,
    );
  }, [filters, arrangeBy, groupBy, showByPresentAbsent, pathname, currentPathname, charts, zeroes]);
};

// Get filter values by filters keys from search params and merge with
// default filters or use initial filters
export const useFiltersStateFromSearchParams = <T extends string>({
  filterKeys,
  defaultFilters,
  initialFilters = {},
}: {
  filterKeys: readonly T[];
  defaultFilters: Partial<Record<T, any>>;
  initialFilters?: Partial<Record<T, any>>;
}) => {
  const [searchParams] = useSearchParams();
  const filtersFromSearchParams: Partial<Record<T, any>> = {};

  for (const key of filterKeys) {
    const param = searchParams.get(key);

    if (!param) continue;

    try {
      filtersFromSearchParams[key] = JSON.parse(decodeURIComponent(param));
    } catch (e) {
      console.warn('Cannot properly decode filter key for', key);
    }
  }

  return Object.keys(filtersFromSearchParams).length
    ? { ...defaultFilters, ...filtersFromSearchParams }
    : { ...defaultFilters, ...initialFilters };
};

export const useArrangeByFromSearchParams = <T extends string>(arrangeByKeys: readonly T[]) => {
  const [searchParams] = useSearchParams();
  const arrangeByParam = searchParams.get(ARRANGE_BY_SEARCH_PARAM);
  if (!arrangeByParam) return undefined;

  for (const key of arrangeByKeys) {
    if (key === arrangeByParam) return key;
  }
  return null;
};

export const useGroupByFromSearchParams = <T extends string>(groupByKeys: readonly T[]) => {
  const [searchParams] = useSearchParams();
  const groupByParam = searchParams.get(GROUP_BY_SEARCH_PARAM);

  if (!groupByParam) return undefined;

  for (const key of groupByKeys) {
    if (key === groupByParam) return key;
  }
  return null;
};

// Used to filter out params not included in filterKeys array
// to perform safe request to the api
export const pickOnlyParamsFromFilterKeys = <T extends string>(
  filterKeys: readonly T[],
  filters: Partial<Record<T, any>>,
) => {
  const result: Partial<Record<T, any>> = {};

  for (const key of filterKeys) {
    const value = filters[key];

    if (value && (!Array.isArray(value) || value.length)) result[key] = value;
  }

  return result;
};

export const checkHasFiltersApplied = <T extends string>(
  filterKeys: readonly T[],
  filters: Partial<Record<T, any>>,
) => {
  return filterKeys.some((key) => {
    const value = filters[key];
    return value && Array.isArray(value) ? value.length : true;
  });
};

type ArrangeBySection<T> = {
  count: number;
  arrangeByKey: T;
  arrangeByValue: string | number;
  title: string;
  isNoneSection?: boolean;
};

export const getArrangeBySectionsFromAvailableCriteria = <T extends FilterKeys>({
  criteria,
  arrangeByKey,
  allowZeroCounts = true,
}: {
  criteria: AvailableCriteriaCount[];
  arrangeByKey: T;
  allowZeroCounts?: boolean;
}): ArrangeBySection<T>[] => {
  return criteria.reduce<ArrangeBySection<T>[]>((acc, criteria) => {
    if (!allowZeroCounts && !criteria.relation_count) {
      return acc;
    }

    const initialProps = {
      count: criteria.relation_count,
    };

    switch (arrangeByKey) {
      case FilterKeys.Gender: {
        if (criteria.type !== FilterElementType.Gender || criteria.enum_index === undefined) {
          return acc;
        }

        const title = Genders[criteria.enum_index] || '';

        return [
          ...acc,
          {
            ...initialProps,
            arrangeByKey,
            arrangeByValue: criteria.enum_index,
            title,
            isNoneSection: !title,
          },
        ];
      }
      case FilterKeys.Nationality: {
        if (criteria.type !== FilterElementType.Nationality || criteria.enum_index === undefined) {
          return acc;
        }

        if (!criteria.relation_count) {
          return acc;
        }

        const title = Nationalities[criteria.enum_index] || '';

        return [
          ...acc,
          {
            ...initialProps,
            arrangeByKey,
            arrangeByValue: criteria.enum_index,
            title,
            isNoneSection: !title,
          },
        ];
      }
      case FilterKeys.TutorGroup: {
        if (criteria.type !== FilterElementType.TutorGroup || !criteria.tutor_group) {
          return acc;
        }

        const title = criteria.tutor_group.name;

        return [
          ...acc,
          {
            ...initialProps,
            arrangeByKey,
            arrangeByValue: criteria.tutor_group.id,
            title,
            isNoneSection: !title,
          },
        ];
      }

      default:
        if (
          criteria.type !== FilterElementType.Property ||
          !criteria.school_property ||
          criteria.school_property.type !== arrangeByKey.valueOf()
        ) {
          return acc;
        }

        if (criteria.school_property?.archived) {
          return acc;
        }

        const title = criteria.school_property.name;

        return [
          ...acc,
          {
            ...initialProps,
            arrangeByKey,
            arrangeByValue: criteria.school_property.id,
            title,
            isNoneSection: !title,
          },
        ];
    }
  }, []);
};

export type CombinedRow<T> = {
  id: string;
  key: string;
  item: T;
  additionalRows: CombinedRow<T>[];
  additionalPermanentRows: CombinedRow<T>[];
};

// It must be done on a BE side
// why are we doing it here? :(
export const getCombinedRowsFromSearchResults = <T extends BaseSearchResult>(
  results: T[],
): CombinedRow<T>[] => {
  return results.reduce((acc, item) => {
    const row = {
      key: `${item.id}-${item.registration.id}-${item.registration.school_user_relation.relation_id}`,
      id: item.registration.school_user_relation.relation_id,
      item,
      additionalRows: [],
      additionalPermanentRows: [],
    };

    const lastRow = acc.length > 0 && acc[acc.length - 1];

    if (!lastRow || lastRow.id !== row.id) {
      return [...acc, row];
    }

    const lastAdditionalRow =
      lastRow.additionalRows.length > 0 &&
      lastRow.additionalRows[lastRow.additionalRows.length - 1];

    if (lastAdditionalRow) {
      // If the last added registration has the same id, it means this is another status
      // of the same registration, no need to add it as one more registration row.
      if (lastAdditionalRow.item.registration.id === row.item.registration.id) {
        lastAdditionalRow.additionalPermanentRows.push(row);
      } else {
        lastRow.additionalRows.push(row);
      }
    } else {
      // If the last added registration has the same id, it means this is another status
      // of the same registration, no need to add it as one more registration row.
      if (lastRow.item.registration.id === row.item.registration.id) {
        lastRow.additionalPermanentRows.push(row);
      } else {
        lastRow.additionalRows.push(row);
      }
    }

    return acc;
  }, [] as CombinedRow<T>[]);
};

// It must be done on a BE side
// why are we doing it here? :(
export const getCombinedRowsFromParentSearchResults = <T extends ParentSearchResult>(
  results: T[],
): CombinedRow<T>[] => {
  return results.reduce((acc, item, i) => {
    const row = {
      key: item.relation_id + i,
      id: item.relation_id,
      item,
      additionalRows: [],
      additionalPermanentRows: [],
    };

    const lastRow = acc.length > 0 && acc[acc.length - 1];

    if (!lastRow || lastRow.id !== row.id) {
      return [...acc, row];
    }

    lastRow.additionalRows.push(row);

    return acc;
  }, [] as CombinedRow<T>[]);
};

type RefetchFor =
  | 'staff'
  | 'student'
  | 'child'
  | 'adult'
  | 'parent'
  | 'assessment'
  | 'group'
  | 'message'
  | 'report'
  | 'attendance'
  | 'attendanceList'
  | 'profile'
  | 'conduct'
  | 'event'
  | 'signup'
  | 'product'
  | 'company'
  | 'annualPlanner'
  | 'legalEntity'
  | 'payableFee'
  | 'frequencies';

const ANNUAL_PLANNER_QUERIES = [GET_ANNUAL_PLAN_QUERY];

const ADULT_QUERIES = [
  GET_PARENTS_QUERY,
  GET_STAFF_QUERY,
  GET_STAFF_CHART_QUERY,
  GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY,
  [GET_MEMBERSHIP_QUERY, 'parent'],
  [GET_MEMBERSHIP_QUERY, 'staff'],
];

const STUDENT_PRODUCTS_QUERY = [
  GET_STUDENT_PRODUCTS_QUERY,
  GET_STUDENTS_FOR_COMPANY_QUERY,
  GET_STUDENTS_WITH_PRODUCTS_QUERY,
];

const PARENT_QUERIES = [GET_PARENTS_QUERY, [GET_MEMBERSHIP_QUERY, 'parent']];

const STUDENT_QUERIES = [
  GET_STUDENTS_QUERY,
  GET_STUDENT_CHART_QUERY,
  GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY,
  [GET_MEMBERSHIP_QUERY, 'student'],
];
const STAFF_QUERIES = [
  GET_STAFF_QUERY,
  GET_STAFF_CHART_QUERY,
  GET_GROUP_AVAILABLE_CRITERIA_COUNT_QUERY,
  [GET_MEMBERSHIP_QUERY, 'staff'],
];
const ASSESSMENT_QUERIES = [
  GET_ASSESSMENTS_QUERY,
  GET_ASSESSMENTS_CHART_QUERY,
  GET_ASSESSMENTS_FOR_RELATION_QUERY,
];

const ROLLOVER_QUERIES = [GET_STUDENTS_INCONSISTENCIES_QUERY];
const GROUP_QUERIES = [
  GET_GROUPS_FOR_RELATION_QUERY,
  GET_GROUPS_QUERY,
  GET_GROUP_CHART_QUERY,
  GET_GROUP_DETAILS_QUERY,
  GET_GROUPS_AGGREGATED_DATA_QUERY,
  GET_AVAILABLE_RELATION_GROUPS_QUERY,
  GET_ASSESSMENT_GROUPS_QUERY,
];
const MESSAGE_QUERIES = [GET_MESSAGES_QUERY];
const REPORT_QUERIES = [
  GET_REPORTS_QUERY,
  GET_AREAS_OF_LEARNING_QUERY,
  GET_REPORT_RECIPIENTS_QUERY,
  GET_REPORTS_FOR_RELATION_QUERY,
];
const ATTENDANCE_QUERIES = [
  GET_ATTENDANCE_REGISTERS_QUERY,
  GET_ATTENDANCE_ENTRIES_FOR_RELATION_QUERY,
  GET_ATTENDANCE_ENTRIES_FOR_PERIOD_QUERY,
  GET_ATTENDANCE_STATS_QUERY,
  GET_ATTENDANCE_CODES_QUERY,
];
const ATTENDANCE_LIST_QUERIES = [GET_ATTENDANCE_REGISTERS_QUERY, GET_ATTENDANCE_STATS_QUERY];
const CONDUCT_QUERIES = [GET_CONDUCT_ENTRIES_QUERY, GET_CONDUCT_STATS_QUERY];
const EVENT_QUERIES = [GET_EVENTS_QUERY, GET_EVENTS_CHART_QUERY];
const SIGNUP_QUERIES = [GET_SIGN_UPS_LIST_QUERY];
const PRODUCT_QUERIES = [
  GET_PRODUCTS_QUERY,
  GET_PRODUCTS_FOR_PARENT_QUERY,
  GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY,
  GET_BILLING_COUNTS_QUERY,
];

const PAYABLE_FEES_QUERIES = [GET_PAYABLE_FEES, GET_PAYABLE_FEES_STATISTICS];

const COMPANY_QUERIES = [GET_COMPANIES_QUERY];
const LEGAL_ENTITY_QUERIES = [GET_LEGAL_ENTITIES_QUERY, GET_BILLING_COUNTS_QUERY];

const PAYMENT_FREQUENCIES_QUERIES = [GET_PAYMENT_FREQUENCIES_QUERY];

const queriesToInvalidate: { [K in RefetchFor]: (string | string[])[] } = {
  parent: [...PARENT_QUERIES, ...STUDENT_QUERIES],
  adult: [...ADULT_QUERIES, ...STAFF_QUERIES],
  child: STUDENT_QUERIES,
  student: [...STUDENT_QUERIES, ...PARENT_QUERIES, ...GROUP_QUERIES, ...ROLLOVER_QUERIES],
  staff: [...STAFF_QUERIES, ...GROUP_QUERIES, ...MESSAGE_QUERIES, ...CONDUCT_QUERIES],
  assessment: [...ASSESSMENT_QUERIES, ...REPORT_QUERIES],
  group: GROUP_QUERIES,
  message: MESSAGE_QUERIES,
  report: REPORT_QUERIES,
  attendance: ATTENDANCE_QUERIES,
  attendanceList: ATTENDANCE_LIST_QUERIES,
  conduct: CONDUCT_QUERIES,
  event: EVENT_QUERIES,
  payableFee: PAYABLE_FEES_QUERIES,
  signup: SIGNUP_QUERIES,
  product: [...PRODUCT_QUERIES, ...STUDENT_PRODUCTS_QUERY, ...PAYABLE_FEES_QUERIES],
  company: COMPANY_QUERIES,
  profile: [],
  annualPlanner: ANNUAL_PLANNER_QUERIES,
  legalEntity: LEGAL_ENTITY_QUERIES,
  frequencies: PAYMENT_FREQUENCIES_QUERIES,
};

export const useInvalidateListQueriesFor = (
  type: RefetchFor,
  filters: InvalidateQueryFilters = { refetchType: 'all' },
) => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    for (const query of queriesToInvalidate[type]) {
      queryClient.invalidateQueries([query], filters);
    }
  }, [filters, queryClient, type]);
};

interface FilterExistingFilterOptionsProps<T> {
  filterOptions: MoreButtonOption<T>[];
  hasDepartments?: boolean;
  hasHouses?: boolean;
  hasSubjects?: boolean;
}

export const filterExistingFilterOptions = <T>({
  filterOptions,
  hasDepartments,
  hasHouses,
  hasSubjects,
}: FilterExistingFilterOptionsProps<T>): MoreButtonOption<T>[] => {
  return filterOptions.filter((filter) => {
    if (filter.value === FilterKeys.Department && !hasDepartments) {
      return false;
    }
    if (
      (filter.value === FilterKeys.House || filter.value === FilterKeys.StudentHouse) &&
      !hasHouses
    ) {
      return false;
    }

    if (filter.value === FilterKeys.Subject && !hasSubjects) {
      return false;
    }
    return true;
  });
};

type GroupMap<T> = Record<string, T[]>;
export interface SelectedItem {
  id: string;
  groupId: string | null;
}
export interface SelectedItemWithGrouping {
  id: string;
  isGroup: boolean;
}

//Creates array of items and grouped items for showing selected tags
export const getSelectedItemsWithGrouping = <T extends { id: string }>(
  selectedItems: SelectedItem[],
  groupMap: GroupMap<T>,
) => {
  let selectedGroupIds: string[] = [];
  const notSelectedGroupIds: string[] = [];

  for (const [k, v] of Object.entries(groupMap)) {
    const matched = v.every(({ id }) => selectedItems.some((item) => item.id === id));
    if (matched) selectedGroupIds.push(k);
    else notSelectedGroupIds.push(k);
  }

  const selectedItemsWithGrouping: SelectedItemWithGrouping[] = [];

  selectedItems.forEach((item) => {
    const groupId = item.groupId;
    //if all items from group selected replace all with one group
    if (groupId && selectedGroupIds.includes(groupId)) {
      selectedItemsWithGrouping.push({ id: groupId, isGroup: true });
      selectedGroupIds = selectedGroupIds.filter((id) => id !== groupId);
      return;
    }
    if (!groupId || notSelectedGroupIds.includes(groupId)) {
      selectedItemsWithGrouping.push({ id: item.id, isGroup: false });
    }
  });

  return selectedItemsWithGrouping;
};

export const toggleMultipleValueArrayProperty = <T>(array: Array<T> | undefined, value: T[]) => {
  if (!array || !array.length) return value;

  const updatedValues = new Set([...(array ?? []), ...value]);
  if (updatedValues.size === array?.length) {
    updatedValues.forEach((v) => value.includes(v) && updatedValues.delete(v));
  }
  return Array.from(updatedValues);
};

// We have system properties that are generated on BE and have no translations
export function getSourcePropertyName(status: SchoolProperty) {
  if (status.source?.type === 're_enrollment') {
    if (status.name === PENDING_PARENT_RESPONSE) return 'status-rollover-PendingParentResponse';
    if (status.name === EXPIRED_RE_ENROLLMENT) return 'status-rollover-ExpiredReEnrollment';
  }
  return status.name;
}

export const filterPropertiesByQuery = (
  query: string,
  properties: Array<SchoolProperty | AgeGroup | LeavingReason>,
) => {
  return properties.filter((p) =>
    getPropertyTypeName(p)?.toLowerCase().includes(query.toLowerCase()),
  );
};

export const getPropertyTypeName = (property?: SchoolProperty | AgeGroup | LeavingReason) => {
  return property && 'name' in property ? property.name : property?.title;
};
