import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import { useGetBillingCountsQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DropdownYears,
  EmptyFolderSvg,
  GridBody,
  MainPageGrid,
  SkeletonRows,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import Header from '../../../components/ui/Header';
import { useFrequencies } from '../../../context/frequencies/WithFrequencies';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { FrequenciesHeader, FrequencyRow } from './SchoolPaymentFrequenciesGrid';

export const PAGE_SIZE = 5;
export const SKELETON_COLUMNS = 6;

export const SchoolPaymentFrequenciesContent: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();

  const { schoolId = '' } = useAuth();
  const { schoolYears, defaultValidity } = useSchoolYears();
  const {
    currentSchoolYear,
    sortedFrequencies,
    canEdit,
    isLoading,
    updateFrequency,
    updatingFrequency,
  } = useFrequencies();

  const { data: countsData } = useGetBillingCountsQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });
  const yearsWithProducts = useMemo(
    () => countsData?.products.filter((p) => p.count > 0).map((p) => p.year_id) ?? [],
    [countsData?.products],
  );

  const yearsForSelect = useMemo(() => {
    return schoolYears.filter(
      (year) => !isDateInPast(year.end) || yearsWithProducts.includes(year.id),
    );
  }, [schoolYears, yearsWithProducts]);

  const emptyStub = (
    <Stack alignItems="center" pt={5.5} pb={2.5}>
      <Box sx={{ mb: 5.5, '& svg': { width: 250, height: 250 } }}>
        <EmptyFolderSvg />
      </Box>

      <Typography textAlign="center" variant="h3" color="text.primary" mb={3}>
        {$t({ id: 'frequencies-NoFrequenciesForThisYear' })}
      </Typography>

      {/* currently user cannot set frequencies */}
      {/* {canEdit && (
        <Button
          startIcon={<PlusIcon />}
          // TODO TR-6100 Add navigation, to be implemented later
          onClick={() => {}}
        >
          {$t({ id: 'frequencies-SetFrequencies' })}
        </Button>
      )} */}
    </Stack>
  );

  const renderContent = () => {
    if (!isLoading && !sortedFrequencies?.length) {
      return emptyStub;
    }

    return (
      <MainPageGrid>
        <FrequenciesHeader />
        <GridBody
          sx={(theme) => ({
            ' .GridRow .MuiTypography-root': {
              ...theme.typography.h3,
            },
          })}
        >
          {isLoading ? (
            <SkeletonRows columnsCount={SKELETON_COLUMNS} amount={PAGE_SIZE} />
          ) : (
            sortedFrequencies?.map((frequency) => (
              <FrequencyRow
                frequency={frequency}
                key={frequency.id}
                canEdit={canEdit}
                onToggleSwitch={updateFrequency}
                updating={updatingFrequency}
              />
            ))
          )}
        </GridBody>
      </MainPageGrid>
    );
  };

  return (
    <>
      <Box sx={{ whiteSpace: 'nowrap' }}>
        <Header pageTitleTextId="section-FrequenciesAndDates" />
      </Box>

      <Card>
        <CardHeader
          title={
            <Typography variant="h2">
              {$t({ id: 'frequencies-AutomaticFrequencyBilling' })}
            </Typography>
          }
          action={
            <DropdownYears
              years={yearsForSelect}
              defaultYear={defaultValidity}
              currentYear={currentSchoolYear}
              onYearChange={({ id }) => navigate(`/settings/frequencies/year/${id}`)}
            />
          }
        />
        <CardContent>{renderContent()}</CardContent>
      </Card>
    </>
  );
};
