import { Box, Button, Skeleton, Stack, Typography } from '@mui/material';
import {
  EmptyListSvg,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  PlusIcon,
  randomInt,
} from '@schooly/style';
import { FC, memo } from 'react';
import { useIntl } from 'react-intl';

interface AssignedProductsListEmptyProps {
  onAdd?: () => void;
  messageId?: string;
}

export const AssignedProductsListEmpty: FC<AssignedProductsListEmptyProps> = ({
  onAdd,
  messageId = 'profile-ThereAreNoProductAssignmentsForStudent',
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack justifyContent="center" alignItems="center" gap={2} py={3}>
      <Box
        sx={{
          ' svg': {
            width: 150,
            height: 150,
          },
        }}
      >
        <EmptyListSvg />
      </Box>
      <Typography textAlign="center" variant="h3" color="text.primary" width={250}>
        {formatMessage({ id: messageId })}
      </Typography>
      {onAdd && (
        <Button startIcon={<PlusIcon />} onClick={onAdd}>
          {formatMessage({ id: 'profile-AddProducts' })}
        </Button>
      )}
    </Stack>
  );
};

export const AssignedProductsListSkeleton: FC = memo(
  () => {
    return (
      <Stack>
        {[...new Array(6)].map((_, i) => {
          const isHeader = i < 1;
          return (
            <GridRowStyled height={isHeader ? 34 : 46} sx={{ pointerEvents: 'none' }}>
              <GridRowItem gap={1} sx={{ padding: 1 }}>
                <GridRowName minWidth={0} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowName>
                <GridRowCell minWidth={200} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={120} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton width={`${randomInt(50, 100)}%`} />
                </GridRowCell>
                <GridRowCell minWidth={60} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={40} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
                <GridRowCell minWidth={60} variant={isHeader ? 'body1' : 'h3'}>
                  <Skeleton />
                </GridRowCell>
              </GridRowItem>
            </GridRowStyled>
          );
        })}
      </Stack>
    );
  },
  () => true,
);
