import { AssignedProductSave, PayerType, Product, YearlyProductAssignments } from '@schooly/api';
import Decimal from 'decimal.js';

import { AssignedProductUpdate } from './StudentProductsModalContent';

export const getDiscountedPrice = (price: number, discountPercent: number) => {
  const decimalPrice = new Decimal(price);
  const decimalDiscountPercent = new Decimal(discountPercent);

  const discountAmount = decimalPrice.mul(decimalDiscountPercent).div(100);
  const discountedPrice = decimalPrice.minus(discountAmount);

  return discountedPrice.toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
};

export const convertProductToAssignedProductUpdate = (product: Product): AssignedProductUpdate => {
  const type = product.types[0];
  const variant = type.variants[0];
  const frequencyId = variant.prices[0].frequency_id;

  return {
    id: product.id,
    payer_type: PayerType.Default,
    obligatory: product.obligatory,
    product_type: product.type,
    name: product.name,
    isExistingAssignment: false,
    variant: {
      id: variant.id,
      price: variant.prices[0].price,
      type_name: type.name,
      currency: type.billing_connection.legal_entity_currency,
      frequency_id: frequencyId,
    },
    available_variants: product.types
      .map((type) =>
        type.variants.map((variant) => ({
          type_id: type.id,
          type_name: type.name,
          id: variant.id,
          half_day: variant.half_day,
          prices: variant.prices,
          currency: type.billing_connection.legal_entity_currency,
        })),
      )
      .flat(),
  };
};

export const convertFormDataToAssignedProductSave = (
  productAssignment: AssignedProductUpdate,
): AssignedProductSave => {
  const { id, variant, payer_type, discount_percent } = productAssignment;

  return {
    id,
    variant: {
      type_name: variant.type_name,
      id: variant.id,
      frequency_id: variant.frequency_id,
    },
    payer_type,
    discount_percent: discount_percent || 0,
  };
};

export const convertProductAssignmentsToForm = (
  productAssignments: YearlyProductAssignments,
  overrideCompanyId?: string,
) => {
  const products = productAssignments.products.map((p) => ({
    ...p,
    discount_percent: p.discount_percent || undefined,
    isExistingAssignment: true,
  }));

  return {
    yearId: productAssignments.year_id,
    defaultPayerId: productAssignments.default_payer.relation_id,
    companyPayerId: overrideCompanyId ?? productAssignments.company_payer?.id,
    products,
  };
};
