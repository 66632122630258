import { Button, IconButton, Stack, Switch, Typography, useTheme } from '@mui/material';
import {
  PaymentFrequency,
  PaymentFrequencyType,
  Product,
  ProductBillingType,
  ProductForm,
  ProductFormType,
  ProductSaveVariant,
  ProductTriggerType,
  SchoolYear,
  useCheckProductNameUniqueMutation,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { usePrevious } from '@schooly/hooks/use-previous';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderInput,
  ModalLarge,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../../../hooks/useSchoolYears';
import {
  convertProductTypeToForm,
  copyProductTypes,
  getCurrentProductTypes,
  getProductTypeYearId,
  getSortedFrequencies,
  useSchoolYearsInProduct,
} from '../helpers';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import {
  ProductSubscriptionExampleContainer,
  ProductSubscriptionExampleHeader,
} from './ProductSubscriptionSection/ProductSubscriptionExample';
import { getExampleHeaderColor } from './ProductSubscriptionSection/ProductSubscriptionOption';
import { ProductSubscriptionTypeSelect } from './ProductSubscriptionSection/ProductSubscriptionTypeSelect';
import { RadioGroupCard } from './ProductSubscriptionSection/RadioGroupCard';
import { ProductTriggerSection } from './ProductTriggerSection';
import { NameInput, SchoolProductCreateModalVariants } from './SchoolProductCreateModalVariants';

type SchoolProductCreateModalContentProps = {
  initialState?: ViewState;
  product?: Product;
  schoolId: string;
  onSave: (v: ProductForm, productFrequencies: PaymentFrequency[]) => void;
  onDelete?: () => void;
  onClose: () => void;
  isSaving: boolean;
  isDeleting: boolean;
};

type IntersectionId =
  | {
      ageGroupId: IntersectsAll;
      subjectId: string;
    }
  | { ageGroupId: string; subjectId: IntersectsAll }
  | {
      ageGroupId: string;
      subjectId: string;
    };

export type IntersectionIds = {
  halfDayIds: IntersectionId[];
  fullDayIds: IntersectionId[];
};

export enum ViewState {
  General = 'general',
  Variants = 'variants',
}

const defaultRegistrationTrigger = {
  trigger_type: ProductTriggerType.RegistrationUpdate,
  extra_data: { status: '' },
};

const defaultSingleInvoiceTrigger = { trigger_type: ProductTriggerType.SingleInvoice };

export const getDefaultVariant = (): ProductForm['types'][0]['variants'][0] => ({
  half_day: false,
  age_groups: [],
  subjects: [],
  prices: [],
});

export const getDefaultType = (yearId: string): ProductForm['types'][0] => ({
  name: '',
  variants: [getDefaultVariant()],
  billing_connection: {
    account_id: '',
    legal_entity_id: '',
  },
  year_id: yearId,
});

const getDefaultValues = (yearId: string): ProductForm => {
  return {
    name: '',
    type: ProductBillingType.Recurring,
    description: '',
    obligatory: true,
    unique_types: true,
    assignment: { one_type: false },
    triggers: [defaultRegistrationTrigger],
    types: [getDefaultType(yearId)],
  };
};

export const SchoolProductCreateModalContent: FC<SchoolProductCreateModalContentProps> = ({
  initialState = ViewState.General,
  product,
  schoolId,
  onClose,
  onSave,
  onDelete,
  isDeleting,
  isSaving,
}) => {
  const { formatMessage } = useIntl();
  const [state, setState] = useState<ViewState>(initialState);
  const checkProductNameUnique = useCheckProductNameUniqueMutation();
  const { showError } = useNotifications();
  const { propertiesMap, getPropertyById } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Student,
  });
  const { getConfirmation } = useConfirmationDialog();
  const theme = useTheme();

  const { schoolYears } = useSchoolYears();
  const { permissions } = useAuth();

  const shouldIgnoreSchoolYears = Boolean(product && product?.type === ProductBillingType.OneOff);

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) => Boolean(year && !isDateInPast(year.end)),
    [],
  );
  const {
    selectedYear,
    setSelectedYear,
    defaultYear,
    yearsForSelect,
    setCopyFromYear,
    copyFromYear,
  } = useSchoolYearsInProduct(canShowYearInSelect, shouldIgnoreSchoolYears);

  const selectedYearId = selectedYear?.id ?? '';
  const prevSelectedYearId = usePrevious(selectedYearId);
  const copyFromYearId = copyFromYear?.id ?? '';

  const { data: frequenciesData, isLoading: isFrequenciesLoading } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId, year_id: selectedYearId },
    { enabled: !!selectedYearId },
  );
  const { data: copyFromFrequenciesData } = useGetSchoolPaymentFrequencies(
    { school_id: schoolId, year_id: copyFromYearId },
    { enabled: !!copyFromYearId },
  );

  const { sortedFrequencies, frequencies } = useMemo(() => {
    const frequencies = frequenciesData?.frequencies ?? [];

    return {
      frequencies,
      sortedFrequencies: getSortedFrequencies(frequencies).filter((f) => !!f.in_use),
    };
  }, [frequenciesData?.frequencies]);

  const existingTypes = useMemo(() => {
    if (!product?.types.length) return [];

    return getCurrentProductTypes({
      types: product.types,
      schoolYears,
      yearId: selectedYearId,
      productType: product.type,
    });
  }, [product?.types, product?.type, schoolYears, selectedYearId]);

  const defaultValues = getDefaultValues(selectedYearId);
  const getDefaultTypes = () => {
    if (!product?.types.length) return defaultValues.types;

    if (product.type === ProductBillingType.OneOff) {
      return copyProductTypes({
        types: existingTypes.map((t) => convertProductTypeToForm(t)),
        copyFromFrequencies: frequenciesData?.frequencies ?? [],
        copyToFrequencies: frequenciesData?.frequencies ?? [],
        filterAgeGroups: (id) => {
          const ageGroup = getPropertyById(id);
          return !!ageGroup && !ageGroup.archived;
        },
      });
    }

    const types = product.types.map((t) =>
      convertProductTypeToForm(t, getProductTypeYearId(t, schoolYears)),
    );

    const isTypesEdit = !!existingTypes.length;
    if (isTypesEdit) return types;

    const copyFromTypes = copyFromYearId
      ? getCurrentProductTypes({
          types: product.types,
          schoolYears,
          yearId: copyFromYearId,
          productType: product.type,
        })
      : [];
    const newTypes = copyFromTypes.length
      ? copyProductTypes({
          types: copyFromTypes.map((t) => convertProductTypeToForm(t)),
          copyFromFrequencies: copyFromFrequenciesData?.frequencies ?? [],
          copyToFrequencies: frequenciesData?.frequencies ?? [],
          yearId: selectedYearId,
          copyPrices: false,
          filterAgeGroups: (id) => {
            const ageGroup = getPropertyById(id);
            return !!ageGroup && !ageGroup.archived;
          },
        })
      : defaultValues.types;

    return [...types, ...newTypes];
  };

  const form = useForm<ProductForm>({
    defaultValues: product
      ? {
          name: product.name,
          description: product.description,
          obligatory: product.obligatory,
          triggers: product.triggers,
          unique_types: product.unique_types,
          assignment: { one_type: product.assignment.one_type },
          types: getDefaultTypes(),
          type: product.type,
        }
      : { ...defaultValues, types: getDefaultTypes() },
  });

  const name = form.watch('name');
  const obligatory = form.watch('obligatory');
  const isUniqueTypes = form.watch('unique_types');
  const oneTypeAssignment = form.watch('assignment.one_type');
  const triggers = form.watch('triggers');

  const type = form.watch('type');
  const isRecurring = type === ProductBillingType.Recurring;

  const allTypes = form.watch('types');
  const types = useMemo(
    () =>
      isRecurring
        ? getCurrentProductTypes({
            types: allTypes,
            schoolYears,
            yearId: selectedYearId,
            productType: type,
          })
        : allTypes,
    [allTypes, isRecurring, schoolYears, selectedYearId, type],
  );

  const [uniqueTypesError, setUniqueTypesError] = useState(false);

  const registrationTrigger = triggers.find(
    (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
  );
  const statusName = registrationTrigger?.extra_data?.status
    ? propertiesMap.status.find((s) => s.id === registrationTrigger?.extra_data?.status)?.name
    : undefined;

  const canViewFrequency =
    permissions.includes('product_and_invoice_viewer') ||
    permissions.includes('registration_manager');

  //TR-6684 Editing of product is temporary blocked
  const isEdit = !!product?.id;
  const canEditProductSettings = !isEdit;
  const canEditVariants = !isEdit || !existingTypes.length;

  const [typesIntersectionIds, setTypesIntersectionIds] = useState<IntersectionIds[]>([]);

  const findTypesApplicableIntersections = useCallback(() => {
    setTypesIntersectionIds(
      types.map((type) =>
        isUniqueTypes ? findProductTypesDuplicates(types) : findProductTypesDuplicates([type]),
      ),
    );
  }, [isUniqueTypes, types]);

  const validateIntersections = useCallback(() => {
    if (
      typesIntersectionIds.some((int) => {
        const { fullDayIds, halfDayIds } = int;

        return !!fullDayIds.length || halfDayIds.length;
      })
    ) {
      if (isUniqueTypes) {
        return formatMessage({
          id: 'products-ApplicableStudentsMustBeUniqueWithinAllProductTypes',
        });
      } else if (!isRecurring) {
        return formatMessage({
          id: 'products-ApplicableStudentsMustBeUniqueWithinOneProductTypeOneOff',
        });
      }

      return formatMessage({ id: 'products-ApplicableStudentsMustBeUniqueWithinOneProductType' });
    }
  }, [typesIntersectionIds, isUniqueTypes, isRecurring, formatMessage]);

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    onClose();
  }, [onClose, form.formState.isDirty, getConfirmation]);

  const resetProductSubscriptionFields = useCallback(() => {
    if (!canEditProductSettings) return;
    !isUniqueTypes && form.setValue('unique_types', defaultValues.unique_types);
    oneTypeAssignment && form.setValue('assignment.one_type', defaultValues.assignment.one_type);
  }, [
    canEditProductSettings,
    defaultValues.assignment.one_type,
    defaultValues.unique_types,
    form,
    isUniqueTypes,
    oneTypeAssignment,
  ]);

  const handleUniqueTypeChange = useCallback(
    (value: 'unique_types' | 'many_types') => {
      if (value === 'many_types') {
        form.setValue('unique_types', false);
      } else {
        form.setValue('unique_types', true);
      }
    },
    [form],
  );

  useEffect(() => {
    if (types.length === 1) {
      //resets subscription fields for single type product
      resetProductSubscriptionFields();
    }
  }, [resetProductSubscriptionFields, types.length]);

  useEffect(() => {
    const errorMessage = validateIntersections();

    if (errorMessage) {
      setUniqueTypesError(true);
    } else {
      setUniqueTypesError(false);
    }
  }, [validateIntersections]);

  useEffect(() => {
    const subscription = form.watch((_, { name }) => {
      if (name === 'types' || name === 'unique_types') {
        findTypesApplicableIntersections();
      }
    });

    return () => subscription.unsubscribe();
  }, [findTypesApplicableIntersections, form]);

  const hasActiveFrequencies = isRecurring
    ? sortedFrequencies.some((f) => f.type !== PaymentFrequencyType.OneOff)
    : sortedFrequencies.some((f) => f.type === PaymentFrequencyType.OneOff);

  const canSave = !!types.length && hasActiveFrequencies;

  useEffect(() => {
    if (isEdit) return;
    if (selectedYearId === prevSelectedYearId || !hasActiveFrequencies) return;

    //on create on year switch types should change to new year
    const shouldChangeTypes = allTypes.some((t) => t.year_id !== selectedYearId);

    if (shouldChangeTypes) {
      form.setValue(
        'types',
        copyProductTypes({
          types: allTypes,
          copyFromFrequencies: copyFromFrequenciesData?.frequencies ?? [],
          copyToFrequencies: frequenciesData?.frequencies ?? [],
          yearId: selectedYearId,
          filterAgeGroups: (id) => {
            const ageGroup = getPropertyById(id);
            return !!ageGroup && !ageGroup.archived;
          },
        }),
      );
    }
  }, [
    allTypes,
    copyFromFrequenciesData?.frequencies,
    form,
    frequenciesData?.frequencies,
    getPropertyById,
    hasActiveFrequencies,
    isEdit,
    prevSelectedYearId,
    selectedYearId,
    types,
  ]);

  const handleTypeChange = useCallback(
    (field: ControllerRenderProps<ProductForm, 'type'>) => {
      const isRecurringSelected = field.value === ProductBillingType.OneOff;

      if (isRecurringSelected) {
        //removes single invoice trigger
        const filteredTriggers = triggers.filter(
          (t) => t.trigger_type === ProductTriggerType.RegistrationUpdate,
        );
        form.setValue(
          'triggers',
          filteredTriggers.length ? filteredTriggers : [defaultRegistrationTrigger],
        );
      } else {
        //resets subscription fields
        resetProductSubscriptionFields();

        //adds single invoice trigger
        form.setValue(
          'triggers',
          obligatory
            ? [defaultRegistrationTrigger, defaultSingleInvoiceTrigger]
            : [defaultSingleInvoiceTrigger],
        );
      }

      //resets frequencies
      form.setValue(
        'types',
        copyProductTypes({
          types: isEdit ? types : allTypes,
          copyFromFrequencies: [],
          copyToFrequencies: [],
          yearId: isRecurringSelected ? selectedYearId : undefined,
          filterAgeGroups: (id) => {
            const ageGroup = getPropertyById(id);
            return !!ageGroup && !ageGroup.archived;
          },
        }),
      );

      field.onChange(
        isRecurringSelected ? ProductBillingType.Recurring : ProductBillingType.OneOff,
      );
    },
    [
      allTypes,
      form,
      getPropertyById,
      isEdit,
      obligatory,
      resetProductSubscriptionFields,
      selectedYearId,
      triggers,
      types,
    ],
  );

  const handleObligatoryChange = useCallback(
    (field: ControllerRenderProps<ProductForm, 'obligatory'>, required: boolean) => {
      if (type === ProductBillingType.OneOff) {
        //adds single invoice trigger for one-off product
        form.setValue(
          'triggers',
          required
            ? [defaultRegistrationTrigger, defaultSingleInvoiceTrigger]
            : [defaultSingleInvoiceTrigger],
        );
      }

      if (!required) {
        field.onChange(false);
        return;
      }

      field.onChange(true);
    },
    [form, type],
  );

  const handleSubmit = useCallback(
    async (v: ProductForm) => {
      switch (state) {
        case ViewState.General: {
          const nameChanged = !product?.id || product.name !== v.name;

          if (!nameChanged) {
            form.clearErrors();
            setState(ViewState.Variants);
            return;
          }

          checkProductNameUnique.mutate(
            {
              schoolId,
              name: v.name,
            },
            {
              onSuccess: ({ is_unique }) => {
                if (!is_unique) {
                  form.setError('name', {
                    type: 'validate',
                    message: formatMessage({ id: 'products-ProductNameExists' }, { name: v.name }),
                  });
                  form.setFocus('name');
                  return;
                }

                form.clearErrors();
                setState(ViewState.Variants);
              },
              onError: showError,
            },
          );

          return;
        }

        case ViewState.Variants: {
          const errorMessage = validateIntersections();
          if (errorMessage) {
            setUniqueTypesError(true);

            showError({
              message: errorMessage,
            });
            return;
          } else {
            setUniqueTypesError(false);
          }

          onSave(v, sortedFrequencies);
          return;
        }

        default:
          return;
      }
    },
    [
      formatMessage,
      checkProductNameUnique,
      sortedFrequencies,
      form,
      onSave,
      product?.id,
      product?.name,
      schoolId,
      showError,
      state,
      validateIntersections,
    ],
  );

  const handleError = useCallback(() => {
    const errorMessage = validateIntersections();

    if (errorMessage) {
      setUniqueTypesError(true);

      showError({
        message: errorMessage,
      });
    } else {
      setUniqueTypesError(false);
    }
  }, [showError, validateIntersections]);

  if (!frequencies)
    return (
      <ModalSmall open onClose={handleClose}>
        <Loading />
      </ModalSmall>
    );

  const actionsDisabled = isDeleting || checkProductNameUnique.isLoading || isSaving;

  const deleteButton = !!onDelete && (
    <Button
      variant="outlined"
      startIcon={isDeleting ? <Spin /> : <DeleteIcon />}
      disabled={actionsDisabled}
      onClick={onDelete}
    >
      {formatMessage({ id: 'action-Delete' })}
    </Button>
  );

  if (state === ViewState.General)
    return (
      <ModalSmall open onClose={handleClose}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <ModalHeader
              active
              title={
                <Controller
                  control={form.control}
                  name="name"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => {
                    return (
                      <ModalHeaderInput
                        placeholder={formatMessage({ id: 'products-ProductName' })}
                        autoFocus={!field.value}
                        error={!!fieldState.error}
                        helperText={getControllerErrorText(
                          fieldState.error,
                          undefined,
                          formatMessage,
                        )}
                        {...field}
                      />
                    );
                  }}
                />
              }
            >
              <IconButton onClick={handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeader>
            <ModalContent active>
              <Stack gap={4}>
                <Stack gap={1}>
                  <Stack flexDirection="row" alignItems="center" gap={1}>
                    <Typography variant="h2">
                      {formatMessage({ id: 'products-IsThisProductRecurring' })}
                    </Typography>
                  </Stack>

                  <Stack direction="row" alignItems="stretch" gap={1}>
                    <Controller
                      control={form.control}
                      name="type"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value === ProductBillingType.Recurring}
                            onChange={() => handleTypeChange(field)}
                            labelTextId="products-ProductType-Recurring"
                            disabled={false}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-RecurringProductsAreInvoiced' })}
                            </Typography>

                            <Typography mb={1}>
                              {formatMessage({ id: 'products-SubscriptionExample-ForExample' })}
                            </Typography>

                            <ProductSubscriptionExampleContainer>
                              <Stack gap={1}>
                                <ProductSubscriptionExampleHeader
                                  textId="products-SubscriptionExample-Tuition"
                                  color={getExampleHeaderColor(
                                    theme,
                                    field.value === ProductBillingType.Recurring,
                                    !canEditProductSettings,
                                  )}
                                />
                              </Stack>
                            </ProductSubscriptionExampleContainer>
                          </RadioGroupCard>
                        );
                      }}
                    />

                    <Controller
                      control={form.control}
                      name="type"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value === ProductBillingType.OneOff}
                            onChange={() => handleTypeChange(field)}
                            labelTextId="products-ProductType-OneOff"
                            disabled={false}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-OneOffProductsAreInvoiced' })}
                            </Typography>

                            <Typography mb={1}>
                              {formatMessage({ id: 'products-SubscriptionExample-ForExample' })}
                            </Typography>

                            <ProductSubscriptionExampleContainer>
                              <Stack gap={1}>
                                <ProductSubscriptionExampleHeader
                                  textId="products-SubscriptionExample-ApplicationFee"
                                  color={getExampleHeaderColor(
                                    theme,
                                    field.value === ProductBillingType.OneOff,
                                    !canEditProductSettings,
                                  )}
                                />
                              </Stack>
                            </ProductSubscriptionExampleContainer>
                          </RadioGroupCard>
                        );
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack flexDirection="column" gap={1} justifyContent="space-between">
                  <Typography variant="h2">
                    {formatMessage({ id: 'products-IsThisProductRequired?' })}
                  </Typography>

                  <Stack direction="row" alignItems="stretch" gap={1}>
                    <Controller
                      control={form.control}
                      name="obligatory"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={field.value}
                            onChange={() => handleObligatoryChange(field, true)}
                            labelTextId="products-Product-Required"
                            disabled={!canEditProductSettings}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-RequiredProductsAreIssued' })}
                            </Typography>
                          </RadioGroupCard>
                        );
                      }}
                    />

                    <Controller
                      control={form.control}
                      name="obligatory"
                      render={({ field }) => {
                        return (
                          <RadioGroupCard
                            checked={!field.value}
                            onChange={() => handleObligatoryChange(field, false)}
                            labelTextId="products-Product-Optional"
                            disabled={!canEditProductSettings}
                            name="type"
                          >
                            <Typography mb={1}>
                              {formatMessage({ id: 'products-OptionalProductsAreIssued' })}
                            </Typography>
                          </RadioGroupCard>
                        );
                      }}
                    />
                  </Stack>
                </Stack>

                <ProductTriggerSection schoolId={schoolId} canEdit={canEditProductSettings} />
              </Stack>
            </ModalContent>
            <ModalFooter active sx={{ justifyContent: onDelete ? 'space-between' : undefined }}>
              {deleteButton}
              <Button
                endIcon={checkProductNameUnique.isLoading ? <Spin /> : <ArrowRightIcon />}
                disabled={actionsDisabled}
                type="submit"
              >
                {formatMessage({ id: 'action-Next' })}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalSmall>
    );

  return (
    <ModalLarge open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit, handleError)}>
          <SchoolProductModalHeader
            name={name}
            obligatory={obligatory}
            statusName={statusName}
            onClose={handleClose}
            triggerTextId={
              registrationTrigger ? 'products-Trigger-Status' : 'products-Trigger-SingleInvoice'
            }
            type={type}
            renderProductName={() => (
              <Controller
                control={form.control}
                name="name"
                rules={{ required: true }}
                render={({ field, fieldState }) => {
                  return (
                    <NameInput
                      placeholder={formatMessage({ id: 'products-ProductName' })}
                      autoFocus={!field.value}
                      error={fieldState.error}
                      {...field}
                    />
                  );
                }}
              />
            )}
          />

          <ModalContent display="flex" flexDirection="column">
            <SchoolProductCreateModalVariants
              findIntersections={findTypesApplicableIntersections}
              typesIntersectionIds={typesIntersectionIds}
              frequencies={frequencies}
              sortedFrequencies={sortedFrequencies}
              form={form}
              schoolId={schoolId}
              selectedYear={selectedYear}
              setSelectedYear={(year) => {
                if (hasActiveFrequencies) {
                  setCopyFromYear(selectedYear);
                }
                setSelectedYear(year);
              }}
              yearsForSelect={yearsForSelect}
              defaultYear={defaultYear}
              canViewFrequency={canViewFrequency}
              canEdit={canEditVariants}
              isLoading={isFrequenciesLoading}
              isEdit={isEdit}
            >
              {types.length > 1 || !isRecurring ? (
                <ProductSubscriptionTypeSelect
                  form={form}
                  onSelect={handleUniqueTypeChange}
                  opened={false}
                  error={uniqueTypesError}
                  canEdit={canEditProductSettings}
                />
              ) : (
                <div />
              )}
            </SchoolProductCreateModalVariants>
          </ModalContent>
          <ModalFooter
            active
            sx={{ justifyContent: product && !onDelete ? undefined : 'space-between' }}
          >
            {product ? (
              deleteButton
            ) : (
              <Button
                variant="outlined"
                startIcon={<ArrowLeftIcon />}
                disabled={isSaving}
                onClick={() => setState(ViewState.General)}
              >
                {formatMessage({ id: 'action-Back' })}
              </Button>
            )}
            {canSave && (
              <Button
                startIcon={isSaving ? <Spin /> : <CheckIcon />}
                disabled={isSaving}
                type="submit"
              >
                {formatMessage({ id: 'action-Save' })}
              </Button>
            )}
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalLarge>
  );
};

export type IntersectsAll = 'All';
export const INTERSECTS_ALL: IntersectsAll = 'All';

const findProductTypesDuplicates = (v: ProductFormType[]): IntersectionIds => {
  const fullDayVariants = v.map((v) => v.variants.filter((v) => !v.half_day)).flat();
  const halfDayVariants = v.map((v) => v.variants.filter((v) => v.half_day)).flat();

  const getNonUniqueIntersections = (v: ProductSaveVariant[]) => {
    const allCombinations: IntersectionId[] = [];

    for (const variant of v) {
      if (!variant.age_groups.length && !variant.subjects.length) continue;

      const variantAgeGroups = variant.age_groups.length ? variant.age_groups : [INTERSECTS_ALL];

      for (const ageGroupId of variantAgeGroups) {
        const variantSubjects = variant.subjects.length ? variant.subjects : [INTERSECTS_ALL];

        for (const subjectId of variantSubjects) {
          allCombinations.push({
            ageGroupId,
            subjectId,
          });
        }
      }
    }

    return allCombinations.reduce<IntersectionId[]>((acc, comb) => {
      if (
        allCombinations.filter(
          (exComb) => exComb.ageGroupId === comb.ageGroupId && exComb.subjectId === comb.subjectId,
        ).length > 1 ||
        (comb.ageGroupId === INTERSECTS_ALL &&
          allCombinations.filter((exComb) => exComb.subjectId === comb.subjectId).length > 1) ||
        (comb.subjectId === INTERSECTS_ALL &&
          allCombinations.filter(
            (exComb) =>
              exComb.ageGroupId === comb.ageGroupId || exComb.ageGroupId === INTERSECTS_ALL,
          ).length > 1)
      )
        return [...acc, comb];
      return acc;
    }, []);
  };

  return {
    halfDayIds: getNonUniqueIntersections(halfDayVariants),
    fullDayIds: getNonUniqueIntersections(fullDayVariants),
  };
};

type ProductCreateToggleProps = {
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  labelTextIds: [string, string];
};

export const ProductCreateToggle = ({
  disabled,
  onChange,
  labelTextIds,
  checked,
}: ProductCreateToggleProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      flexDirection="row"
      gap={1}
      sx={{
        '& .MuiTypography-root': {
          color: disabled ? 'common.light2' : 'common.grey',
          '&.selectedOption': {
            color: disabled ? 'common.light2' : 'primary.main',
          },
        },
      }}
    >
      <Typography variant="h3" className={!checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[0] })}
      </Typography>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        sx={{
          '& .MuiSwitch-thumb': { bgcolor: 'primary.main' },
          '&.MuiSwitch-root .Mui-disabled': {
            '+.MuiSwitch-track': {
              opacity: 1,
              borderColor: 'common.light2',
            },
            '.MuiSwitch-thumb': {
              bgcolor: 'common.light2',
            },
          },
        }}
      />
      <Typography variant="h3" className={checked ? 'selectedOption' : undefined}>
        {formatMessage({ id: labelTextIds[1] })}
      </Typography>
    </Stack>
  );
};
