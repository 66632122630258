import { Icon, IconButton, Skeleton, Stack, StackProps, styled, Typography } from '@mui/material';
import { AssignedProductSave, Product, ProductType, useGetProductsListQuery } from '@schooly/api';
import { PageHeaderSearchInput } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  DropdownIcon,
  ModalHeader,
  ModalPanel,
  PlusIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AssignedProductUpdate } from './StudentProductsModalContent';

export const PAGE_SIZE = 10;

type StudentProductsModalSidebarFeesProps = {
  selectedProducts: AssignedProductUpdate[];
  onSelectProduct: (v: { product: Product; type: ProductType }) => void;
  productsQueryState: ReturnType<typeof useGetProductsListQuery>;
};

export const StudentProductsModalSidebarFees: FC<StudentProductsModalSidebarFeesProps> = ({
  selectedProducts,
  onSelectProduct,
  productsQueryState,
}) => {
  const { $t } = useIntl();

  const { isLoading, data, params, isFetchingNextPage, fetchNextPage, hasNextPage, setParams } =
    productsQueryState;

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const productsWithFilteredTypes = useMemo(() => {
    return (
      data?.pages.reduce<{ product: Product; types: ProductType[] }[]>((prev, curr) => {
        const results = curr.results
          .map((product) => {
            const selectedProductTypes = selectedProducts
              .filter((p) => p.id === product.id)
              .map((p) => p.variant.type_name);

            return {
              product,
              types: product.types.filter((t) => !selectedProductTypes.includes(t.name)),
            };
          })
          .filter((p) => p.types.length);

        return [...prev, ...results];
      }, []) ?? []
    );
  }, [data?.pages, selectedProducts]);

  const total = data?.pages[0].count;
  const hasNoMatchingProducts = !total && !params.query && !isLoading;

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const renderList = () => {
    return (
      <>
        {!isLoading && !productsWithFilteredTypes?.length && (
          <>
            <Stack flex={1} gap={1} height="100%" justifyContent="center" alignItems="center" p={2}>
              <picture>
                <source srcSet="/images/no-search-results.png, /images/no-search-results@2x.png 2x" />
                <img
                  className="LandingLayout__image"
                  src="/images/no-search-results.png"
                  alt="No results matching your search found"
                />
              </picture>

              {hasNoMatchingProducts ? (
                <>
                  <Typography textAlign="center">
                    {$t({ id: 'profile-NoMatchingProductAssignments' })}
                  </Typography>

                  <Typography mt={2} textAlign="center">
                    {$t({ id: 'profile-NoMatchingProductAssignments-Description' })}
                  </Typography>
                </>
              ) : (
                <Typography textAlign="center">
                  {$t({ id: params.query ? 'noSearchResults' : 'profile-NoProductAssignments' })}
                </Typography>
              )}
            </Stack>
          </>
        )}
        <Stack gap={2}>
          {productsWithFilteredTypes?.map((p) => {
            return (
              <ProductExpandable
                key={p.product.id}
                product={p.product}
                types={p.types}
                selectedProducts={selectedProducts}
                onSelectType={onSelectProduct}
              />
            );
          })}
        </Stack>
        {isLoading && <SkeletonRowsComponent gap={2} rowCount={PAGE_SIZE} />}

        {hasNextPage && (
          <>
            <div ref={loaderRef} />
            <SkeletonRowsComponent
              rowCount={Math.min(
                PAGE_SIZE,
                total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Stack sx={{ height: '100%' }}>
        <ModalHeader title={$t({ id: 'profile-RecurringProducts' })} active />
        <ModalPanel active flat sx={(theme) => ({ padding: theme.spacing(2.5, 2.5, 1) })}>
          {!hasNoMatchingProducts && (
            <PageHeaderSearchInput
              sx={{
                '.MuiInputAdornment-root': {
                  marginTop: `0 !important`,
                },
              }}
              value={params.query ?? ''}
              onChangeText={handleSetFiltersQuery}
              placeholder={$t({ id: 'searchPlaceholder' })}
            />
          )}
        </ModalPanel>
        <ModalPanel sx={{ pt: 0, flex: '1 1 auto', overflowY: 'auto' }} active>
          {renderList()}
        </ModalPanel>
      </Stack>
    </>
  );
};

type ProductExpandableProps = {
  product: Product;
  types: ProductType[];
  selectedProducts: AssignedProductSave[];
  onSelectType: (p: { product: Product; type: ProductType }) => void;
};

const ProductExpandable: FC<ProductExpandableProps> = ({ product, types, onSelectType }) => {
  const [expanded, expand, collapse] = useFlag(true);

  if (!types.length) return null;

  if (types.length === 1 && !types[0].name) {
    return (
      <Stack
        sx={(theme) => ({
          gap: theme.spacing(1.25),
        })}
      >
        <ProductTypeStyled onClick={() => onSelectType({ product, type: product.types[0] })}>
          <TypographyWithOverflowHint variant="h3" color="text.primary">
            {product.name}
          </TypographyWithOverflowHint>
          <Stack className="plus-icon">
            <Icon sx={{ fontSize: 16 }}>
              <PlusIcon />
            </Icon>
          </Stack>
        </ProductTypeStyled>
      </Stack>
    );
  }

  return (
    <Stack
      sx={(theme) => ({
        gap: theme.spacing(1.25),
        borderRadius: theme.spacing(1),
        border: `1px solid ${theme.palette.divider}`,
        padding: theme.spacing(1.5),
      })}
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography color="text.primary" variant="h3">
          {product.name}
        </Typography>
        <IconButton
          inverse
          sx={{
            transform: expanded ? 'rotate(180deg)' : undefined,
          }}
          onClick={expanded ? collapse : expand}
        >
          <DropdownIcon />
        </IconButton>
      </Stack>
      <Stack gap={1}>
        {expanded &&
          types.map((t) => (
            <ProductTypeStyled key={t.name} onClick={() => onSelectType({ product, type: t })}>
              <TypographyWithOverflowHint variant="h3" color="text.primary">
                {t.name}
              </TypographyWithOverflowHint>
              <Stack className="plus-icon">
                <Icon sx={{ fontSize: 16 }}>
                  <PlusIcon />
                </Icon>
              </Stack>
            </ProductTypeStyled>
          ))}
      </Stack>
    </Stack>
  );
};

type SkeletonRowsComponentProps = {
  rowCount: number;
} & StackProps;

const SkeletonRowsComponent: FC<SkeletonRowsComponentProps> = ({ rowCount, ...props }) => {
  if (!rowCount) return null;

  return (
    <Stack {...props}>
      {[...new Array(rowCount)].map((_, i) => (
        <Skeleton variant="rounded" sx={(theme) => ({ height: theme.spacing(8) })} key={i} />
      ))}
    </Stack>
  );
};

const ProductTypeStyled = styled(Stack)<{}>(({ theme }) => ({
  cursor: 'pointer',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 1, 1, 1.5),
  '.plus-icon': {
    transition: 'all .2s',
    width: theme.spacing(3),
    height: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    '.plus-icon': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}));
