import { Currencies, SchoolInviteStatus } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { EnrollmentBase } from './apiTypes/applications';
import { Company } from './apiTypes/companies';
import { IColumnSort } from './apiTypes/endpoints/people';
import { ApiError, PagedResponse, SORT_DIRECTION, StringOrNull } from './apiTypes/misc';
import { WithAvatar, WithName } from './apiTypes/partials';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { SchoolRelation } from './apiTypes/relations';
import { SchoolYear } from './apiTypes/schools';
import { FilterKeys, Guardian } from './apiTypes/users';
import { PaymentFrequencyType } from './frequencies';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

type AccountType = 'internal' | 'xero';

type Account<T extends AccountType> = {
  id: string;
  name: string;
  type: T;
};

export type InternalAccount = Account<'internal'>;

export type XeroAccount = {
  xero_tenant_name: string;
  xero_tenant_id: string;
} & Account<'xero'>;

export type XeroAccountInProduct = Omit<XeroAccount, 'xero_tenant_name' | 'xero_tenant_id'>;

export enum ProductTriggerType {
  RegistrationUpdate = 'registration-update',
  SingleInvoice = 'single-invoice',
}

export type ProductVariantPrice = {
  price: number;
  frequency_id: string;
};

export type ProductVariant = {
  id: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

export type ProductType = {
  id: string;
  name: string;
  variants: ProductVariant[];
  active_from: string;
  active_to: string;
  created_at: number;
  billing_connection: {
    legal_entity_account: InternalAccount | XeroAccountInProduct;
    legal_entity_id: string;
    legal_entity_display_name: string;
    legal_entity_currency: Currencies;
  };
};

export enum ProductBillingType {
  Recurring = 'recurring',
  OneOff = 'one_off',
}

export type ProductTrigger = {
  id: string;
  trigger_type: ProductTriggerType;
  extra_data?: { status: string };
};

export type Product = {
  id: string;
  type: ProductBillingType;
  school_id: string;
  name: string;
  description: string;
  obligatory: boolean;
  //TODO: This field is not used, can be removed
  single_type: boolean;
  unique_types: boolean;
  assignment: {
    one_type: boolean;
  };
  triggers: ProductTrigger[];
  types: ProductType[];
};

export type ProductSaveVariant = {
  id?: string;
  age_groups: string[];
  subjects: string[];
  half_day: boolean;
  prices: ProductVariantPrice[];
};

export type ProductSaveType = {
  id?: string;
  name: string;
  variants: ProductSaveVariant[];
  year_id?: SchoolYear['id'];
  billing_connection: {
    account_id: string;
  };
};

export type ProductSave = {
  name: string;
  type: ProductBillingType;
  description: string;
  obligatory: boolean;
  unique_types: boolean;
  assignment: {
    one_type: boolean;
  };
  triggers: Omit<ProductTrigger, 'id'>[];
  types: ProductSaveType[];
};

export type ProductFormType = Omit<ProductSaveType, 'billing_connection'> & {
  billing_connection: {
    account_id: string;
    legal_entity_id: string;
    legal_entity_currency?: Currencies;
  };
};

export type ProductForm = Omit<ProductSave, 'types'> & {
  types: ProductFormType[];
};

export type GetProductsQueryFilters = {
  [FilterKeys.YearId]?: string[];
};

export const PRODUCTS_QUERY_FILTER_KEYS = [FilterKeys.YearId] as const;

const DEFAULT_PAGE_SIZE = 30;
const INVOICES_URL = '/invoices';

export type GetSchoolAccountsResponse = Array<InternalAccount | XeroAccount>;

export const getSchoolAccounts = async (schoolId: string): Promise<GetSchoolAccountsResponse> => {
  return await api.get(`${INVOICES_URL}/accounts/${schoolId}`);
};

export const GET_SCHOOL_ACCOUNTS = `${INVOICES_URL}/GET_SCHOOL_ACCOUNTS`;

export const useGetSchoolAccounts = (
  schoolId: string,
  options?: RQUseQueryOptions<GetSchoolAccountsResponse>,
) => {
  return useQuery<GetSchoolAccountsResponse, ApiError>(
    [GET_SCHOOL_ACCOUNTS, schoolId],
    () => getSchoolAccounts(schoolId),
    {
      ...options,
    },
  );
};

export const getProduct = async (id: string): Promise<Product> => {
  return api.get(`${INVOICES_URL}/products/${id}`);
};

export const GET_PRODUCT_QUERY = `${INVOICES_URL}/GET_PRODUCT_QUERY`;

export const useGetProductQuery = (id: string, options?: RQUseQueryOptions<Product>) => {
  return useQuery<Product, ApiError>([GET_PRODUCT_QUERY, id], () => getProduct(id), {
    ...options,
  });
};

export type GetProductsSort = IColumnSort<keyof Pick<Product, 'name'>>;

export type GetProductsParams = {
  schoolId: string;
  query?: string;
  pageSize?: number;
  sort?: GetProductsSort[];
  pageNumber?: number;
  relationId?: string;
  legalEntityIds?: string[];
  type?: ProductBillingType;
  filters?: GetProductsQueryFilters;
};

export const getProducts = async ({
  schoolId,
  query = '',
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  relationId,
  legalEntityIds,
  type,
  filters,
}: GetProductsParams): Promise<PagedResponse<Product>> => {
  const params = removeObjectUndefinedNullValues({
    search_query: query || undefined,
    page_size: pageSize,
    page_number: pageNumber,
    relation_id: relationId,
    legal_entity_ids: legalEntityIds?.join(','),
    type,
    year_id: filters?.[FilterKeys.YearId]?.[0],
  });

  return api.get(`${INVOICES_URL}/products/for-school/${schoolId}`, { params });
};

export const GET_PRODUCTS_QUERY = `${INVOICES_URL}/GET_PRODUCTS_QUERY`;

export const useGetProductsListQuery = (
  initialParams: Omit<GetProductsParams, 'sort'> & {
    sort?: GetProductsSort;
    filters?: GetProductsQueryFilters;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Product>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = removeObjectEmptyArrayValues(params.filters ?? {});

  const query = useInfiniteQuery<PagedResponse<Product>, ApiError>(
    [GET_PRODUCTS_QUERY, { ...params, filters }],
    ({ pageParam }) =>
      getProducts({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type CreateProductParams = {
  schoolId: string;
  product: ProductSave;
  withActiveRegistrations?: boolean;
};

export type CreateProductResponse = Product;

export const createProduct = ({
  schoolId,
  withActiveRegistrations,
  ...params
}: CreateProductParams): Promise<CreateProductResponse> => {
  const withActiveRegistrationsParam =
    withActiveRegistrations !== undefined
      ? `?with_active_registrations=${withActiveRegistrations}`
      : '';

  return api.post(
    `${INVOICES_URL}/products/for-school/${schoolId}${withActiveRegistrationsParam}`,
    { ...params.product },
  );
};

export const useCreateProductMutation = (
  options?: RQUseMutationOptions<CreateProductResponse, CreateProductParams>,
): RQUseMutationResult<CreateProductResponse, CreateProductParams> => {
  return useMutation(createProduct, {
    ...options,
  });
};

export type UpdateProductParams = {
  id: string;
  product: ProductSave;
};

export type UpdateProductResponse = { success: string; product: Product };

export const updateProduct = ({
  id,
  ...params
}: UpdateProductParams): Promise<UpdateProductResponse> => {
  return api.patch(`${INVOICES_URL}/products/${id}`, {
    ...params.product,
  });
};

export const useUpdateProductMutation = (
  options?: RQUseMutationOptions<UpdateProductResponse, UpdateProductParams>,
): RQUseMutationResult<UpdateProductResponse, UpdateProductParams> => {
  return useMutation(updateProduct, {
    ...options,
  });
};

export type DeleteProductParams = {
  id: string;
};

export type DeleteProductResponse = { success: string };

export const deleteProduct = ({ id }: DeleteProductParams): Promise<DeleteProductResponse> => {
  return api.remove(`${INVOICES_URL}/products/${id}`);
};

export const useDeleteProductMutation = (
  options?: RQUseMutationOptions<DeleteProductResponse, DeleteProductParams>,
): RQUseMutationResult<DeleteProductResponse, DeleteProductParams> => {
  return useMutation(deleteProduct, {
    ...options,
  });
};

type CheckProductNameUniqueParams = {
  schoolId: string;
  name: string;
};

type CheckProductNameUniqueResponse = {
  is_unique: boolean;
};

const checkProductNameUnique = (
  params: CheckProductNameUniqueParams,
): Promise<CheckProductNameUniqueResponse> => {
  return api.post(`${INVOICES_URL}/products/for-school/${params.schoolId}/validate-name`, {
    name: params.name,
  });
};

export const useCheckProductNameUniqueMutation = (
  options?: RQUseMutationOptions<CheckProductNameUniqueResponse, CheckProductNameUniqueParams>,
): RQUseMutationResult<CheckProductNameUniqueResponse, CheckProductNameUniqueParams> => {
  return useMutation(checkProductNameUnique, {
    ...options,
  });
};

export enum PayerType {
  Default = 'default',
  Company = 'company',
}

export type DefaultPayer = {
  relation_id: string;
  email?: string;
  telephone?: string;
  invite_status: SchoolInviteStatus | null;
} & WithName &
  WithAvatar;

export type AssignedProduct = {
  id: string;
  product_type: ProductBillingType;
  name: string;
  obligatory: boolean;
  payer: Payer;
  discount_percent?: number;
  discounted_price?: number;
  year_id: string;
  variant: {
    id: string;
    price: number;
    currency: Currencies;
    frequency_id: string;
    type_name: string;
  };
};

export type AssignedProductWithAvailableVariants = AssignedProduct & {
  available_variants: AssignedProductAvailableVariant[];
};

export type AssignedProductAvailableVariant = {
  type_id: string;
  type_name: string;
  id: string;
  half_day: boolean;
  prices: ProductVariantPrice[];
  currency: Currencies;
};

type GetStudentProductsParams = {
  relationId: string;
  type?: ProductBillingType;
};

export type YearlyProductAssignments = {
  year_id: string;
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: AssignedProductWithAvailableVariants[];
};

export type GetStudentProductsResponse = {
  yearly_product_assignments: {
    year_id: string;
    default_payer: DefaultPayer;
    company_payer?: Company;
    products: AssignedProductWithAvailableVariants[];
  }[];
};

const getStudentProducts = async ({
  relationId,
  ...params
}: GetStudentProductsParams): Promise<GetStudentProductsResponse> => {
  return api.get(`${INVOICES_URL}/products/for-relation/${relationId}`, { params });
};

export const GET_STUDENT_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENT_PAYERS_QUERY`;

export const useGetStudentProductsQuery = (
  params: GetStudentProductsParams,
  options?: RQUseQueryOptions<GetStudentProductsResponse>,
) => {
  return useQuery<GetStudentProductsResponse, ApiError>(
    [GET_STUDENT_PRODUCTS_QUERY, params],
    () => getStudentProducts(params),
    {
      ...options,
    },
  );
};

export interface StudentWithProducts extends WithName, WithAvatar {
  relation_id: string;
  default_payer: DefaultPayer;
  company_payer?: Company;
  products: AssignedProduct[];
}

type GetStudentsWithProductsSort = IColumnSort<
  keyof Pick<StudentWithProducts, 'last_name' | 'given_name'>
>;

interface GetStudentsWithProductsFilters {
  [FilterKeys.Status]?: string[];
  [FilterKeys.AgeGroup]?: string[];
}

interface GetStudentsWithProductsParams {
  schoolId: string;
  query: string;
  pageSize?: number;
  sort?: GetStudentsWithProductsSort[];
  pageNumber?: number;
  filters?: Partial<GetStudentsWithProductsFilters>;
}

const getStudentsWithProducts = async ({
  schoolId,
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  filters,
}: GetStudentsWithProductsParams): Promise<PagedResponse<StudentWithProducts>> => {
  const baseQueryParams = filters
    ? removeObjectUndefinedNullValues({
        status_ids: filters?.status?.join(','),
        age_group: filters?.age_group?.join(','),
      })
    : {};

  return api.get(`${INVOICES_URL}/students/for-school/${schoolId}`, {
    params: {
      ...baseQueryParams,
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
    },
  });
};

export const GET_STUDENTS_WITH_PRODUCTS_QUERY = `${INVOICES_URL}/GET_STUDENTS_WITH_PRODUCTS_QUERY`;

export const useGetStudentsWithProductsListQuery = (
  initialParams: Omit<GetStudentsWithProductsParams, 'sort'> & {
    sort?: GetStudentsWithProductsSort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentWithProducts>>,
) => {
  const [params, setParams] = useState(initialParams);

  const filters = params.filters ? removeObjectEmptyArrayValues(params.filters) : {};

  const query = useInfiniteQuery<PagedResponse<StudentWithProducts>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsWithProducts({
        pageNumber: pageParam,
        ...params,
        filters,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export interface StudentForCompanyRelation extends WithName, WithAvatar {
  id: string;
}

export interface StudentForCompany {
  relation: StudentForCompanyRelation;
  products: AssignedProduct[];
}

type GetStudentsForCompanySort = IColumnSort<
  keyof Pick<StudentForCompanyRelation, 'last_name' | 'given_name'>
>;

interface GetStudentsForCompanyParams {
  query: string;
  pageSize?: number;
  sort?: GetStudentsForCompanySort[];
  pageNumber?: number;
  companyId: Company['id'];
  year_id?: string;
}

export const getStudentsForCompany = async ({
  query,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber = 1,
  sort = [
    { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    { columnTextId: 'given_name', direction: SORT_DIRECTION.ASC },
  ],
  companyId,
  year_id,
}: GetStudentsForCompanyParams): Promise<PagedResponse<StudentForCompany>> => {
  return api.get(`${INVOICES_URL}/students/for-company/${companyId}`, {
    params: {
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: getSortParams(sort),
      year_id,
    },
  });
};

export const GET_STUDENTS_FOR_COMPANY_QUERY = `${INVOICES_URL}/GET_STUDENTS_FOR_COMPANY_QUERY`;

export const useGetStudentsForCompanyListQuery = (
  initialParams: Omit<GetStudentsForCompanyParams, 'sort'> & {
    sort?: GetStudentsForCompanySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<StudentForCompany>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<StudentForCompany>, ApiError>(
    [GET_STUDENTS_WITH_PRODUCTS_QUERY, params],
    ({ pageParam }) =>
      getStudentsForCompany({
        pageNumber: pageParam,
        ...params,
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type AssignedProductSave = {
  id: string;
  discount_percent?: number;
  payer_type: PayerType;
  variant: {
    type_name: string;
    id: string;
    frequency_id: string;
  };
};

type UpdateStudentProductsParams = {
  studentId: string;
  defaultPayerId: string;
  companyPayerId?: string;
  products: AssignedProductSave[];
  yearId: string;
};

type UpdateStudentProductsResponse = { success: string };

export const updateStudentProducts = ({
  studentId,
  defaultPayerId,
  companyPayerId,
  products,
  yearId,
}: UpdateStudentProductsParams): Promise<UpdateStudentProductsResponse> => {
  return api.patch(`${INVOICES_URL}/products/for-relation/${studentId}`, {
    default_payer_id: defaultPayerId,
    company_payer_id: companyPayerId,
    year_id: yearId,
    products,
  });
};

export const useUpdateStudentProductsMutation = (
  options?: RQUseMutationOptions<UpdateStudentProductsResponse, UpdateStudentProductsParams>,
): RQUseMutationResult<UpdateStudentProductsResponse, UpdateStudentProductsParams> => {
  return useMutation(updateStudentProducts, {
    ...options,
  });
};

export type DependantStudent = {
  id: string;
} & WithName &
  WithAvatar;

type GetDependantStudentsForParentParams = {
  id: string;
  year_id?: string;
};

export const getDependantStudentsForParent = async ({
  id,
  year_id,
}: GetDependantStudentsForParentParams): Promise<DependantStudent[]> => {
  return api.get(`${INVOICES_URL}/students/for-parent/${id}`, {
    params: { year_id },
  });
};

export const GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY`;

export const useGetDependantStudentsForParentQuery = (
  params: GetDependantStudentsForParentParams,
  options?: RQUseQueryOptions<DependantStudent[]>,
) => {
  return useQuery<DependantStudent[], ApiError>(
    [GET_STUDENTS_DEPENDANTS_FOR_PARENT_QUERY, params],
    () => getDependantStudentsForParent(params),
    {
      ...options,
    },
  );
};

type GetProductsForParentParams = {
  relationId: string;
};

export type GetProductsForParentResponse = {
  student_id: string;
  product: AssignedProduct;
}[];

const getProductsForParent = async ({
  relationId,
}: GetProductsForParentParams): Promise<GetProductsForParentResponse> => {
  return api.get(`${INVOICES_URL}/products/for-parent/${relationId}`);
};

export const GET_PRODUCTS_FOR_PARENT_QUERY = `${INVOICES_URL}/GET_PRODUCTS_FOR_PARENT_QUERY`;

export const useGetProductsForParentQuery = (
  params: GetProductsForParentParams,
  options?: RQUseQueryOptions<GetProductsForParentResponse>,
) => {
  return useQuery<GetProductsForParentResponse, ApiError>(
    [GET_PRODUCTS_FOR_PARENT_QUERY, params],
    () => getProductsForParent(params),
    {
      ...options,
    },
  );
};

export type PayableFeeStatus =
  | 'upcoming'
  | 'due'
  | 'partially_paid'
  | 'overdue'
  | 'paid'
  | 'unpaid'
  | 'voided'
  | 'cancelled'
  | 'projected';

export type GetPayableFeesStatisticsParams = {
  school_ids: string;
  year_id: string;
  relation_ids?: string[]; // any school user relation id
  query?: string;
  company_ids?: string[];
};

export type PayableFeesStatisticsItem = {
  label: PayableFeeStatus;
  value: number;
};

export type GetPayableFeesStatisticsResponse = {
  statistics: { currency: Currencies; total: number; items: PayableFeesStatisticsItem[] }[];
};

const getPayableFeesStatistics = async ({
  relation_ids,
  company_ids,
  ...rest
}: GetPayableFeesStatisticsParams): Promise<GetPayableFeesStatisticsResponse> => {
  const params = removeObjectUndefinedNullValues({
    relation_ids: relation_ids?.join(','),
    company_ids: company_ids?.join(','),
    ...rest,
  });

  return api.get(`${INVOICES_URL}/invoice/for-school/statistics`, {
    params,
  });
};

export const GET_PAYABLE_FEES_STATISTICS = `${INVOICES_URL}/GET_PAYABLE_FEES_STATISTICS`;

export const useGetPayableFeesStatisticsQuery = (
  params: GetPayableFeesStatisticsParams,
  options?: RQUseQueryOptions<GetPayableFeesStatisticsResponse>,
) => {
  return useQuery<GetPayableFeesStatisticsResponse, ApiError>(
    [GET_PAYABLE_FEES_STATISTICS, params],
    () => getPayableFeesStatistics(params),
    {
      ...options,
    },
  );
};
type GetPayableFeesParams = {
  school_ids: string;
  year_id?: string;
  query?: string;
  filters?: GetPayableFeesQueryFilters & {
    search?: string;
    company_ids?: string[];
    relation_ids?: string[]; // any school user relation id
  };
  page_number?: number;
  page_size?: number;
  sort?: GetPayableFeesQuerySort;
};

export type GetPayableFeesQuerySort = {
  by:
    | 'status'
    | 'issue_date'
    | 'due_date'
    | 'discount'
    | 'students'
    | 'paid'
    | 'due'
    | 'projected'
    | 'total';
  direction: SORT_DIRECTION;
};

type PayerCommon<T> = {
  type: PayerType;
  data: T;
};

export type Payer =
  | (PayerCommon<Company> & { type: PayerType.Company })
  | (PayerCommon<DefaultPayer> & { type: PayerType.Default });

type PayableFeePayerCompany = Pick<
  Company,
  'id' | 'contact_name' | 'telephone' | 'email' | 'name'
> & { address: string };

export type PayableFeePayer =
  | (PayerCommon<PayableFeePayerCompany> & { type: PayerType.Company })
  | (PayerCommon<DefaultPayer & { id: string }> & { type: PayerType.Default });

export type LineItemCommon = {
  id: string;
  product_id: string;
  product_type: ProductBillingType;
  name: string;
  // Not supported yet
  // item_total: number;
  // item_current: number;
  paid?: number;
  due?: number;
  price: number;
  price_full: number;
  variant_id: string;
  variant_name: string;
  label: string;
  discount_amount: number;
  discount_percent: number;
  student_relation_id: string;
  student_name: string;
};

export type PayableFeeLineItem = {
  frequency_id: string;
  invoice_date: string;
  age_group_name: string;
  frequency_name: string;
  service_date: string;
  // Not supported yet
  // item_total: number;
  // item_current: number;
  has_billing_events_processing_issue: boolean;
} & LineItemCommon;

export type PayableFeeInvoiceLineItem = PayableFeeLineItem & { is_deleted: boolean };

export type PayableFeeCommon = {
  payer: PayableFeePayer;
  students: DependantStudent[];
  status: PayableFeeStatus;
  issue_date: string;
  invoice_number: string | null;
  invoice_id: string | null;
  invoice_date: string;
  due: number;
  due_date: string;
  total_paid: number | null;
  total_payment: number;
  fully_paid_date?: string;
  items: PayableFeeLineItem[];
  invoice_link_for_issuer?: string;
  invoice_link_for_payer?: string;
  currency: Currencies;
  tenant_id: StringOrNull;
};

type PayableFeeWithoutInvoice = {
  invoice_number: null;
  items: PayableFeeLineItem[];
  invoice_link_for_issuer: null;
  invoice_id: null;
} & PayableFeeCommon;

export type PayableFeeWithInvoice = {
  invoice_number: string;
  items: PayableFeeInvoiceLineItem[];
  invoice_link_for_issuer: string;
  invoice_id: string;
  discrepancy_records: InvoiceDiscrepancyRecord[];
} & PayableFeeCommon;

export type PayableFee = PayableFeeWithoutInvoice | PayableFeeWithInvoice;

export enum InvoiceDiscrepancyType {
  InvoiceItemDeleted = 'invoice_item_deleted',
  InvoiceItemDiscountChanged = 'invoice_item_discount_changed',
  InvoiceItemPriceChanged = 'invoice_item_price_changed',
  InvoiceItemLabelChanged = 'invoice_item_label_changed',
  InvoiceItemAccountChanged = 'invoice_item_account_changed',
  InvoiceItemAdded = 'invoice_item_added',
  InvoiceItemQuantityChanged = 'invoice_item_quantity_changed',
  InvoicePayerChanged = 'invoice_payer_changed',
  InvoiceDueDateChanged = 'invoice_due_date_changed',
  InvoiceDateChanged = 'invoice_date_changed',
  InvoiceVoided = 'invoice_voided',
}

enum InvoiceDiscrepancySource {
  FeeAssignmentPayerChanged = 'fee_assignment_payer_changed',
  FeeAssignmentProductTypeChanged = 'fee_assignment_product_type_changed',
  FeeAssignmentCancelled = 'fee_assignment_cancelled',
  RegistrationStatusPeriodChanged = 'registration_status_period_changed',
  ProductLegalEntityChanged = 'product_legal_entity_changed',
  ProductNameChanged = 'product_name_changed',
  ProductPriceChanged = 'product_price_changed',
  FeeAssignmentDiscountChanged = 'fee_assignment_discount_changed',
  ProductLegalEntityAccountChanged = 'product_legal_entity_account_changed',
}

export enum InvoiceDiscrepancyStatus {
  Unreconcilable = 'unreconcilable',
  Reconciled = 'reconciled',
  Unprocessed = 'unprocessed',
}

type DiscrepancyRecordCommon<T, V> = {
  id: string;
  invoice_id: string;
  type: InvoiceDiscrepancyType;
  source: InvoiceDiscrepancySource;
  status: InvoiceDiscrepancyStatus;
  date: string;
  old_invoice_item_id: string;
  new_invoice_item_id: T;
  old_value: V;
  new_value: V;
};

type InvoiceItemDeletedDiscrepancyRecord = {
  type: InvoiceDiscrepancyType.InvoiceItemDeleted;
  source:
    | InvoiceDiscrepancySource.FeeAssignmentPayerChanged
    | InvoiceDiscrepancySource.FeeAssignmentProductTypeChanged
    | InvoiceDiscrepancySource.FeeAssignmentCancelled
    | InvoiceDiscrepancySource.RegistrationStatusPeriodChanged
    | InvoiceDiscrepancySource.ProductLegalEntityChanged;
} & DiscrepancyRecordCommon<null, null>;

type InvoiceItemDiscountChangedDiscrepancyRecord = {
  type: InvoiceDiscrepancyType.InvoiceItemDiscountChanged;
  source: InvoiceDiscrepancySource.FeeAssignmentDiscountChanged;
} & DiscrepancyRecordCommon<string, number>;

type InvoiceItemPriceChangedDiscrepancyRecord = {
  type: InvoiceDiscrepancyType.InvoiceItemPriceChanged;
  source: InvoiceDiscrepancySource.ProductPriceChanged;
} & DiscrepancyRecordCommon<string, number>;

type InvoiceItemLabelChangedDiscrepancyRecord = {
  type: InvoiceDiscrepancyType.InvoiceItemLabelChanged;
  source: InvoiceDiscrepancySource.ProductNameChanged;
} & DiscrepancyRecordCommon<string, string>;

type InvoiceItemAccountChangedDiscrepancyRecord = {
  type: InvoiceDiscrepancyType.InvoiceItemAccountChanged;
  source: InvoiceDiscrepancySource.ProductLegalEntityAccountChanged;
} & DiscrepancyRecordCommon<string, string>;

export type InvoiceDiscrepancyRecord =
  | InvoiceItemDeletedDiscrepancyRecord
  | InvoiceItemDiscountChangedDiscrepancyRecord
  | InvoiceItemPriceChangedDiscrepancyRecord
  | InvoiceItemLabelChangedDiscrepancyRecord
  | InvoiceItemAccountChangedDiscrepancyRecord;

export class ProductTypeId {
  typeId: string;
  productId: string;

  static divider = '__';

  static fromString(s: string): ProductTypeId {
    const [typeId, productId] = s.split(ProductTypeId.divider);
    if (!typeId || !productId) throw new Error('Cannot parse ProductTypeId from string');
    return new ProductTypeId(typeId, productId);
  }

  static areEqual(a: ProductTypeId, b: ProductTypeId): boolean {
    return a.valueOf() === b.valueOf();
  }

  constructor(typeId: string, productId: string) {
    this.typeId = typeId;
    this.productId = productId;
  }

  toString() {
    return String(`${this.typeId}${ProductTypeId.divider}${this.productId}`);
  }

  valueOf() {
    return this.toString();
  }

  isEqual(id: ProductTypeId) {
    return ProductTypeId.areEqual(this, id);
  }
}

const getPayableFees = async ({
  filters,
  sort,
  ...rest
}: GetPayableFeesParams): Promise<PagedResponse<PayableFee>> => {
  const params = removeObjectUndefinedNullValues({
    filters: removeObjectUndefinedNullValues({
      search: filters?.search,
      relation_ids: filters?.relation_ids?.join(','),
      company_ids: filters?.company_ids?.join(','),
      status: filters?.[FilterKeys.FeeStatus]?.join(','),
      date: filters?.[FilterKeys.Date]?.join(','),
      age_group_ids: filters?.[FilterKeys.InvoicesAgeGroup]?.join(','),
      day_past_due: filters?.[FilterKeys.DayPastDue]?.join(','),
      payer_ids: filters?.[FilterKeys.Payer]?.join(','),
      product_ids: filters?.[FilterKeys.Product]?.join(','),
      product_type_ids: filters?.[FilterKeys.ProductType]?.map((t) => t.typeId).join(','),
      frequency_types: filters?.[FilterKeys.Frequency]?.join(','),
    }),
    sort: sort ? JSON.stringify([sort]) : undefined,
    ...rest,
  });

  return api.get(`${INVOICES_URL}/invoice/for-school`, { params });
};

export type InvoicesReportingAggregatedSortOption =
  | FilterKeys.Month
  | FilterKeys.Name
  | FilterKeys.DueAmount
  | FilterKeys.Overdue
  | FilterKeys.Discount;

export type InvoicesReportingAggregatedSort = {
  by: InvoicesReportingAggregatedSortOption;
  direction: SORT_DIRECTION;
};

export type InvoicesReportingBreakDownBy =
  | FilterKeys.FeeStatus
  | FilterKeys.InvoicesAgeGroup
  | FilterKeys.Payer;

export type GetInvoicesReportingAggregatedParams = {
  schoolId: string;
  filters: GetPayableFeesQueryFilters & { search?: string };
  sort: InvoicesReportingAggregatedSort;
  breakDownBy?: InvoicesReportingBreakDownBy;
  arrangeBy: GetPayableFeesArrangeBy;
};

export type InvoicesReportingAggregatedRow = {
  name: string; // according to the InvoicesReportingArrangeBy property
  value: string | number; // according to the InvoicesReportingArrangeBy property
  count: number; // total count of line items
  // rest is aggregated data
  payers: number;
  students: number;
  discount: number;
  paid: number;
  due: number;
  overdue: number;
  projected: number;
  total: number;
};

export interface PayableFeesAggregatedRow {
  total: number;
  name: string;
  value: string;
}

export interface PayableFeesAggregatedData {
  rows: PayableFeesAggregatedRow[];
  value: string;
  name: string;
  key: string;
}

export type GetInvoicesReportingAggregatedResponse = {
  currency: Currencies;
  total: number;
  rows: InvoicesReportingAggregatedRow[];
  chartData: PayableFeesAggregatedData[];
};

export const getInvoicesReportingAggregated = async (
  params: GetInvoicesReportingAggregatedParams,
): Promise<GetInvoicesReportingAggregatedResponse> => {
  const { by, direction } = params.sort;

  const sort =
    params.arrangeBy !== FilterKeys.DayPastDue
      ? {
          direction,
          by: by === FilterKeys.Name || by === FilterKeys.Month ? 'default' : by,
        }
      : undefined;

  const payload = removeObjectUndefinedNullValues({
    arrange_by: params.arrangeBy,
    break_down_by: params.breakDownBy === FilterKeys.FeeStatus ? 'status' : params.breakDownBy,
    school_id: params.schoolId,
    filters: removeObjectUndefinedNullValues({
      search: params.filters?.search,
      date: params.filters[FilterKeys.Date]?.join(','),
      age_group_ids: params.filters[FilterKeys.InvoicesAgeGroup]?.join(','),
      status: params.filters?.[FilterKeys.FeeStatus]?.join(','),
      day_past_due: params.filters?.[FilterKeys.DayPastDue]?.join(','),
      product_ids: params.filters?.[FilterKeys.Product]?.join(','),
      product_type_ids: params.filters?.[FilterKeys.ProductType]?.map((t) => t.typeId).join(','),
      frequency_types: params.filters?.[FilterKeys.Frequency]?.join(','),
    }),
    sort: sort ? JSON.stringify([sort]) : undefined,
  });

  return api.get(`/billing/invoice/aggregated`, { params: payload });
};

export const useGetInvoicesReportingAggregatedQuery = (
  initialParams: GetInvoicesReportingAggregatedParams,
  options?: RQUseQueryOptions<GetInvoicesReportingAggregatedResponse>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useQuery<GetInvoicesReportingAggregatedResponse, ApiError>(
    [GET_PAYABLE_FEES, params],
    () => getInvoicesReportingAggregated(params),
    {
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type InvoicesReportingDayPastDue =
  | 'awaiting_payment'
  | 'paid_on_time'
  | 'paid_less_than_2_weeks_late'
  | 'paid_less_than_4_weeks_late'
  | 'paid_more_than_4_weeks_late'
  | 'overdue_by_less_than_2_weeks'
  | 'overdue_by_less_than_4_weeks'
  | 'overdue_by_more_than_4_weeks';

export const GET_PAYABLE_FEES = `${INVOICES_URL}/GET_PAYABLE_FEES`;

export const PAYABLE_FEES_QUERY_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Product,
  FilterKeys.InvoicesAgeGroup,
  FilterKeys.ProductType,
  FilterKeys.Frequency,
  FilterKeys.DayPastDue,
  FilterKeys.FeeStatus,
] as const;

export type InvoicesReportingAggregatedArrangeBy =
  | FilterKeys.Month
  | FilterKeys.Payer
  | FilterKeys.Product
  | FilterKeys.DayPastDue
  | FilterKeys.InvoicesAgeGroup;

export const PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS = [
  FilterKeys.Month,
  // FilterKeys.Payer,
  FilterKeys.Product,
  FilterKeys.DayPastDue,
  FilterKeys.InvoicesAgeGroup,
] as const;

export type GetPayableFeesQueryFilters = {
  [FilterKeys.Date]?: string[];
  [FilterKeys.Product]?: string[];
  [FilterKeys.Payer]?: string[];
  [FilterKeys.InvoicesAgeGroup]?: string[];
  [FilterKeys.ProductType]?: ProductTypeId[];
  [FilterKeys.Frequency]?: PaymentFrequencyType[];
  [FilterKeys.FeeStatus]?: PayableFeeStatus[];
  [FilterKeys.DayPastDue]?: InvoicesReportingDayPastDue[];
};

export type GetPayableFeesArrangeBy =
  | FilterKeys.Month
  | FilterKeys.Payer
  | FilterKeys.Product
  | FilterKeys.DayPastDue
  | FilterKeys.InvoicesAgeGroup;

export const useGetPayableFeesQuery = (
  initialParams: GetPayableFeesParams,
  options?: RQUseInfiniteQueryOptions<PagedResponse<PayableFee>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<PayableFee>, ApiError>(
    [GET_PAYABLE_FEES, params],
    ({ pageParam }) =>
      getPayableFees({
        page_number: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export type SingleInvoiceAssignedProduct = {
  quantity: number;
  discount_percent?: number;
  price: number;
  variant_id: string;
  type_id: string;
  type_name: string;
  id: string;
  name: string;
  billing_connection: ProductType['billing_connection'];
};

export type SingleInvoiceStudent = SchoolRelation & WithName & WithAvatar;

export type SingleInvoicePayer =
  | (Company & { type: 'company' })
  | (Guardian & { type: 'guardian' });

export type SingleInvoice = {
  generation_date: string;
  invoice_date: string;
  due_date: string;
  assignments: Array<{
    relation: SingleInvoiceStudent;
    payer: SingleInvoicePayer;
    products: Array<SingleInvoiceAssignedProduct>;
  }>;
};

export type SingleInvoiceSave = {
  generation_date: string;
  invoice_date: string;
  due_date: string;
  assignments: Array<{
    relation_id: string;
    payer: {
      type: 'company' | 'guardian';
      id: string;
    };
    products: Array<Omit<SingleInvoiceAssignedProduct, 'price' | 'billing_connection'>>;
  }>;
};

type CreateSingleInvoiceParams = {
  school_id: string;
  single_invoice: SingleInvoiceSave;
};

type CreateSingleInvoiceResponse = { single_invoices: SingleInvoice[] };

export const createSingleInvoices = ({
  school_id,
  single_invoice,
}: CreateSingleInvoiceParams): Promise<CreateSingleInvoiceResponse> => {
  return api.post(`${INVOICES_URL}/single-invoices`, {
    school_id,
    single_invoice,
  });
};

export const useCreateSingleInvoicesMutation = (
  options?: RQUseMutationOptions<CreateSingleInvoiceResponse, CreateSingleInvoiceParams>,
): RQUseMutationResult<CreateSingleInvoiceResponse, CreateSingleInvoiceParams> => {
  return useMutation(createSingleInvoices, {
    ...options,
  });
};

export type InvoiceLineItem = {
  billing_connection: ProductType['billing_connection'];
} & LineItemCommon;

export type InvoiceStudent = {
  company_payer: Company | null;
  parents: DefaultPayer[];
  relation_id: string;
} & DependantStudent;

export type Invoice = {
  invoice_id: string;
  due_date: string;
  issue_date: string;
  invoice_date: string;
  invoice_link: string;
  invoice_number: string;
  students: InvoiceStudent[];
  total_paid: number | null;
  total_payment: number;
  tenant_id: string | null;
  status: PayableFeeStatus;
  items: InvoiceLineItem[];
  legal_entity: {
    currency: Currencies;
    display_name: string;
    id: string;
    type: AccountType;
  };
  payer: Payer;
};

type GetInvoiceParams = {
  id: string;
};

type GetInvoiceResponse = { invoice: Invoice };

const GET_SINGLE_INVOICE_QUERY = `${INVOICES_URL}/GET_SINGLE_INVOICE_QUERY`;

export const getInvoice = ({ id }: GetInvoiceParams): Promise<GetInvoiceResponse> => {
  return api.get(`${INVOICES_URL}/${id}`);
};

export const useGetInvoiceQuery = (
  params: GetInvoiceParams,
  options?: RQUseQueryOptions<GetInvoiceResponse>,
) => {
  return useQuery<GetInvoiceResponse, ApiError>(
    [GET_SINGLE_INVOICE_QUERY, params],
    () => getInvoice(params),
    {
      ...options,
    },
  );
};

type InvoiceActions = 'void';

type InvoiceActionParams = {
  invoice_ids: string[];
  action: InvoiceActions;
};

export type InvoiceActionResponse = { success: string };

export const invoiceAction = (params: InvoiceActionParams): Promise<InvoiceActionResponse> => {
  return api.post(`${INVOICES_URL}/action`, params);
};

export const INVOICE_ACTION_MUTATION = 'INVOICE_ACTION_MUTATION';

export const useInvoiceActionMutation = (
  options?: RQUseMutationOptions<InvoiceActionResponse, InvoiceActionParams>,
): RQUseMutationResult<InvoiceActionResponse, InvoiceActionParams> => {
  return useMutation([INVOICE_ACTION_MUTATION], invoiceAction, {
    ...options,
  });
};

export type UpdateInvoiceParams = {
  id: string;
  invoice: UpdatedInvoice;
};

type UpdatedInvoice = {
  items: Array<InvoiceLineItem | Omit<InvoiceLineItem, 'id'>>;
} & Omit<Invoice, 'items'>;

type UpdateInvoiceResponse = {
  invoice: Invoice;
};

export const updateInvoice = ({
  id,
  ...params
}: UpdateInvoiceParams): Promise<UpdateInvoiceResponse> => {
  return api.patch(`${INVOICES_URL}/${id}`, {
    ...params,
  });
};

export const useUpdateInvoiceMutation = (
  options?: RQUseMutationOptions<UpdateInvoiceResponse, UpdateInvoiceParams>,
): RQUseMutationResult<UpdateInvoiceResponse, UpdateInvoiceParams> => {
  return useMutation(updateInvoice, {
    ...options,
  });
};

export type GetProductsToBeAssignedParams = {
  relation_id?: string;
  school_id: string;
  enrollments: EnrollmentBase[];
};
export type GetProductsToBeAssignedResponse = { products: Product[][] };

export const getProductsToBeAssigned = ({
  relation_id,
  school_id,
  ...params
}: GetProductsToBeAssignedParams): Promise<GetProductsToBeAssignedResponse> => {
  const queryParams = new URLSearchParams({
    school_id,
    ...(relation_id ? { relation_id } : {}),
  }).toString();

  return api.post(`${INVOICES_URL}/products/assignment-preview?${queryParams}`, params);
};

export const useGetProductsToBeAssignedMutation = (
  options?: RQUseMutationOptions<GetProductsToBeAssignedResponse, GetProductsToBeAssignedParams>,
): RQUseMutationResult<GetProductsToBeAssignedResponse, GetProductsToBeAssignedParams> => {
  return useMutation(getProductsToBeAssigned, {
    ...options,
  });
};
