import {
  Backdrop,
  Box,
  ClickAwayListener,
  Icon,
  Skeleton,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { LegalEntity, ProductForm, useGetLegalEntitiesListQuery } from '@schooly/api';
import {
  ExpandedSelect,
  ExpandedSelectProps,
  SelectContentSkeleton,
  SelectSearchInput,
} from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import {
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ExtendedFieldError } from '../../../../components/ui/Input/utils';

type BillingConnectionComponentProps<T> = {
  selectedId?: string;
  isAdmin?: boolean;
  onSelect: (val: T) => void;
  error?: ExtendedFieldError;
  options: T[];
  opened?: boolean;
  placeholder: string;
  width?: number;
  children?: (opened: boolean) => ReactNode;
  labelMaxWidth?: number;
  isLoading: boolean;
} & Omit<
  ExpandedSelectProps,
  'renderContent' | 'hasSelectedValue' | 'onClose' | 'children' | 'onSelect' | 'ref'
>;

const DEFAULT_WIDTH = 488;

export type DropdownProps = {
  open: () => void;
  close: () => void;
};

export function BillingConnectionComponent<
  T extends { display_name?: string; name: string; id: string },
>(
  {
    selectedId,
    onSelect,
    error,
    options,
    opened: initialOpened,
    placeholder,
    width,
    children,
    labelMaxWidth,
    isLoading,
    ...props
  }: BillingConnectionComponentProps<T>,
  ref: React.ForwardedRef<DropdownProps>,
) {
  const currentWidth = width ?? DEFAULT_WIDTH;
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const [opened, open, close] = useFlag(initialOpened);
  const selected = options.find((g) => g.id === selectedId);
  const selectedOptionName = selected?.display_name ?? selected?.name;
  const selectedOptionNameContent =
    !selected && isLoading ? <Skeleton width={labelMaxWidth ?? 200} /> : selectedOptionName;

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [open, close],
  );

  const renderContent = useCallback(() => {
    if (!options.length) return <SelectContentSkeleton />;

    const filteredList = options.filter(
      (a) =>
        a.name.toLowerCase().includes(query.toLowerCase()) ||
        a.display_name?.toLowerCase().includes(query.toLowerCase()),
    );

    if (!filteredList.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <Stack p={1.25} gap={0.5}>
        {filteredList.map((val) => {
          const isSelected = selectedId === val.id;
          const name = val.display_name ?? val.name;

          return (
            <Stack
              key={val.id}
              onClick={() => {
                onSelect(val);
                close();
              }}
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {name}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </Stack>
    );
  }, [options, query, selectedId, onSelect, close]);

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={() => {}}>
        <Box sx={{ overflowX: 'auto' }}>
          <Tooltip
            onClose={close}
            open={opened}
            PopperProps={{
              disablePortal: true,
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, opened ? -8 : -30],
                  },
                },
              ],
            }}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: currentWidth,
                  maxWidth: currentWidth,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0, 0, 0)} !important`,
                  '.header': {
                    minHeight: 36,
                  },
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <ExpandedSelect
                onClickInputArea={() => inputRef.current?.focus()}
                hasSelectedValue={!!selected}
                onClose={close}
                renderContent={renderContent}
                width={currentWidth}
                {...props}
              >
                {selectedId ? (
                  <TypographyWithOverflowHint variant="h3">
                    {selectedOptionNameContent}
                  </TypographyWithOverflowHint>
                ) : (
                  <SelectSearchInput
                    ref={inputRef}
                    autoFocus
                    value={query || ''}
                    onChangeText={setQuery}
                    placeholder={placeholder}
                  />
                )}
              </ExpandedSelect>
            }
          >
            <Stack
              flexDirection="row"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={open}
            >
              {!opened ? (
                <Stack flexDirection="row" alignItems="center" pr={1} sx={{ overflowX: 'auto' }}>
                  {selectedId ? (
                    <TypographyWithOverflowHint
                      flex={1}
                      variant="h3"
                      noWrap
                      maxWidth={labelMaxWidth}
                    >
                      {selectedOptionNameContent}
                    </TypographyWithOverflowHint>
                  ) : (
                    <Typography
                      variant="h3"
                      sx={(theme) => ({
                        color: error ? theme.palette.error.main : 'common.grey',
                      })}
                    >
                      {placeholder}
                    </Typography>
                  )}
                </Stack>
              ) : (
                <></>
              )}
              {children?.(opened)}
            </Stack>
          </Tooltip>
        </Box>
      </ClickAwayListener>
    </>
  );
}

const BillingConnection = forwardRef(BillingConnectionComponent);

type BillingConnectionFormProps = {
  typeIndex: number;
  schoolId: string;
  opened?: boolean;
  maxLabelWidth: number;
} & StackProps;

export const BillingConnectionForm: FC<BillingConnectionFormProps> = ({
  typeIndex,
  schoolId,
  opened,
  maxLabelWidth,
  ...props
}) => {
  const { control, watch, setValue } = useFormContext<ProductForm>();
  const dropdownRef = useRef<DropdownProps | null>(null);

  const { $t } = useIntl();

  const leIdPath = `types.${typeIndex}.billing_connection.legal_entity_id` as const;
  const accountIdPath = `types.${typeIndex}.billing_connection.account_id` as const;
  const currencyPath = `types.${typeIndex}.billing_connection.legal_entity_currency` as const;

  const legalEntityId = watch(leIdPath);
  const accountId = watch(accountIdPath);

  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetLegalEntitiesListQuery(
      { schoolId, archived: false },
      {
        refetchOnMount: true,
      },
    );

  const entries = useMemo(
    () => data?.pages.reduce<LegalEntity[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const currentLegalEntity = legalEntityId ? entries.find((l) => l.id === legalEntityId) : null;

  return (
    <Stack direction="row" {...props}>
      <Controller
        control={control}
        name={leIdPath}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <BillingConnection
            opened={opened}
            onSelect={(v) => {
              if (accountId) {
                setValue(accountIdPath, '');
              }

              const account = v.accounts[0];

              if (!v.connected_tenant && account) {
                setValue(accountIdPath, account.id);
              }

              setValue(currencyPath, v.currency);
              field.onChange(v.id);
              dropdownRef.current?.open();
            }}
            options={entries}
            selectedId={field.value}
            error={fieldState.error}
            placeholder={$t({ id: 'legalEntities-AddLegalEntity' })}
            isFetchingNextPage={isLoading || isFetchingNextPage}
            hasNextPage={hasNextPage}
            onFetchNextPage={fetchNextPage}
            labelMaxWidth={currentLegalEntity?.connected_tenant ? maxLabelWidth : undefined}
            onClear={() => {
              field.onChange('');
              setValue(accountIdPath, '');
            }}
            isLoading={isLoading}
          >
            {(opened) =>
              !opened &&
              currentLegalEntity?.connected_tenant && <AccountSeparator selected={!!accountId} />
            }
          </BillingConnection>
        )}
      />

      {currentLegalEntity?.connected_tenant && (
        <Controller
          control={control}
          name={accountIdPath}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <BillingConnection
              onSelect={(a) => {
                field.onChange(a.id);
              }}
              options={currentLegalEntity.accounts}
              selectedId={field.value}
              error={fieldState.error}
              placeholder={$t({ id: 'legalEntities-SelectAccount' })}
              width={260}
              ref={dropdownRef}
              opened={!accountId}
              labelMaxWidth={currentLegalEntity?.connected_tenant ? maxLabelWidth : undefined}
              onClear={() => field.onChange('')}
              isLoading={isLoading}
            />
          )}
        />
      )}
    </Stack>
  );
};

type AccountSeparatorProps = {
  selected: boolean;
} & StackProps;

export const AccountSeparator: FC<AccountSeparatorProps> = ({ selected, sx, ...rest }) => (
  <Stack
    sx={{
      height: '3px',
      width: '3px',
      minWidth: '3px',
      borderRadius: '50%',
      backgroundColor: selected ? 'primary.main' : 'common.grey',
      alignSelf: 'center',
      mr: 1,
      ...sx,
    }}
    {...rest}
  />
);
