import { Stack, Typography } from '@mui/material';
import {
  FilterKeys,
  GetProductsQueryFilters,
  Product,
  PRODUCTS_QUERY_FILTER_KEYS,
  SORT_DIRECTION,
  useGetProductsListQuery,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { PRODUCTS_YEAR_ID_PARAM } from '@schooly/constants';
import { GridBody, MainPageGrid, PlusIcon, SkeletonGridLoader } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import useSchoolYears from '../../../hooks/useSchoolYears';
import { getSortedFrequencies } from './helpers';
import { ProductFilters } from './ProductFilters';
import { NoFrequencies } from './SchoolProductCommon';
import { ProductRow, ProductsHeader } from './SchoolProductsGrid';
import { SchoolProductsSkeleton } from './SchoolProductsSkeleton';

export const PAGE_SIZE = 30;
export const SKELETON_COLUMNS = 12;

export const SchoolProductsContent: FC = () => {
  const { schoolId = '', permissions } = useAuth();
  const { $t } = useIntl();
  const navigate = useNavigate();

  const [showAllFrequencies, setShowAllFrequencies] = useState(false);

  const { defaultValidity, getSchoolYearById } = useSchoolYears();

  const canCreate =
    permissions.includes('product_and_invoice_creator') ||
    permissions.includes('product_and_invoice_manager');

  const canViewFrequency =
    permissions.includes('product_and_invoice_viewer') ||
    permissions.includes('registration_manager');

  const defaultFilters: GetProductsQueryFilters = useMemo(
    () => ({
      [FilterKeys.YearId]: [defaultValidity?.id ?? ''],
    }),
    [defaultValidity],
  );

  const { lastAppliedFilter } = useLastAppliedFiltersState({
    type: StoredFilterSections.Products,
    filterKeys: PRODUCTS_QUERY_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: PRODUCTS_QUERY_FILTER_KEYS,
    defaultFilters,
  });

  const { isLoading, data, params, isFetchingNextPage, fetchNextPage, hasNextPage, setParams } =
    useGetProductsListQuery(
      {
        schoolId,
        query: '',
        sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
        filters: lastAppliedFilter ?? initialFiltersState,
      },
      { refetchOnMount: 'always', enabled: !!schoolId },
    );

  useSyncFiltersStateWithSearchParams({
    pathname: '/settings/products',
    filters: params.filters ?? {},
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Products,
    filters: params.filters ?? {},
    schoolId: schoolId || '',
  });

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );
  const handleSetFilters = useCallback(
    (filters: GetProductsQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const schoolYearId = params.filters?.[FilterKeys.YearId]?.[0] ?? '';
  const schoolYear = getSchoolYearById(schoolYearId);

  const { data: frequencyData, isLoading: isFrequencyLoading } = useGetSchoolPaymentFrequencies(
    {
      school_id: schoolId,
      year_id: schoolYearId,
    },
    { enabled: !!schoolYearId },
  );

  const hasDisabledFrequency = !!frequencyData?.frequencies.some((f) => !f.in_use);

  const { sortedFrequencies, hasAvailableFrequencies } = useMemo(() => {
    const sortedFrequencies = frequencyData?.frequencies
      ? getSortedFrequencies(frequencyData.frequencies)
      : undefined;

    return {
      sortedFrequencies: showAllFrequencies
        ? sortedFrequencies
        : sortedFrequencies?.filter((f) => f.in_use),
      hasAvailableFrequencies: !!sortedFrequencies?.some((f) => f.in_use),
    };
  }, [frequencyData?.frequencies, showAllFrequencies]);

  const canCreateProductVersion = schoolYear && !isDateInPast(schoolYear.end) && canCreate;

  const yearIdParam = schoolYearId ? `?${PRODUCTS_YEAR_ID_PARAM}=${schoolYearId}` : '';
  const handleCreateProductClick = useCallback(() => {
    navigate(`/settings/products/new${yearIdParam}`);
  }, [navigate, yearIdParam]);

  const entries = useMemo(
    () => data?.pages.reduce<Product[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  const total = data?.pages[0].count;

  const noAvailableFrequenciesTitle = !hasAvailableFrequencies ? (
    <NoFrequencies canView={canViewFrequency} />
  ) : (
    ''
  );

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="products-NewProduct"
          pageTitleTextId="products-Title"
          showActionButton={canCreateProductVersion && !isFrequencyLoading}
          buttonIcon={<PlusIcon />}
          buttonDisabled={!hasAvailableFrequencies}
          onButtonClick={handleCreateProductClick}
          tooltipTitle={noAvailableFrequenciesTitle}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={$t({ id: 'products-SearchAmongProducts' })}
          />
        </PageHeader>
        <ProductFilters
          showAllFrequencies={showAllFrequencies}
          defaultFilters={defaultFilters}
          filters={params.filters ?? {}}
          defaultShowAllFrequencies={showAllFrequencies}
          showFrequenciesToggle={hasDisabledFrequency}
          onSetShowAllFrequencies={setShowAllFrequencies}
          onSetFilters={handleSetFilters}
        />
      </Stack>

      {isLoading || !sortedFrequencies ? (
        <SchoolProductsSkeleton withPageHeader={false} />
      ) : (
        <MainPageGrid mt={2}>
          <ProductsHeader frequencies={sortedFrequencies} />
          <GridBody>
            {entries?.map((entry) => (
              <ProductRow
                schoolId={schoolId}
                product={entry}
                key={entry.id}
                frequencies={sortedFrequencies}
                canViewFrequency={canViewFrequency}
                path={`/settings/products/${entry.id}${yearIdParam}`}
                schoolYear={schoolYear}
              />
            ))}
            <SkeletonGridLoader
              isFetching={isLoading || isFetchingNextPage}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              columnsCount={SKELETON_COLUMNS}
              amount={Math.min(
                PAGE_SIZE,
                total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
              )}
            />
          </GridBody>
        </MainPageGrid>
      )}
      {!isLoading && !entries.length && (
        <MainGridNoResultsStub
          textId="products-noProductsMatching"
          subText={
            !params.query &&
            canCreateProductVersion &&
            hasAvailableFrequencies && (
              <Typography variant="h3" mb={1} whiteSpace="pre-wrap" textAlign="center">
                <FormattedMessage id="products-CreateProductVersionForThisYear" />
              </Typography>
            )
          }
          showFiltersInfo={false}
        />
      )}
    </>
  );
};
