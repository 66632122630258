import {
  Box,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AssignedProductAvailableVariant,
  AssignedProductWithAvailableVariants,
  PayerType,
  ProductVariantPrice,
} from '@schooly/api';
import {
  ArrowDownV2Icon,
  CircleIconButton,
  CursorCloseHandIcon,
  DragAndDropBlock,
  DragIcon,
  Grid,
  GridBody,
  GridCell,
  GridHead,
  InformationBlock,
  LockIcon,
  MinusIcon,
  Price,
  PRICE_SUBTEXT_CLASS_NAME,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useCallback, useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useFieldArray, UseFormReturn } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { PlusIcon } from '../../../../../components/ui/Icons';
import { getCurrencySymbol } from '../../../../../pages/School/SchoolProducts/helpers';
import { DiscountInput } from './DiscountInput';
import { getDiscountedPrice } from './helpers';
import { ProductTypeSelect } from './ProductTypeSelect';
import {
  AssignedProductUpdate,
  handleUpdateProductProps,
  UpdateStudentProductsForm,
  WithIndex,
} from './StudentProductsModalContent';
import { VariantPriceSelect } from './VariantPriceSelect';

export const HOVER_TEXT_CLASS_NAME = 'hoverText';
const HOVER_ICON_CLASS_NAME = 'hoverIcon';
const ASSIGNED_PRODUCTS_DND_TYPE = 'ASSIGNED_PRODUCTS_DND_TYPE';

type StudentProductDragItem = AssignedProductUpdate & WithIndex;

type StudentProductsModalListProps = {
  relationId: string;
  payerType: PayerType;
  products: (AssignedProductUpdate & WithIndex)[];
  schoolId: string;
  form: UseFormReturn<UpdateStudentProductsForm>;
  yearId: string;
  onUpdateProduct: (p: handleUpdateProductProps) => void;
  missingFrequencyId: string;
  missingFrequencyForVariantId: Set<string>;
  canDrag: boolean;
  getExistingAssignment: (variantId: string) => AssignedProductWithAvailableVariants | undefined;
  canAdd: boolean;
};

export const StudentProductsModalList: FC<StudentProductsModalListProps> = ({
  form,
  schoolId,
  payerType,
  products,
  yearId,
  onUpdateProduct,
  missingFrequencyId,
  missingFrequencyForVariantId,
  canDrag,
  getExistingAssignment,
  canAdd,
}) => {
  const gridRef = useRef<HTMLTableElement>(null);
  const productsTotal = form.watch('products').length;

  const { remove, move } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const handleProductPayerChange = useCallback(
    (index: number) => onUpdateProduct({ index, productUpdate: { payer_type: payerType } }),
    [onUpdateProduct, payerType],
  );

  const scrollOnDrop = useCallback(() => {
    const gridElement = gridRef.current;
    if (gridElement) {
      const { bottom } = gridElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight - 60;
      if (bottom > viewportHeight) {
        gridElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, []);

  const onDrop = useCallback(
    (item: StudentProductDragItem) => {
      if (item.payer_type !== payerType) {
        handleProductPayerChange(item.index);
        move(item.index, productsTotal - 1);
        scrollOnDrop();
      }
    },
    [handleProductPayerChange, move, payerType, productsTotal, scrollOnDrop],
  );

  const [, drop] = useDrop(
    useMemo(() => ({ accept: ASSIGNED_PRODUCTS_DND_TYPE, drop: onDrop }), [onDrop]),
  );

  if (!products.length)
    return <StudentProductsEmptyList onDrop={onDrop} payerType={payerType} canAdd={canAdd} />;

  return (
    <Grid stickyHeader fixedLayout ref={gridRef}>
      <StudentProductsHeader />

      <GridBody ref={drop}>
        {products.map((product, i, arr) => {
          const sameProducts = products.filter((p) => p.id === product.id);

          return (
            <StudentProductRow
              key={product.id + product.variant.id}
              missingFrequencyId={
                missingFrequencyForVariantId.has(product.variant.id)
                  ? missingFrequencyId
                  : undefined
              }
              selectedFrequencyId={missingFrequencyId}
              schoolId={schoolId}
              product={product}
              otherAddedTypes={sameProducts
                .map((p) => p.variant.type_name)
                .filter((typeName) => typeName !== product.variant.type_name)}
              onDelete={!product.obligatory || sameProducts.length > 1 ? remove : undefined}
              onUpdate={onUpdateProduct}
              yearId={yearId}
              canDrag={canDrag}
              getExistingAssignment={getExistingAssignment}
            />
          );
        })}
      </GridBody>
    </Grid>
  );
};

export const StudentProductsHeader: FC = () => {
  const { $t } = useIntl();

  return (
    <GridHead sx={{ '& .MuiTableCell-root.MuiTableCell-head': { height: 34 } }} borderBottom>
      <GridCell>{$t({ id: 'products-Name' })} </GridCell>
      <GridCell
        width="110px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: { width: '90px' },
        })}
      >
        {$t({ id: 'products-Type' })}
      </GridCell>
      <GridCell width="110px">{$t({ id: 'frequencies-Frequency' })}</GridCell>
      <GridCell
        width="100px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: { width: '90px' },
        })}
      >
        {$t({ id: 'products-Price' })}
      </GridCell>
      <GridCell width="45px">{$t({ id: 'products-Dis' })}</GridCell>
      <GridCell
        width="100px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: { width: '90px' },
        })}
      >
        {$t({ id: 'products-Amount' })}
      </GridCell>
      <GridCell width="30px" />
    </GridHead>
  );
};

type StudentProductRowProps = {
  schoolId: string;
  product: AssignedProductUpdate & WithIndex;
  missingFrequencyId?: string;
  selectedFrequencyId?: string;
  otherAddedTypes: string[];
  onDelete?: (index: number) => void;
  yearId: string;
  onUpdate: (p: handleUpdateProductProps) => void;
  canDrag: boolean;
  getExistingAssignment: (variantId: string) => AssignedProductWithAvailableVariants | undefined;
};

const StudentProductRow: FC<StudentProductRowProps> = ({
  product,
  schoolId,
  missingFrequencyId,
  selectedFrequencyId,
  otherAddedTypes,
  onDelete,
  onUpdate,
  yearId,
  canDrag,
  getExistingAssignment,
}) => {
  const { formatMessage } = useIntl();
  const { index, isExistingAssignment } = product;

  const [, drag, preview] = useDrag(
    useMemo(
      () => ({
        type: ASSIGNED_PRODUCTS_DND_TYPE,
        item: product,
      }),
      [product],
    ),
  );

  const getRowStyles = (theme: Theme) => ({
    '&:hover': {
      'td.MuiTableCell-root': {
        backgroundColor: theme.palette.background.default,
        '.MuiTypography-root': {
          color: theme.palette.primary.main,
        },
        [`.${HOVER_ICON_CLASS_NAME}`]: {
          color: theme.palette.primary.main,
        },
        [`.${PRICE_SUBTEXT_CLASS_NAME}, .${HOVER_TEXT_CLASS_NAME}`]: {
          color: theme.palette.common.grey2,
        },
        ' input': {
          color: theme.palette.primary.main,
        },
      },
    },
  });

  const { handleProductPriceChange, handleProductDiscountChange, handleTypeChange } = useMemo(
    () => ({
      handleProductPriceChange: (price: ProductVariantPrice) => {
        onUpdate({
          index,
          productUpdate: { variant: { frequency_id: price.frequency_id } },
        });
      },
      handleProductDiscountChange: (discount?: number) => {
        onUpdate({ index, productUpdate: { discount_percent: discount } });
      },
      handleTypeChange: (variant: AssignedProductAvailableVariant) => {
        const existingAssignment = getExistingAssignment(variant.id);

        const getFrequencyId = () => {
          if (existingAssignment) return existingAssignment.variant.frequency_id;

          const hasSameFrequency = variant.prices.some(
            (p) => p.frequency_id === product.variant.frequency_id,
          );
          if (hasSameFrequency) return product.variant.frequency_id;

          return variant.prices[0].frequency_id;
        };

        onUpdate({
          index,
          productUpdate: {
            isExistingAssignment: !!existingAssignment,
            variant: {
              id: variant.id,
              type_name: variant.type_name,
              frequency_id: getFrequencyId(),
            },
          },
        });
      },
    }),
    [getExistingAssignment, index, onUpdate, product.variant.frequency_id],
  );

  const { price, variant } = useMemo(() => {
    const variant = product.available_variants.find((v) => v.id === product.variant.id);
    const price = variant?.prices.find(
      (p) => p.frequency_id === product.variant.frequency_id,
    )?.price;

    return {
      price,
      variant,
    };
  }, [product.available_variants, product.variant.id, product.variant.frequency_id]);

  const typeOptions = useMemo(
    () => product.available_variants.filter((v) => !otherAddedTypes.includes(v.type_name)),
    [otherAddedTypes, product.available_variants],
  );

  const hasMultiplePrices = (variant?.prices.length ?? 0) > 1;
  //Based on TR-6701 frequency selection is temporary blocked for existing assignments
  const canEditFrequency = !isExistingAssignment;
  const priceSelectDisabled = !canEditFrequency || !hasMultiplePrices;

  const isSelectedFrequency = product.variant.frequency_id === selectedFrequencyId;
  const blockedFrequencyChange = !canEditFrequency && !!selectedFrequencyId && !isSelectedFrequency;
  const showMissingFrequencyId =
    hasMultiplePrices && canEditFrequency ? missingFrequencyId : undefined;
  const showPriceSelectWarning =
    hasMultiplePrices && (blockedFrequencyChange || !!missingFrequencyId);

  const renderPriceSelectRightIcon = useCallback(() => {
    if (!hasMultiplePrices) return <></>;

    if (!canEditFrequency) {
      return (
        <Icon
          sx={{
            color: 'primary.main',
            visibility: 'hidden',
            '.MuiTableCell-root:hover &': { visibility: 'visible' },
          }}
        >
          <LockIcon />
        </Icon>
      );
    }

    return (
      <Icon
        sx={(theme) => ({
          width: theme.spacing(1),
          transform: 'rotate(180deg)',
          path: { stroke: theme.palette.common.grey },
        })}
      >
        <ArrowDownV2Icon />
      </Icon>
    );
  }, [canEditFrequency, hasMultiplePrices]);

  const skeleton = (
    <Typography>
      <Skeleton variant="text" />
    </Typography>
  );

  if (!variant)
    return (
      <TableRow>
        {[...new Array(7)].map((_, i) => (
          <GridCell key={i}>{skeleton}</GridCell>
        ))}
      </TableRow>
    );

  const currencySymbol = getCurrencySymbol(variant.currency);

  return (
    <TableRow sx={getRowStyles} ref={preview}>
      <GridCell noVerticalPadding>
        <Stack direction="row" gap={1}>
          {canDrag && (
            <IconButton inverse className={HOVER_ICON_CLASS_NAME} ref={drag}>
              <DragIcon />
            </IconButton>
          )}
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            component={Link}
            to={`/settings/products/${product.id}`}
            replace
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {product.name}
          </TypographyWithOverflowHint>
        </Stack>
      </GridCell>
      <GridCell noPadding>
        <ProductTypeSelect
          selectedTypeName={product.variant.type_name || '-'}
          options={typeOptions}
          onSelect={handleTypeChange}
          maxExpandedContainerWidth={200}
          layoutStyleProps={{ paddingLeft: 1, paddingRight: 3 }}
        />
      </GridCell>
      <Tooltip
        componentsProps={{ tooltip: { sx: { padding: 1.25 } } }}
        title={
          //Based on TR-6701 frequency selection is temporary blocked for existing assignments
          hasMultiplePrices && !canEditFrequency ? (
            <>
              <Typography mb={2}>
                {formatMessage({ id: 'products-CannotChangeFrequency' })}
              </Typography>
              <Typography>{formatMessage({ id: 'products-WorkingOnChangeFrequency' })}</Typography>
            </>
          ) : undefined
        }
      >
        <GridCell noPadding>
          <VariantPriceSelect
            schoolId={schoolId}
            options={variant.prices}
            priceFrequencyId={product.variant.frequency_id}
            showMissingFrequencyId={showMissingFrequencyId}
            showWarning={showPriceSelectWarning}
            onSelect={handleProductPriceChange}
            disabled={priceSelectDisabled}
            renderRightIcon={renderPriceSelectRightIcon}
            maxExpandedContainerWidth={200}
            yearId={yearId}
            layoutStyleProps={{ paddingLeft: 1, paddingRight: 3.5 }}
          />
        </GridCell>
      </Tooltip>
      <GridCell noVerticalPadding>
        {price ? (
          <TypographyWithOverflowHint>
            <Price currency={currencySymbol} variant="body1" color="text.primary" price={price} />
          </TypographyWithOverflowHint>
        ) : (
          '-'
        )}
      </GridCell>
      <GridCell noPadding>
        <DiscountInput value={product.discount_percent} onChange={handleProductDiscountChange} />
      </GridCell>

      <GridCell noVerticalPadding>
        {price ? (
          <TypographyWithOverflowHint>
            <Price
              currency={currencySymbol}
              variant="body1"
              color="text.primary"
              price={
                product.discount_percent
                  ? getDiscountedPrice(price, product.discount_percent)
                  : price
              }
            />
          </TypographyWithOverflowHint>
        ) : (
          '-'
        )}
      </GridCell>

      <GridCell
        noVerticalPadding
        onClick={
          onDelete
            ? (e) => {
                e.preventDefault();
                onDelete?.(index);
              }
            : undefined
        }
      >
        <Stack alignItems="center">
          {!onDelete ? (
            <Tooltip
              title={formatMessage({ id: 'products-ProductIsRequired' })}
              componentsProps={{
                tooltip: {
                  sx: (theme) => ({
                    padding: theme.spacing(1.25),
                  }),
                },
              }}
            >
              <Icon className={HOVER_ICON_CLASS_NAME} sx={{ color: 'common.grey' }}>
                <LockIcon />
              </Icon>
            </Tooltip>
          ) : (
            <CircleIconButton sx={(theme) => ({ fontSize: theme.spacing(1.75) })}>
              <MinusIcon />
            </CircleIconButton>
          )}
        </Stack>
      </GridCell>
    </TableRow>
  );
};

type StudentProductsEmptyListProps = {
  onDrop: (item: StudentProductDragItem) => void;
  payerType: PayerType;
  canAdd: boolean;
};

const StudentProductsEmptyList: FC<StudentProductsEmptyListProps> = ({
  onDrop,
  payerType,
  canAdd,
}) => {
  const { formatMessage } = useIntl();
  const isCompany = payerType === PayerType.Company;

  const [, drop] = useDrop(
    useMemo(() => ({ accept: ASSIGNED_PRODUCTS_DND_TYPE, drop: onDrop }), [onDrop]),
  );

  if (!canAdd) {
    return (
      <InformationBlock>
        <Typography textAlign="center" variant="h3">
          {formatMessage({
            id: isCompany ? 'profile-PayingCompanyCanBeAdded' : 'profile-ChooseResponsibleGuardian',
          })}
        </Typography>
      </InformationBlock>
    );
  }

  if (isCompany) {
    return (
      <DragAndDropBlock ref={drop}>
        <Typography textAlign="center" variant="h3" whiteSpace="nowrap">
          {formatMessage({ id: 'profile-UseDragAndDrop-1' })}
        </Typography>

        <Stack
          direction="row"
          gap={1}
          sx={(theme) => ({
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(0.5),
            borderRadius: theme.spacing(0.5),
            boxShadow: '0px 10px 40px 0px #25275A26',
            position: 'relative',
          })}
        >
          <Icon sx={{ color: 'primary.main' }}>
            <DragIcon />
          </Icon>
          <Icon sx={{ position: 'absolute', left: 15, top: 15 }}>
            <CursorCloseHandIcon />
          </Icon>
          <Box
            sx={(theme) => ({
              bgcolor: theme.palette.background.default,
              height: 20,
              width: 80,
              borderRadius: theme.spacing(0.25),
            })}
          />
        </Stack>
        <Typography textAlign="center" variant="h3" whiteSpace="nowrap">
          {formatMessage({ id: 'profile-UseDragAndDrop-2' })}
        </Typography>
      </DragAndDropBlock>
    );
  }

  return (
    <InformationBlock ref={drop}>
      <Typography textAlign="center" variant="h3">
        {formatMessage({ id: 'profile-PressToAddProducts-1' })}
      </Typography>
      <CircleIconButton
        disabled
        sx={(theme) => ({
          '&.Mui-disabled': {
            color: theme.palette.background.paper,
            background: theme.palette.primary.main,
          },
        })}
      >
        <PlusIcon />
      </CircleIconButton>
      <Typography textAlign="center" variant="h3">
        {formatMessage({ id: 'profile-PressToAddProducts-2' })}
      </Typography>
    </InformationBlock>
  );
};
